/*
* NOTE:
* ./data-routes is a symlink to ../api/src/data-routes
*
* use the following command to create the symlink:
* ln -s ../api/src/data-routes ./data-routes
*
* On windows, use mklink command:
* mklink /D data-routes ..\api\src\data-routes
*/
import routes from './data-routes';
import { type DataRoutes, type Route } from './data-routes/types';
import { getAuthHeaders, handleResponse } from './authService';
import { convertKeysToCamelCase } from '../utils/camelCase';
import { parseDataProperty } from '../utils/parseUtils';
import { z } from 'zod';

type HttpMethod = 'GET' | 'POST' | 'PUT' | 'PATCH' | 'DELETE';

interface ApiEndpointDefinition<TReq = any, TRes = any> {
  path: string;
  method: HttpMethod;
  schema?: {
    request?: z.Schema<TReq>;
    response: z.Schema<TRes>;
  };
}

// Convert routes to API endpoint definitions
const apiDefinitions = Object.entries(routes as DataRoutes).reduce((acc, [key, routeConfig]: [string, Route]) => {
  const [method, path] = key.split(' ');
  acc[key] = {
    path,
    method: method.toUpperCase() as HttpMethod,
    schema: routeConfig.schema ? {
      request: routeConfig.schema,
      response: z.any()
    } : undefined
  };
  return acc;
}, {} as Record<string, ApiEndpointDefinition>);

function replacePathParams(path: string, params: Record<string, string> = {}): string {
  return path.replace(/:([^/]+)/g, (_, param) => {
    const value = params[param];
    if (!value) throw new Error(`Missing required path parameter: ${param}`);
    return encodeURIComponent(String(value));
  });
}

function buildQueryString(query: Record<string, string | number | boolean> = {}): string {
  const params = new URLSearchParams();
  Object.entries(query).forEach(([key, value]) => {
    if (value !== undefined) {
      params.append(key, String(value));
    }
  });
  const queryString = params.toString();
  return queryString ? `?${queryString}` : '';
}

async function makeRequest(methodPath: string, payload?: any): Promise<any> {
  const endpoint = apiDefinitions[methodPath];
  if (!endpoint) {
    throw new Error(`No route found: ${methodPath}`);
  }

  const isGetOrDelete = ['GET', 'DELETE'].includes(endpoint.method);
  let pathParams: Record<string, string> = {};
  let queryParams: Record<string, string | number | boolean> = {};

  // Extract path parameters and query parameters from payload
  if (payload) {
    const payloadObj = payload as Record<string, any>;
    const extractedPathParams: Record<string, string> = {};

    // First find all path parameters in the endpoint path
    const pathParamMatches = endpoint.path.match(/:([^/]+)/g) || [];
    const pathParamNames = pathParamMatches.map(param => param.substring(1));

    Object.entries(payloadObj).forEach(([key, value]) => {
      if (pathParamNames.includes(key)) {
        extractedPathParams[key] = String(value);
      } else if (isGetOrDelete) {
        // Only add to query params for GET/DELETE
        queryParams[key] = value;
      }
    });

    pathParams = extractedPathParams;
  }

  const path = replacePathParams(endpoint.path, pathParams);
  const queryString = isGetOrDelete ? buildQueryString(queryParams) : '';
  const url = `${path}${queryString}`;

  const headers = await getAuthHeaders();
  const init: RequestInit = {
    method: endpoint.method,
    headers: {
      ...headers,
      'Content-Type': 'application/json'
    }
  };

  if (!isGetOrDelete && payload) {
    // For POST/PUT/PATCH, put all non-path params in the body
    const bodyData = { ...payload };
    // Remove path params from body
    const pathParamMatches = endpoint.path.match(/:([^/]+)/g) || [];
    const pathParamNames = pathParamMatches.map(param => param.substring(1));
    pathParamNames.forEach(param => delete bodyData[param]);
    init.body = JSON.stringify(bodyData);
  }

  const response = await fetch(url, init);
  const data = await handleResponse(response);
  const parsedData = data ? parseDataProperty(data) : data;
  const processedData = parsedData ? convertKeysToCamelCase(parsedData) : parsedData;

  // Validate response if schema exists
  if (endpoint.schema?.response && processedData) {
    const validationResult = endpoint.schema.response.safeParse(processedData);
    if (!validationResult.success) {
      throw new Error(`Invalid response data: ${validationResult.error.message}`);
    }
  }

  return processedData;
}

/**
 * Generic request function for making API calls using route definitions
 * @param path The API path to request
 * @param payload Optional payload (used as body for POST/PUT/PATCH, or query/params for GET/DELETE)
 * @returns Promise resolving to the response data
 */
export async function request(fullPath: string, payload?: any): Promise<any> {
  console.log('request', fullPath, payload);
  const [reqMethod, reqPath] = fullPath.toLowerCase().split(' ');
  const methodPath = Object.keys(apiDefinitions).find(key => {
    const [method, path] = key.toLowerCase().split(' ');
    return method === reqMethod && path === reqPath;
  });

  if (!methodPath) {
    throw new Error(`No route found: ${fullPath}`);
  }

  return makeRequest(methodPath, payload);
}
