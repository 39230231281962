import React from 'react';
import { API_ENDPOINTS } from '../../constants/apiEndpoints';
import Typeahead, { TypeaheadResult, Section } from './Typeahead';

export interface EntityTypeaheadResult extends TypeaheadResult {
  email?: string;
}

interface EntityTypeaheadProps {
  onSelect: (result: TypeaheadResult) => void;
  placeholder?: string;
  className?: string;
}

const EntityTypeahead: React.FC<EntityTypeaheadProps> = ({
  onSelect,
  placeholder = 'Search contacts and accounts...',
  className = ''
}) => {
  const sections: Section[] = [
    {
      title: 'Accounts',
      filter: { type: 'Account' },
      avatarColor: 'bg-blue-500'
    },
    {
      title: 'Existing Contacts',
      filter: { type: 'Contact', hasId: true },
      avatarColor: 'bg-red-500'
    },
    {
      title: 'Suggested Contacts',
      filter: { type: 'Contact', hasId: false },
      avatarColor: 'bg-red-500',
      itemClassName: 'suggested-contact'
    }
  ];

  return (
    <Typeahead
      onSelect={onSelect}
      sections={sections}
      searchEndpoint={API_ENDPOINTS.GET_CONTACTS_SEARCH_ALL}
      placeholder={placeholder}
      className={className}
    />
  );
};

export default EntityTypeahead;
