import { z } from "zod";
import { DataRoutes } from "./types";

const noteRoutes: DataRoutes = {
  // Get total count of notes in the system
  'get /api/data/notes/count': {
    queryOne: `SELECT COUNT(*) AS [count] FROM [dbo].[Notes]`,
  },

  // Get all notes associated with a specific contact
  'get /api/data/contacts/:id/notes': {
    schema: z.object({
      id: z.number().int(),
    }),
    query: `SELECT N.*
      ,U.[name] AS [author]
    FROM [dbo].[Notes] N
    JOIN [dbo].[NoteContacts] B ON N.Id = B.NoteID
    LEFT JOIN [dbo].[Users] U ON U.[Id] = N.[createdBy]
    WHERE B.contactId = @id
    ORDER BY N.UpdatedAt DESC`
  },

  // Get notes for a specific pipeline
  'get /api/data/pipeline/:id/notes': {
    schema: z.object({
      id: z.number().int()
    }),
    query: `
    SELECT N.*
       ,O.[Id] AS [opportunityId]
       ,U.[name] AS [author]
       ,O.ContactId as [contactId]
       ,O.AccountId as [accountId]
    FROM Opportunities O
    JOIN [dbo].[PipelineStages] P ON P.[Id] = O.[stageId]
    LEFT JOIN NoteAccounts NA ON O.accountId = NA.accountId
    LEFT JOIN NoteContacts NC ON O.contactId = NC.contactId
    LEFT JOIN Notes N ON N.id = NA.noteId OR N.id = NC.noteId
    LEFT JOIN [dbo].[Users] U ON U.[Id] = N.[createdBy]
    WHERE P.[pipelineId] = @id
    ORDER BY N.UpdatedAt DESC
    `
  },

  // Get notes for a specific opportunity
  'get /api/data/opportunities/:id/notes': {
    schema: z.object({
      id: z.number().int()
    }),
    query: `
    SELECT N.*
       ,O.[Id] AS [opportunityId]
       ,U.[name] AS [author]
       ,O.ContactId as [contactId]
       ,O.AccountId as [accountId]
    FROM Opportunities O
    LEFT JOIN NoteAccounts NA ON O.accountId = NA.accountId
    LEFT JOIN NoteContacts NC ON O.contactId = NC.contactId
    LEFT JOIN Notes N ON N.id = NA.noteId OR N.id = NC.noteId
    LEFT JOIN [dbo].[Users] U ON U.[Id] = N.[createdBy]
    WHERE O.[Id] = @id
    ORDER BY N.UpdatedAt DESC
    `
  },

  // Get notes for a specific account
  'get /api/data/accounts/:id/notes': {
    schema: z.object({
      id: z.number().int()
    }),
    query: `EXEC [dbo].[GetNotes] @Id = @id`

  },

  // Get notes for a specific lead
  'get /api/data/leads/:id/notes': {
    schema: z.object({
      id: z.number().int()
    }),
    query: `SELECT * FROM [dbo].[Notes]
            WHERE [leadId] = @id`
  },


  // Get notes for a current user
  'get /api/data/users/me/notes': {
    query: `EXEC GetNotes`
  },

  // Create a new note with title, content, creator and visibility settings
  'post /api/data/notes': {
    schema: z.object({
      title: z.string(),
      content: z.string(),
      type: z.string().nullable(),
      teamIds: z.string().nullable(),
      objectIds: z.string().nullable(),
      objectType: z.string(),
    }),
    query: `EXEC [dbo].[InsertNote] @Title = @title, @Content = @content, @Type = @type, @ObjectIds = @objectIds, @objectType = @objectType, @TeamIds = @TeamIds`
  },

  // Update main fields of an existing note
  'put /api/data/notes/:id': {
    schema: z.object({
      id: z.number().int(),
      title: z.string(),
      content: z.string(),
      type: z.string().nullable(),
      teamIds: z.string().nullable(),
    }),
    query: `EXEC [dbo].[UpdateNote] @NoteID=@id, @Title = @title, @NewContent = @content, @Type = @type, @TeamIds = @TeamIds`
  },

  // Update start and due dates of a note
  'patch /api/data/notes/:id': {
    schema: z.object({
      id: z.number().int(),
      startDate: z.string().nullable(),
      dueDate: z.string().nullable()
    }),
    query: `UPDATE [dbo].[Notes]
            SET [startDate] = @startDate,
                [dueDate] = @dueDate,
                [modifiedAt] = GETDATE()
            WHERE [noteId] = @id`
  },

  // Update status of a note
  'patch /api/data/notes/:id/status': {
    schema: z.object({
      id: z.number().int(),
      status: z.number().int(),
    }),
    query: `UPDATE [dbo].[Notes]
            SET [statusId] = @status,
                [modifiedAt] = GETDATE()
            WHERE [noteId] = @id`
  },

  // Delete note by ID
  'delete /api/data/notes/:id': {
    schema: z.object({
      id: z.number().int(),
    }),
    query: `EXEC DeleteNote @id`
  }
};

export default noteRoutes;
