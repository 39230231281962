
import { z } from "zod";
import { DataRoutes } from "./types";

const newsRoutes: DataRoutes = {
  // Get latest 100 news items sorted by creation date
  'get /api/data/news': {
    query: `SELECT TOP (100) *
      FROM [dbo].[News]
      ORDER BY [createdAt] DESC`
  },

  // Create a new news item with entity reference and content
  'post /api/data/news': {
    schema: z.object({
      content: z.string(),
      entityType: z.string(),
      entityId: z.number().int(),
      url: z.string(),
      user: z.string()
    }),

    query: `
      INSERT INTO [dbo].[News] ([EntityType], [EntityId], [Content], [url], [createdBy] )
      VALUES (@entityType, @entityId, @content, @url, @user)     `
  },
}

export default newsRoutes;
