import { z } from "zod";
import { DataRoutes } from "./types";

const teamsRoutes: DataRoutes = {
  // Get list of all teams with their hierarchy
  'get /api/data/teams': {
    query: `SELECT
      Id as teamId,
      Name as teamName,
      parentId as parentId
    FROM Teams t`
  },

  // Delete a team by ID
  'delete /api/data/teams/:id': {
    schema: z.object({
      id: z.string(),
    }),
    query: `DELETE FROM Teams WHERE TeamID = @id`
  },

  // Get all users with their team associations
  'get /api/data/teams/users': {
    query: `SELECT
      u.Id as userId,
      u.Name as name,
      u.Email as email,
      t.Id as teamId,
      t.Name as teamName,
      t.parentId as parentId
    FROM Users u
    FULL OUTER JOIN UserTeams ut ON u.ID = ut.UserID
    FULL OUTER JOIN Teams t ON ut.TeamID = t.ID
    ORDER BY t.ID`
  },

  // Add a user to a team
  'post /api/data/teams/:id/users': {
    schema: z.object({
      id: z.number(),
      userId: z.number(),
    }),
    query: `INSERT INTO UserTeams (TeamID, UserID)
            VALUES (@id, @userId)`
  },

  // Remove a user from a team
  'delete /api/data/teams/:id/users/:userId': {
    schema: z.object({
      id: z.number(),
      userId: z.number(),
    }),
    query: `DELETE FROM UserTeams
            WHERE TeamID = @id
            AND UserID = @userId`
  },
}

export default teamsRoutes;
