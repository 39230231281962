import { FC, useCallback, useState, useMemo } from 'react';
import { request } from '../../services/dataService';
import { API_ENDPOINTS } from '../../constants/apiEndpoints';
import { getUserData } from '../../services/authService';
import { trimTitle } from '../../utils/stringUtils';
import { Pipeline } from '../../models/Pipeline';
import { Opportunity } from '../../models/Opportunity';
import OpportunityCard from './OpportunityCard';

interface OpportunityBoardProps {
  pipeline: Pipeline;
  opportunities: Opportunity[];
  setOpportunities: React.Dispatch<React.SetStateAction<Opportunity[]>>;
  onEditOpportunity: (opportunity: Opportunity) => void;
  searchQuery: string;
  visibleStages: string[];
}

const OpportunityBoard: FC<OpportunityBoardProps> = ({
  pipeline,
  opportunities,
  setOpportunities,
  onEditOpportunity,
  searchQuery,
  visibleStages
}) => {
  const [draggingId, setDraggingId] = useState<number | null>(null);

  const formatDate = (dateStr: string) => {
    if (!dateStr) return '';
    return new Date(dateStr).toLocaleDateString('en-US', {
      year: 'numeric',
      month: 'short',
      day: 'numeric'
    });
  };

  const formatValue = (value: any, field: any) => {
    if (value === undefined || value === '') return '';

    switch (field.type) {
      case 'number':
        if (field.name === 'probability') return `${value}%`;
        if (field.name === 'value') return `$${Number(value).toLocaleString()}`;
        return Number(value).toLocaleString();
      case 'date':
        return formatDate(value);
      case 'select':
        if (field.options) {
          const option = field.options.find((opt: any) => opt.value === value);
          return option ? option.label : value;
        }
        return value;
      default:
        return value;
    }
  };

  const keywords = useMemo(() =>
    searchQuery.toLowerCase()
      .split(' ')
      .map(k => k.trim())
      .filter(k => k.length > 0),
    [searchQuery]
  );

  const filteredOpportunities = useMemo(() => {
    const trimmedQuery = searchQuery.trim();
    if (!trimmedQuery) return opportunities;

    return opportunities.filter(opportunity => {
      const searchableValues = pipeline.fields
        .map(field => {
          const value = opportunity[field.name];
          return formatValue(value, field).toString().toLowerCase();
        })
        .filter(Boolean);

      return keywords.every(keyword =>
        searchableValues.some(value => value.includes(keyword))
      );
    });
  }, [opportunities, searchQuery, pipeline.fields, keywords]);
  const [hoveredStageId, setHoveredStageId] = useState<number | null>(null);

  // Count opportunities in each stage
  const stageOpportunityCount = useMemo(() => {
    const counts: Record<number, number> = {};

    // Initialize counts for all stages
    pipeline.stages.forEach(stage => {
      counts[stage.id] = 0;
    });

    // Count filtered opportunities by stage
    filteredOpportunities.forEach(opp => {
      if (counts[opp.stageId] !== undefined) {
        counts[opp.stageId]++;
      }
    });

    return counts;
  }, [filteredOpportunities, pipeline.stages]);

  const handleDragStart = useCallback((id: number) => {
    setDraggingId(id);
  }, []);

  const handleDragOver = useCallback((e: React.DragEvent<HTMLDivElement>, stageId: number) => {
    e.preventDefault();
    setHoveredStageId(stageId);
  }, []);

  const handleDrop = useCallback(async (stageId: number) => {
    if (!draggingId || !pipeline) return;
    setHoveredStageId(null);

    try {
      // Get the opportunity being moved and its old stage
      const opportunity = opportunities.find(opp => opp.id === draggingId);
      const oldStage = pipeline.stages.find(stage => stage.id === opportunity?.stageId);
      const newStage = pipeline.stages.find(stage => stage.id === stageId);

      if (!opportunity || !oldStage || !newStage || oldStage.id === newStage.id) return;

      // Optimistically update local state
      setOpportunities((prev: Opportunity[]) => {
        return prev.map((opp: Opportunity) => ({
          ...opp,
          stageId: opp.id === draggingId ? stageId : opp.stageId
        }));
      });

      // Make API call in background
      await request(API_ENDPOINTS.PATCH_OPPORTUNITY_STAGE, { id: draggingId, stageId })
        .then(() => {
          // Post news update after successful stage change
          const opportunityData = opportunity as any;
          const content = `Updated the stage of opportunity "${trimTitle(opportunityData.title || '')}" from "${oldStage.name}" to "${newStage.name}"`;
          const userData = getUserData();
          request(API_ENDPOINTS.POST_NEWS, {
            content, entityType: 'opportunity', entityId: opportunity.id,
            url: window.location.pathname,
            user: userData?.name || ''
          });
        })
        .catch((err: Error) => {
          console.error('Failed to update opportunity stage:', err);
          // Revert optimistic update if API call fails
          setOpportunities((prev: Opportunity[]) => {
            return prev.map((opp: Opportunity) => {
              if (opp.id === draggingId) {
                return { ...opp, stageId: oldStage.id }; // Revert to original stageId
              }
              return opp;
            });
          });
        });
    } catch (err) {
      console.error('Failed to update opportunity stage:', err);
    } finally {
      setDraggingId(null);
    }
  }, [draggingId, pipeline, opportunities, setOpportunities]);

  return (
    <div className="overflow-x-auto">
      <div className="flex min-w-max">
        {pipeline.stages
          .filter(stage => visibleStages.includes(stage.id.toString()))
          .map((stage, index) => (
            <div key={stage.id} className="w-72">
              <div className={`h-10 flex items-center px-4 transition-colors duration-200 ${hoveredStageId === stage.id
                ? 'bg-pink-500/40 dark:bg-pink-600/30'
                : 'bg-green-500/40 dark:bg-green-600/30'
                } ${index === 0
                  ? '[clip-path:polygon(4px_0,calc(100%-12px)_0,100%_50%,calc(100%-12px)_100%,4px_100%,4px_0)]'
                  : index === pipeline.stages.length - 1
                    ? '[clip-path:polygon(0_0,calc(100%-4px)_0,calc(100%-4px)_100%,0_100%,12px_50%)]'
                    : '[clip-path:polygon(0_0,calc(100%-12px)_0,100%_50%,calc(100%-12px)_100%,0_100%,12px_50%)]'
                }`}>
                <h3 className="text-gray-800 dark:text-gray-100 text-xs font-semibold truncate mx-auto my-auto">
                  {stage.name} <span className="ml-1 px-1.5 py-0.5 bg-gray-200 dark:bg-gray-700 rounded-full text-xs">
                    {stageOpportunityCount[stage.id] || 0}
                  </span>
                </h3>
              </div>
              <div
                onDragOver={(e) => handleDragOver(e, stage.id)}
                onDrop={() => handleDrop(stage.id)}
                onDragLeave={() => setHoveredStageId(null)}
                className={`mt-4 p-4 bg-white dark:bg-gray-800 rounded-lg h-[calc(100vh-220px)] border transition-all duration-200 overflow-y-auto ${hoveredStageId === stage.id
                  ? 'border-2 border-dashed border-green-500 dark:border-green-600 bg-green-50/50 dark:bg-green-900/20'
                  : 'border border-gray-200 dark:border-gray-700'
                  }`}
              >
                <div className="space-y-2">
                  <div className="space-y-2 h-full min-h-[2rem]">
                    {filteredOpportunities
                      .filter(opp => opp.stageId === stage.id)
                      .map(opp => (
                        <OpportunityCard
                          key={opp.id}
                          opportunity={opp as any}
                          onDragStart={handleDragStart}
                          onDragOver={handleDragOver}
                          onDrop={handleDrop}
                          setEditingOpportunity={(opp) => onEditOpportunity(opp)}
                          draggingId={draggingId}
                          fields={pipeline.fields}
                          keywords={keywords}
                        />
                      ))}
                  </div>
                </div>
              </div>
            </div>
          ))}
      </div>
    </div>
  );
};

export default OpportunityBoard;
