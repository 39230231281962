import { z } from "zod";
import { DataRoutes } from "./types";

const contactRoutes: DataRoutes = {
  // Get list of contacts with sorting options
  'get /api/data/contacts': {

    query: `SELECT C.id
      ,C.accountId
      ,C.Name as displayName
      ,C.rlpPersonId
      ,A.rlpCompanyId
      ,A.Name as accountName
      ,A.teamId
      ,dbo.MergeJsonObjects(C.data,
        (SELECT *
        FROM RLPPersons
        WHERE RLPPersons.rlpPersonId = C.rlpPersonId
        FOR JSON PATH, WITHOUT_ARRAY_WRAPPER)) as data
      ,Count(N.noteId) as noteCount

    FROM Contacts C
    LEFT JOIN Accounts A ON C.accountId = A.id
    LEFT JOIN NoteContacts N ON C.id = N.contactId
    WHERE A.rlpCompanyId is NOT NULL OR A.teamId in (SELECT teamId FROM CurrentUserAndTeams())
    GROUP BY C.id, C.accountId, C.CRMPersonId, C.rlpPersonId, C.Name, C.data, A.rlpCompanyId, A.Name, A.teamId
    `
  },

  // Search accounts and contacts for autocomplete functionality
  'get /api/data/contacts/searchAll': {
    schema: z.object({
      q: z.string()
    }),
    query: `EXEC [dbo].[SearchAll] @searchString = @q`,
    serverCache: 10, // 10 seconds
    clientCache: 10, // 10 seconds
  },

  // Search contacts for autocomplete functionality
  'get /api/data/contacts/search': {
    schema: z.object({
      q: z.string()
    }),
    query: `EXEC [dbo].[SearchAutoComplete] @searchString = @q`,
  },

  // Get total count of contacts in system
  'get /api/data/contacts/count': {
    query: `SELECT
      ISNULL(A.teamId, 0) AS teamId
      ,COUNT(*) AS count
      FROM dbo.Contacts C
      JOIN dbo.Accounts A ON C.accountId = A.id
      GROUP BY A.teamId
    `
  },

  // Get details of a specific contact
  'get /api/data/contacts/:id': {
    schema: z.object({
      id: z.number().int(),
    }),
    queryOne: `EXEC GetContact @id`
  },

  // Get contacts of a specific account with sorting
  'get /api/data/accounts/:id/contacts': {
    schema: z.object({
      id: z.number().int(),
      initialContactId: z.number().optional().nullable().default(null)
    }),
    query: `EXEC GetAccountContacts  @id, @initialContactId -- Caller: GET_ACCOUNT_CONTACTS `,
  },

  // Get list of contacts associated with a user
  'get /api/data/users/:userId/contacts': {
    schema: z.object({
      userId: z.number().int(),
    }),
    query: `SELECT C.id
      ,C.accountId
      ,C.Name as displayName
      ,C.rlpPersonId
      ,A.rlpCompanyId
      ,A.Name as accountName
      ,A.teamId
      ,dbo.MergeJsonObjects(C.data,
        (SELECT *
        FROM RLPPersons
        WHERE RLPPersons.rlpPersonId = C.rlpPersonId
        FOR JSON PATH, WITHOUT_ARRAY_WRAPPER)) as data

    FROM Contacts C
    JOIN UserContacts B ON C.id = B.ContactId
    LEFT JOIN Accounts A ON C.accountId = A.id
    WHERE (A.rlpCompanyId is NOT NULL OR A.teamId in (SELECT teamId FROM CurrentUserAndTeams()))
    AND B.[UserId] = @userId
    ORDER BY B.CreatedAt DESC`
  },


  // Get list of contacts associated with a team
  'get /api/data/team/:teamId/contacts': {
    schema: z.object({
      teamId: z.number().int(),
    }),
    query: `SELECT C.id
      ,C.accountId
      ,C.Name as displayName
      ,C.rlpPersonId
      ,A.rlpCompanyId
      ,A.Name as accountName
      ,A.teamId
      ,Count(N.noteId) as noteCount
      ,dbo.MergeJsonObjects(C.data,
        (SELECT *
        FROM RLPPersons
        WHERE RLPPersons.rlpPersonId = C.rlpPersonId
        FOR JSON PATH, WITHOUT_ARRAY_WRAPPER)) as data
    FROM Contacts C
    INNER JOIN Accounts A ON C.accountId = A.id
    LEFT JOIN NoteContacts N ON C.id = N.contactId
    WHERE (a.teamId IS NULL OR a.teamId in (SELECT teamId FROM CurrentUserAndTeams()))
    AND (@teamId = 0 AND a.teamId IS NULL OR @teamId > 0 AND a.teamId = @teamId)
    GROUP BY C.id, C.accountId, C.CRMPersonId, C.rlpPersonId, C.Name, C.data, A.rlpCompanyId, A.Name, A.teamId
    `
  },

  'put /api/data/contacts': {
    schema: z.object({
      personId: z.number().int(),
    }),
    queryOne: `
    DECLARE @accountId INT
    DECLARE @name NVARCHAR(50)

    -- Check if the contact already exists
    IF EXISTS (SELECT 1 FROM Contacts WHERE rlpPersonId = @personId)
      BEGIN
        SELECT id FROM Contacts WHERE rlpPersonId = @personId
        RETURN
      END

    -- If not, insert a new contact
    SELECT @accountId = A.id, @name = P.DisplayName
      FROM RLPPersons P
      JOIN Accounts A ON P.CompanyId = A.RlpCompanyId
      WHERE rlpPersonId = @personId
    INSERT INTO Contacts (accountId, name, rlpPersonId) VALUES (@accountId, @name, @personId)
    SELECT SCOPE_IDENTITY() AS id
    `
  },

  // // Create a new contact with associated company information
  // 'post /api/data/contacts': {
  //   schema: z.object({
  //     firstName: z.string(),
  //     lastName: z.string(),
  //     displayName: z.string(),
  //     email: z.string(),
  //     phone: z.string(),
  //     employeeStatus: z.string(),
  //     companyId: z.number().int(),
  //     companyName: z.string(),
  //   }),
  //   query: `EXEC [dbo].[InsertContactForEmail] @FirstName = @firstName, @LastName = @lastName, @DisplayName = @displayName,
  //   @Email = @email, @Phone = @phone, @EmployeeStatus = @employeeStatus, @CompanyID = @companyId, @CompanyName = @companyName`
  // },

  // Create a new contact with provided details
  'post /api/data/contacts': {
    schema: z.object({
      name: z.string(),
      data: z.string(),
      accountId: z.number()
    }),
    query: `INSERT INTO [dbo].[Contacts] (name, data, accountId, createdBy)
      VALUES (@name, @data, @accountId, dbo.fnGetContextUser())`
  },

  // Update an existing contact by its Id
  'patch /api/data/contacts/:id': {
    schema: z.object({
      id: z.number().int(),
      data: z.string().optional()
    }),
    query: `UPDATE [dbo].[Contacts]
            SET
                data = dbo.MergeJsonObjects(data, @data),
                updatedBy = dbo.fnGetContextUser(),
                updatedAt = GETDATE()
            WHERE id = @id`
  },

  // Delete a contact by its Id
  'delete /api/data/contacts/:id': {
    schema: z.object({
      id: z.number().int(),
    }),
    query: `DELETE FROM [dbo].[Contacts] WHERE id = @id
    AND createdBy = dbo.fnGetContextUser()
    `
  },


};

export default contactRoutes;
