import React from 'react';
import { Navigate } from 'react-router-dom';
import { useTeams } from '../../context/TeamContext';
import { THEME_CLASSES } from '../../constants/themeConstants';

interface ProtectedRouteProps {
  children: React.ReactNode;
  requiresAdmin?: boolean;
  teamName?: string;
}

export const ProtectedRoute: React.FC<ProtectedRouteProps> = ({ 
  children, 
  requiresAdmin = false,
  teamName = 'User Admin'
}) => {
  const { teams, isLoading } = useTeams();

  if (isLoading) {
    return <div className="flex items-center justify-center h-screen">
      <div className={`animate-spin rounded-full h-8 w-8 border-b-2 ${THEME_CLASSES.border}`}></div>
    </div>;
  }

  if (requiresAdmin && !teams.some(team => team.teamName === teamName)) {
    return <Navigate to="/" replace />;
  }

  return <>{children}</>;
};
