import { Contact } from '../models/Contact';
import { ColDef, SortDirection } from 'ag-grid-community';
import { createHighlightCellRenderer, createNameCellRenderer } from './gridCellRenderers';
import { type FormField } from '../components/common/FormField';
import { filterBySearchTerms } from './searchUtils';

export interface ContactField {
  key: keyof Contact;
  label: string;
  hideEmpty?: boolean;
}

export const getContactDisplayFields = (): ContactField[] => [
  { key: 'accountName', label: 'Account', hideEmpty: true },
  { key: 'emailAddress', label: 'Email', hideEmpty: true },
  { key: 'officePhone', label: 'Office Phone', hideEmpty: true },
  { key: 'cellPhone', label: 'Cell Phone', hideEmpty: true },
];

export const getContactColumnDefs = (searchQuery: string, schemaFields?: FormField[]): ColDef<Contact>[] => {
  // Base columns that are always present
  const baseColumns = [
    {
      field: 'displayName',
      headerName: 'Name',
      sortable: true,
      width: 300,
      pinned: true,
      cellRenderer: createNameCellRenderer(searchQuery)
    },
    {
      field: 'noteCount',
      headerName: 'Note Count',
      sortable: true,
      sort: 'desc' as SortDirection
    }
  ];

  if (!schemaFields || !schemaFields.length) return baseColumns;

  // Add schema-based columns
  const additionalColumns = schemaFields.map(field => ({
    field: field.name,
    headerName: field.label,
    sortable: true,
    cellRenderer: createHighlightCellRenderer(searchQuery)
  }));

  return [...baseColumns, ...additionalColumns];
};

export const filterContacts = (
  contacts: Contact[],
  searchQuery: string,
  searchFields?: Array<keyof Contact>
) => {
  return filterBySearchTerms(contacts, searchQuery, searchFields);
};
