import { useState, useEffect, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { request } from '../services/dataService';
import { API_ENDPOINTS } from '../constants/apiEndpoints';
import { Account } from '../models/Account';
import { Note } from '../models/Note';
import { THEME_CLASSES } from '../constants/themeConstants';
import DashboardNotes from '../components/dashboard/DashboardNotes';
import { useTeams } from '../context/TeamContext';

// Contact count team interface
interface ContactCount {
  teamId: number;
  count: number;
}

// Opportunity count interface
interface OpportunityCount {
  pipelineId: number;
  count: number;
}

// Note count by team interface
interface NoteCountByTeam {
  teamId: number;
  count: number;
}

function Dashboard() {
  const navigate = useNavigate();
  const { currentUserId, teams } = useTeams();
  const [contactsCount, setContactsCount] = useState<ContactCount[]>([]);
  const [accounts, setAccounts] = useState<Account[]>([]);
  const [notesCount, setNotesCount] = useState<number>(0);
  const [userNotesCount, setUserNotesCount] = useState<number>(0);
  const [userNotes, setUserNotes] = useState<Note[]>([]);
  const [opportunityCount, setOpportunityCount] = useState<OpportunityCount[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isStatsCollapsed, setIsStatsCollapsed] = useState(() => {
    const stored = localStorage.getItem('dashboard-stats-collapsed');
    return stored ? JSON.parse(stored) : false;
  });
  // Add state for selected team filter
  const [selectedTeamFilter, setSelectedTeamFilter] = useState<number | null>(null);

  // Calculate account totals by type
  const rlpCount = accounts.filter(account => !account.teamId).length;
  const prospeactCount = accounts.filter(account => account.teamId === 6).length;
  const commercialCount = accounts.filter(account => account.teamId === 7).length;

  // Calculate contact counts by team ID
  const getTotalContactsCount = () => {
    return contactsCount.reduce((total, item) => total + item.count, 0);
  };

  const getTeamContactsCount = (teamId: number) => {
    const team = contactsCount.find(item => item.teamId === teamId);
    return team?.count || 0;
  };

  // Calculate opportunity counts by pipeline ID
  const getTotalOpportunitiesCount = () => {
    return opportunityCount.reduce((total, item) => total + item.count, 0);
  };

  const getPipelineOpportunitiesCount = (pipelineId: number) => {
    const pipeline = opportunityCount.find(item => item.pipelineId === pipelineId);
    return pipeline?.count || 0;
  };

  const getOtherOpportunitiesCount = () => {
    return opportunityCount
      .filter(item => item.pipelineId !== 1 && item.pipelineId !== 2)
      .reduce((total, item) => total + item.count, 0);
  };

  // Function to get count of notes by team
  const getNotesBreakdown = (): string => {
    if (!userNotes || userNotes.length === 0) {
      return "No notes";
    }

    // Initialize counters for special team IDs
    const teamCounts: Record<number, number> = {
      0: 0,    // RLP
      9999: 0  // Public
    };

    // Count notes for each team ID
    userNotes.forEach(note => {
      if (!note.teamIds) return;

      // Split the comma-separated teamIds string into an array of numbers
      const teamIdArray = note.teamIds.split(',').map(id => parseInt(id.trim(), 10));

      // Increment the count for each team ID
      teamIdArray.forEach(teamId => {
        if (!teamCounts[teamId]) {
          teamCounts[teamId] = 0;
        }
        teamCounts[teamId]++;
      });
    });

    // Create the breakdown string parts
    const breakdownParts: string[] = [];

    // Add RLP count if it exists
    if (teamCounts[0] > 0) {
      breakdownParts.push(`<span class="cursor-pointer hover:underline" data-team-id="0">RLP: ${teamCounts[0]}</span>`);
    }

    // Add Public count if it exists
    if (teamCounts[9999] > 0) {
      breakdownParts.push(`<span class="cursor-pointer hover:underline" data-team-id="9999">Public: ${teamCounts[9999]}</span>`);
    }

    // Add counts for other teams
    teams.forEach(team => {
      if (teamCounts[team.teamId] && team.teamId !== 0) {
        breakdownParts.push(`<span class="cursor-pointer hover:underline" data-team-id="${team.teamId}">${team.teamName}: ${teamCounts[team.teamId]}</span>`);
      }
    });

    return breakdownParts.join(' | ') || "No team breakdown available";
  };

  // Filter notes by selected team
  const filteredNotes = useMemo(() => {
    if (!selectedTeamFilter) return userNotes;

    return userNotes.filter(note => {
      if (!note.teamIds) return false;
      const teamIdArray = note.teamIds.split(',').map(id => parseInt(id.trim(), 10));
      return teamIdArray.includes(selectedTeamFilter);
    });
  }, [userNotes, selectedTeamFilter]);

  // Get the selected team name for display
  const selectedTeamName = useMemo(() => {
    if (selectedTeamFilter === null) return null;
    if (selectedTeamFilter === 0) return "RLP";
    if (selectedTeamFilter === 9999) return "Public";

    const team = teams.find(t => t.teamId === selectedTeamFilter);
    return team ? team.teamName : `Team ${selectedTeamFilter}`;
  }, [selectedTeamFilter, teams]);

  // Function to handle team name click in stats card
  const handleTeamFilterClick = (event: React.MouseEvent<HTMLElement>) => {
    const target = event.target as HTMLElement;
    const teamIdAttr = target.getAttribute('data-team-id');
    if (teamIdAttr) {
      const teamId = parseInt(teamIdAttr, 10);
      setSelectedTeamFilter(teamId);
    }
  };

  // Handler to clear team filter
  const handleClearTeamFilter = () => {
    setSelectedTeamFilter(null);
  };

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      const [contactsCountData, accountsData, notesCount, opportunitiesCount] = await Promise.all([
        request(API_ENDPOINTS.GET_CONTACT_COUNT),
        request(API_ENDPOINTS.GET_ACCOUNTS),
        request(API_ENDPOINTS.GET_NOTES_COUNT),
        request(API_ENDPOINTS.GET_OPPORTUNITY_COUNT)
      ]);

      setContactsCount(contactsCountData);
      setAccounts(accountsData);
      setNotesCount(notesCount.count);
      setOpportunityCount(opportunitiesCount);
      setIsLoading(false);
    };

    void fetchData();
  }, []);

  // Fetch user-specific notes when currentUserId is available
  useEffect(() => {
    const fetchUserNotes = async () => {
      if (!currentUserId) return;

      try {
        const notes = await request(API_ENDPOINTS.GET_USER_NOTES, { userId: currentUserId });
        // Sort notes by modified date (most recent first)
        const sortedNotes = notes.sort((a: Note, b: Note) =>
          (new Date(b.updatedAt ?? b.createdAt).getTime()) -
          (new Date(a.updatedAt ?? a.createdAt).getTime())
        );
        setUserNotes(sortedNotes);
        setUserNotesCount(notes.length);
      } catch (error) {
        console.error("Failed to fetch user notes:", error);
      }
    };

    fetchUserNotes();
  }, [currentUserId]);

  // Save collapsed state to localStorage
  useEffect(() => {
    localStorage.setItem('dashboard-stats-collapsed', JSON.stringify(isStatsCollapsed));
  }, [isStatsCollapsed]);

  const stats = [
    {
      title: 'Total Accounts',
      value: accounts.length.toLocaleString(),
      change: `RLP: ${rlpCount} | Prospect: ${prospeactCount} | Commercial: ${commercialCount}`,
      link: '/accounts',
      icon: (
        <svg className="w-6 h-6 text-blue-600" fill="none" stroke="currentColor" viewBox="0 0 24 24">
          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M19 21V5a2 2 0 00-2-2H7a2 2 0 00-2 2v16m14 0h2m-2 0h-5m-9 0H3m2 0h5M9 7h1m-1 4h1m4-4h1m-1 4h1m-5 10v-5a1 1 0 011-1h2a1 1 0 011 1v5m-4 0h4" />
        </svg>
      )
    },
    {
      title: 'Total Contacts',
      value: getTotalContactsCount().toLocaleString(),
      change: `RLP: ${getTeamContactsCount(0)} | Prospect: ${getTeamContactsCount(6)} | Commercial: ${getTeamContactsCount(7)}`,
      link: '/contacts',
      icon: (
        <svg className="w-6 h-6 text-green-600" fill="none" stroke="currentColor" viewBox="0 0 24 24">
          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M17 20h5v-2a3 3 0 00-5.356-1.857M17 20H7m10 0v-2c0-.656-.126-1.283-.356-1.857M7 20H2v-2a3 3 0 015.356-1.857M7 20v-2c0-.656.126-1.283.356-1.857m0 0a5.002 5.002 0 019.288 0M15 7a3 3 0 11-6 0 3 3 0 016 0zm6 3a2 2 0 11-4 0 2 2 0 014 0zM7 10a2 2 0 11-4 0 2 2 0 014 0z" />
        </svg>
      )
    },
    {
      title: 'Total Opportunities',
      value: getTotalOpportunitiesCount().toLocaleString(),
      change: `Prospect: ${getPipelineOpportunitiesCount(1)} | Commercial: ${getPipelineOpportunitiesCount(2)} | Other: ${getOtherOpportunitiesCount()}`,
      link: '/pipelines',
      icon: (
        <svg className="w-6 h-6 text-purple-600" fill="none" stroke="currentColor" viewBox="0 0 24 24">
          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 19v-6a2 2 0 00-2-2H5a2 2 0 00-2 2v6a2 2 0 002 2h2a2 2 0 002-2zm0 0V9a2 2 0 012-2h2a2 2 0 012 2v10m-6 0a2 2 0 002 2h2a2 2 0 002-2m0 0V5a2 2 0 012-2h2a2 2 0 012 2v14a2 2 0 01-2 2h-2a2 2 0 01-2-2z" />
        </svg>
      )
    },
    {
      title: 'Total Notes',
      value: notesCount.toLocaleString(),
      change: `Your Notes: ${userNotesCount} | ${getNotesBreakdown()}`,
      icon: (
        <svg className="w-6 h-6 text-amber-600" fill="none" stroke="currentColor" viewBox="0 0 24 24">
          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 12h6m-6 4h6m2 5H7a2 2 0 01-2-2V5a2 2 0 012-2h5.586a1 1 0 01.707.293l5.414 5.414a1 1 0 01.293.707V19a2 2 0 01-2 2z" />
        </svg>
      )
    },
  ];

  return (
    <div className="h-full flex flex-col mx-6">
      <div className="flex items-center justify-between">
        <h1 className={`${THEME_CLASSES.text.primary} text-xl font-bold tracking-tight`}>Dashboard</h1>
        <button
          onClick={() => setIsStatsCollapsed((prev: boolean) => !prev)}
          className={`p-1 rounded-md ${THEME_CLASSES.text.secondary} ${THEME_CLASSES.hover.background}`}
          title={isStatsCollapsed ? "Expand statistics" : "Collapse statistics"}
        >
          <svg className={`w-5 h-5 transform transition-transform ${isStatsCollapsed ? 'rotate-180' : ''}`} fill="none" stroke="currentColor" viewBox="0 0 24 24">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M19 9l-7 7-7-7" />
          </svg>
        </button>
      </div>

      {/* Statistics */}
      <section
        className={`grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-4 sm:gap-6 transition-all duration-300 ease-in-out overflow-visible mb-6 ${isStatsCollapsed ? 'h-0 mb-0 opacity-0' : 'opacity-100'}`}
        aria-label="Statistics"
      >
        {stats.map((stat) => (
          <div
            key={stat.title}
            className={`p-6 m-1 ${THEME_CLASSES.input.background} rounded-xl border ${THEME_CLASSES.border} transition-all duration-200 cursor-pointer shadow-lg hover:shadow-2xl`}
            role="figure"
            onClick={(e) => {
              if (stat.title === 'Total Notes') {
                handleTeamFilterClick(e);
              } else if (stat.link) {
                navigate(stat.link);
              }
            }}
          >
            <div className="flex justify-between items-start mb-4">
              <p className={`text-sm ${THEME_CLASSES.text.secondary}`}>{stat.title}</p>
              {stat.link && <span>{stat.icon}</span>}
            </div>
            {isLoading ? (
              <div className="animate-pulse">
                <div className="h-8 bg-gray-200 dark:bg-gray-700 rounded w-24 mb-2"></div>
                <div className="h-4 bg-gray-200 dark:bg-gray-700 rounded w-48"></div>
              </div>
            ) : (
              <>
                <p className={`text-2xl font-semibold mb-2 ${THEME_CLASSES.text.primary}`}>{stat.value}</p>
                {stat.title === 'Total Notes' ? (
                  <div
                    className={`text-sm ${THEME_CLASSES.text.secondary}`}
                    dangerouslySetInnerHTML={{ __html: stat.change }}
                  />
                ) : (
                  <p className={`text-sm ${THEME_CLASSES.text.secondary}`}>{stat.change}</p>
                )}
              </>
            )}
          </div>
        ))}
      </section>

      {/* main content - fill available space */}
      <div className="h-[calc(100vh-320px)] overflow-hidden">
        <DashboardNotes
          notes={filteredNotes}
          isLoading={isLoading}
          selectedTeamFilter={selectedTeamFilter}
          selectedTeamName={selectedTeamName}
          onClearTeamFilter={handleClearTeamFilter}
        />
      </div>

    </div>
  );
}

export default Dashboard;
