export const API_ENDPOINTS = {
  // Auth routes
  GET_AUTH_WHO: 'get /api/data/who',

  // User routes
  GET_USERS: 'get /api/data/users',
  POST_USER_ACCOUNT: 'post /api/data/users/:userId/accounts',
  DELETE_USER_ACCOUNT: 'delete /api/data/users/:userId/accounts/:id',
  GET_USER_CONTACTS: 'get /api/data/users/:userId/contacts',
  POST_USER_CONTACT: 'post /api/data/users/:userId/contacts',
  DELETE_USER_CONTACT: 'delete /api/data/users/:userId/contacts/:id',
  GET_USER_NOTES: 'get /api/data/users/me/notes', // New endpoint for user notes

  // Account routes
  GET_ACCOUNTS_SEARCH: 'get /api/data/accounts/search',
  GET_ACCOUNTS: 'get /api/data/accounts',
  GET_ACCOUNT: 'get /api/data/accounts/:id',
  POST_ACCOUNT: 'post /api/data/accounts',
  PATCH_ACCOUNT: 'patch /api/data/accounts/:id',
  DELETE_ACCOUNT: 'delete /api/data/accounts/:id',
  GET_ACCOUNT_NOTES: 'get /api/data/accounts/:id/notes',
  GET_ACCOUNT_CONTACTS: 'get /api/data/accounts/:id/contacts',
  GET_ACCOUNT_PIPELINES: 'get /api/data/accounts/:id/pipelines',
  GET_USER_ACCOUNTS: 'get /api/data/users/:userId/accounts',
  GET_TEAM_ACCOUNTS: 'get /api/data/team/:teamId/accounts',

  // Contact routes
  GET_CONTACTS: 'get /api/data/contacts', // Get list of contacts with sorting options
  GET_CONTACT: 'get /api/data/contacts/:id', // Get details of a specific contact
  POST_CONTACT: 'post /api/data/contacts', // Create a new contact with provided details
  PUT_CONTACT: 'put /api/data/contacts', // Link existing RLP contact
  GET_CONTACTS_SEARCH_ALL: 'get /api/data/contacts/searchAll', // Search accounts and contacts for autocomplete
  GET_CONTACT_SEARCH: 'get /api/data/contacts/search', // Search contacts for autocomplete
  GET_CONTACT_COUNT: 'get /api/data/contacts/count', // Get total count of contacts in system
  GET_CONTACT_NOTES: 'get /api/data/contacts/:id/notes',
  PATCH_CONTACT: 'patch /api/data/contacts/:id', // Update an existing contact
  DELETE_CONTACT: 'delete /api/data/contacts/:id', // Delete a contact
  GET_TEAM_CONTACTS: 'get /api/data/team/:teamid/contacts', // Get list of contacts associated with a team

  // Lead routes
  GET_LEADS: 'get /api/data/leads',
  GET_LEAD: 'get /api/data/leads/:id',
  PATCH_LEAD: 'patch /api/data/leads/:id',
  GET_LEAD_NOTES: 'get /api/data/leads/:id/notes',

  // News routes
  GET_NEWS: 'get /api/data/news',
  POST_NEWS: 'post /api/data/news',

  // Note routes
  GET_NOTES: 'get /api/data/notes', // Generic notes endpoint for backwards compatibility
  GET_NOTES_COUNT: 'get /api/data/notes/count',
  POST_NOTE: 'post /api/data/notes',
  PUT_NOTE: 'put /api/data/notes/:id',
  PATCH_NOTE: 'patch /api/data/notes/:id',
  PATCH_NOTE_STATUS: 'patch /api/data/notes/:id/status',
  DELETE_NOTE: 'delete /api/data/notes/:id',
  GET_PIPELINE_NOTES: 'get /api/data/pipeline/:id/notes',

  // Pipeline routes
  GET_PIPELINES: 'get /api/data/pipelines',
  GET_PIPELINE: 'get /api/data/pipelines/:id',
  GET_PIPELINE_STAGES: 'get /api/data/pipelines/:id/stages',
  GET_PIPELINE_OPPORTUNITIES: 'get /api/data/pipelines/:id/opportunities',

  // Opportunity routes
  GET_OPPORTUNITIES: 'get /api/data/opportunities',
  GET_OPPORTUNITY: 'get /api/data/opportunities/:id',
  POST_OPPORTUNITY: 'post /api/data/opportunities',
  PATCH_OPPORTUNITY_STAGE: 'patch /api/data/opportunities/:id/stage',
  PATCH_OPPORTUNITY_DATA: 'patch /api/data/opportunities/:id/data',
  PATCH_OPPORTUNITY_CONTACT: 'patch /api/data/opportunities/:id/contact',
  GET_OPPORTUNITY_NOTES: 'get /api/data/opportunities/:id/notes',
  GET_OPPORTUNITY_COUNT: 'get /api/data/opportunities/count',

  // Team routes
  GET_TEAMS: 'get /api/data/teams',
  GET_TEAM: 'get /api/data/teams/:id',
  DELETE_TEAM: 'delete /api/data/teams/:id',
  GET_TEAMS_USERS: 'get /api/data/teams/users',
  GET_TEAM_USERS: 'get /api/data/teams/:id/users',
  POST_TEAM_USER: 'post /api/data/teams/:id/users',
  DELETE_TEAM_USER: 'delete /api/data/teams/:id/users/:userId',

  // Schema routes
  GET_SCHEMA: 'get /api/data/schema',
  PATCH_SCHEMA: 'patch /api/data/schema'
} as const;
