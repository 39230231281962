/**
 * Generic search utility for filtering arrays of objects
 */

/**
 * Filters an array of objects based on search criteria
 * @param items Array of objects to search through
 * @param searchQuery String containing space-separated search terms
 * @param searchFields Optional array of field names to limit the search to specific fields
 * @returns Filtered array of objects that match the search criteria
 */
export const filterBySearchTerms = <T extends Record<string, any>>(
  items: T[],
  searchQuery: string,
  searchFields?: Array<keyof T>
): T[] => {
  // Safety check - if no items, return empty array
  if (!items || items.length === 0) {
    return [];
  }

  // Start with all provided items
  let filtered = items;

  // If no search query, return all items
  if (!searchQuery) return filtered;

  // Split search query into keywords
  const keywords = searchQuery.toLowerCase().split(/\s+/).filter(Boolean);
  if (keywords.length === 0) return filtered;

  return filtered.filter(item => {
    // If searchFields is provided, only search through those specific fields
    if (searchFields && searchFields.length > 0) {
      const fieldsToSearch: string[] = [];

      // Collect all the values from specified fields
      searchFields.forEach(field => {
        if (item[field] !== undefined) {
          const value = item[field];
          if (value != null && typeof value !== 'object') {
            fieldsToSearch.push(String(value).toLowerCase());
          }
        }
      });

      // Match if every keyword is found in at least one of the searchable values
      return keywords.every(keyword =>
        fieldsToSearch.some(value => value.includes(keyword))
      );
    }

    // If no searchFields provided, search all properties
    const searchableValues = Object.values(item)
      .filter(value => value != null && typeof value !== 'object')
      .map(value => String(value).toLowerCase());

    // Match if every keyword is found in at least one of the searchable values
    return keywords.every(keyword =>
      searchableValues.some(value => value.includes(keyword))
    );
  });
};

/**
 * Helper type for search configuration
 */
export type SearchConfig<T> = {
  items: T[];
  searchQuery: string;
  searchFields?: Array<keyof T>;
};

/**
 * Alternative version with a configuration object
 */
export const search = <T extends Record<string, any>>({
  items,
  searchQuery,
  searchFields,
}: SearchConfig<T>): T[] => {
  return filterBySearchTerms(items, searchQuery, searchFields);
};