import React, { useEffect } from 'react';
import { Note, NOTE_TYPES } from '../../models/Note';
import { THEME_CLASSES } from '../../constants/themeConstants';
import { useTeams } from '../../context/TeamContext';

type NoteType = typeof NOTE_TYPES[number];

interface NoteEditorProps {
  note?: Note;
  content: string;
  type: NoteType;
  teamIds: number[];
  accountName?: string;
  contactName?: string;
  parent?: string | null;
  onContentChange: (content: string) => void;
  onTypeChange: (type: NoteType) => void;
  onTeamChange: (teamIds: number[]) => void;
  submitButtonText?: string;
  onSubmit: () => void;
  onCancel: () => void;
  currentUserId?: number;
  isModalOpen?: boolean;
  toggleModal?: () => void;
  error?: string | null;
  setError?: (error: string | null) => void;
}

const NoteEditor: React.FC<NoteEditorProps> = ({
  note,
  content,
  type,
  teamIds,
  accountName,
  contactName,
  parent = null,
  onContentChange,
  onTypeChange,
  onTeamChange,
  submitButtonText = note ? 'Save Note' : 'Create Note',
  onSubmit,
  onCancel,
  currentUserId,
}) => {
  const { teams } = useTeams();
  const isEditable = !note || note.createdBy === currentUserId;
  const originalContent = note?.content || '';
  const [teamError, setTeamError] = React.useState<string>('');
  const [contentError, setContentError] = React.useState<string>('');

  // Initialize note data
  useEffect(() => {
    setTeamError('');
    setContentError('');
    if (note) {
      if (note.teamIds) {
        const noteTeamIds = note.teamIds.split(',').map(id => parseInt(id, 10));
        onTeamChange(noteTeamIds);
      }
      if (note.type) {
        onTypeChange(note.type as NoteType);
      }
    }
  }, [note, onTeamChange, onTypeChange]);

  const isTeamSelected = (teamId: number): boolean => {
    return !teamIds?.includes(9999) && (teamIds?.includes(teamId) || false);
  };

  const checkTeamError = (teamIds: number[]): string => {
    return teamIds.length === 0 ? 'Please select at least one team' : '';
  };

  const checkContentError = (content: string): string => {
    return !content.trim() ? 'Please enter note content' : '';
  };

  const handleTeamChange = (newTeamIds: number[]) => {
    setTeamError(checkTeamError(newTeamIds));
    onTeamChange(newTeamIds);
  };

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    if (!isEditable) return;

    const contentErr = checkContentError(content);
    const teamErr = checkTeamError(teamIds);

    setContentError(contentErr);
    setTeamError(teamErr);

    if (contentErr || teamErr) {
      return;
    }

    onSubmit();
  };

  const handleCancel = () => {
    onContentChange(originalContent);
    setTeamError('');
    setContentError('');
    onCancel();
  };

  return (
    <form onSubmit={handleSubmit} className="flex-1 flex flex-col h-full">
      {/* Title */}
      <div className={`px-6 py-3 ${THEME_CLASSES.border} border-b`}>
        {(accountName || contactName) && (
          <div className={`text-lg font-semibold ${THEME_CLASSES.text.primary} mb-2`}>
            {contactName || accountName}
            {contactName && accountName && (
              <span className={`text-sm ${THEME_CLASSES.text.secondary} ml-2`}>
                ({accountName})
              </span>
            )}
          </div>
        )}
        {parent !== "NewNote" && (
          <div className={`text-xs font-medium ${THEME_CLASSES.text.secondary}`}>
            {note ? 'Editing Note' : 'Creating Note'}
          </div>
        )}
      </div>
      {/* Note Content */}
      <div className="flex flex-col flex-1 p-6">
        <label className={`text-xs font-medium ${THEME_CLASSES.text.secondary} mb-2`}>
          Note
        </label>
        <textarea
          value={content}
          onChange={(e) => onContentChange(e.target.value)}
          readOnly={!isEditable}
          className={`flex-1 w-full max-h-[90%] ${THEME_CLASSES.input.textarea} ${THEME_CLASSES.input.background} ${THEME_CLASSES.input.border} ${THEME_CLASSES.input.text} ${THEME_CLASSES.input.placeholder} ${THEME_CLASSES.input.base} ${!isEditable ? 'cursor-not-allowed opacity-75' : ''}`}
          placeholder="Enter note title and content"
        />
      </div>

      {/* Note Type and Team */}
      <div className={`grid grid-cols-2 gap-4 px-6 py-4 ${THEME_CLASSES.border} border-t`}>
        <div>
          <label className={`block text-xs font-medium ${THEME_CLASSES.text.secondary} mb-2`}>
            Type
          </label>
          <select
            value={type}
            onChange={(e) => onTypeChange(e.target.value as NoteType)}
            disabled={!isEditable}
            className={`block w-full ${THEME_CLASSES.input.field} ${THEME_CLASSES.input.background} ${THEME_CLASSES.input.border} ${THEME_CLASSES.input.text} ${THEME_CLASSES.input.base} ${!isEditable ? 'cursor-not-allowed opacity-75' : ''}`}
          >
            {NOTE_TYPES.filter(noteType => noteType !== 'All').map(noteType => (
              <option key={noteType} value={noteType}>{noteType}</option>
            ))}
          </select>
        </div>

        <div>
          <label className={`block text-xs font-medium ${THEME_CLASSES.text.secondary} mb-2`}>
            Teams
          </label>
          <div className="space-y-2">
            <div className="flex flex-wrap gap-2">
              <label
                className={`inline-flex items-center px-3 py-1 rounded-full text-xs cursor-pointer ${
                  teamIds?.includes(9999)
                    ? THEME_CLASSES.button.primary
                    : `${THEME_CLASSES.text.secondary} ${THEME_CLASSES.border} border`
                }`}
              >
                <input
                  type="checkbox"
                  className="hidden"
                  checked={teamIds?.includes(9999)}
                  disabled={!isEditable}
                  onChange={(e) => {
                      const newTeamIds = e.target.checked ? [9999] : [];
                      handleTeamChange(newTeamIds);
                  }}
                />
                All Teams
              </label>
              {teams.map(team => {
                      const isSelected = !teamIds?.includes(9999) && teamIds?.includes(team.teamId);
                return (
                  <label
                    key={team.teamId}
                    className={`inline-flex items-center px-3 py-1 rounded-full text-xs cursor-pointer ${
                      isTeamSelected(team.teamId)
                        ? THEME_CLASSES.button.primary
                        : `${THEME_CLASSES.text.secondary} ${THEME_CLASSES.border} border ${
                            isEditable ? 'hover:bg-opacity-10 hover:bg-primary' : ''
                          }`
                    } ${!isEditable && !isSelected ? 'opacity-50' : ''}`}
                  >
                    <input
                      type="checkbox"
                      className="hidden"
                      checked={isTeamSelected(team.teamId)}
                      disabled={!isEditable}
                      onChange={(e) => {
                        if (teamIds?.includes(9999)) {
                          // If "All Teams" was selected, deselect it
                          handleTeamChange([team.teamId]);
                        } else {
                          let newTeamIds;
                          if (e.target.checked) {
                            newTeamIds = [...new Set([...(teamIds || []), team.teamId])];
                          } else {
                            newTeamIds = (teamIds || []).filter(id => id !== team.teamId);
                          }
                          handleTeamChange(newTeamIds);
                        }
                      }}
                    />
                    {team.teamName}
                  </label>
                );
              })}
            </div>
          </div>
        </div>
      </div>

      {/* Action Buttons */}
      <div className={`${THEME_CLASSES.border} border-t`}>
        <div className="flex items-center justify-between gap-4 p-6">
          <div className="text-sm text-red-500">
            {[contentError, teamError].filter(Boolean).join(' • ')}
          </div>
          <div className="flex items-center gap-4">
            <button
              type="button"
              onClick={handleCancel}
              disabled={!isEditable}
              className={`inline-flex items-center px-4 py-2 rounded-md text-xs font-medium ${isEditable
                ? THEME_CLASSES.button.secondary
                : `${THEME_CLASSES.button.secondary} opacity-50 cursor-not-allowed`
              }`}
            >
              Cancel
            </button>
            <button
              type="submit"
              disabled={!isEditable}
              className={`inline-flex items-center px-4 py-2 border border-transparent rounded-md shadow-sm text-xs font-medium ${isEditable
                ? THEME_CLASSES.button.primary
                : `${THEME_CLASSES.button.primary} opacity-50 cursor-not-allowed`
              }`}
            >
              {submitButtonText}
            </button>
          </div>
        </div>
      </div>
      <div>&nbsp;</div>
    </form>
  );
};

export default NoteEditor;
