import React, { useState, useEffect } from 'react';
import { highlightText } from '../utils/highlightUtils';
import { useTeams } from '../context/TeamContext';
import { useNotification } from '../context/NotificationContext';
import { request } from '../services/dataService';
import { API_ENDPOINTS } from '../constants/apiEndpoints';
import type { User } from '../models/User';
import type { Team } from '../models/Team';
import { THEME_CLASSES } from '../constants/themeConstants';
import Spinner from '../components/common/Spinner';

interface UserWithTeams extends Omit<User, 'userId' | 'teams'> {
  userId: number; // Make userId required
  teams: Team[];
}

interface UserTeamData {
  userId: number;
  name: string;
  email: string;
  teamId: number;
  teamName: string;
  parentId: number | null;
}

const Admin: React.FC = () => {
  const { isLoading: isLoadingTeams } = useTeams();
  const { showNotification } = useNotification();
  const [users, setUsers] = useState<UserWithTeams[]>([]);
  const [teams, setTeams] = useState<(Team & { level: number })[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [searchQuery, setSearchQuery] = useState('');
  const [selectedType, setSelectedType] = useState<'team' | 'user' | null>(null);
  const [selectedId, setSelectedId] = useState<string | number | null>(null);
  useEffect(() => {
    loadData();
  }, []);

  // Helper function to organize teams into hierarchy with levels
  const organizerTeamsHierarchy = (teams: Team[]): (Team & { level: number })[] => {
    const teamMap = new Map<number, Team & { level: number }>();
    const result: (Team & { level: number })[] = [];

    // Helper function to process a team and its children recursively
    const processTeam = (team: Team, level: number, processed: Set<number>) => {
      if (processed.has(team.teamId)) return;

      // Add the team with its level to both map and result array
      const teamWithLevel = { ...team, level };
      teamMap.set(team.teamId, teamWithLevel);
      result.push(teamWithLevel);
      processed.add(team.teamId);

      // Process all children of this team
      teams
        .filter(t => t.parentId === team.teamId)
        .sort((a, b) => a.teamName.localeCompare(b.teamName)) // Sort children by name
        .forEach(child => processTeam(child, level + 1, processed));
    };

    // First process all root teams (teams with no parent)
    teams
      .filter(team => !team.parentId)
      .sort((a, b) => a.teamName.localeCompare(b.teamName)) // Sort root teams by name
      .forEach(rootTeam => processTeam(rootTeam, 0, new Set<number>()));

    // Process any remaining teams that might be orphaned
    teams
      .filter(team => !teamMap.has(team.teamId))
      .sort((a, b) => a.teamName.localeCompare(b.teamName))
      .forEach(team => processTeam(team, 0, new Set<number>()));

    return result;
  };

  const loadData = async () => {
    setIsLoading(true);
    try {
      const usersWithTeamsData = await request(API_ENDPOINTS.GET_TEAMS_USERS);

      // Process the raw data to create unique users and teams lists
      const uniqueTeams = new Map<number, Team>();
      const uniqueUsers = new Map<string, UserWithTeams>();

      // First, create entries for all users regardless of team
      usersWithTeamsData.forEach((data: UserTeamData) => {
        if (!data.email) return; // Skip users with null/undefined email
        if (!uniqueUsers.has(data.email)) {
          uniqueUsers.set(data.email, {
            userId: data.userId,
            name: data.name,
            email: data.email,
            teams: []
          });
        }
      });

      // Then process team assignments only for valid teams
      usersWithTeamsData.forEach((data: UserTeamData) => {
        if (!data.email || !data.teamName) return; // Skip invalid users and teams

        // Add team to unique teams if not exists
        if (!uniqueTeams.has(data.teamId)) {
          uniqueTeams.set(data.teamId, {
            teamId: data.teamId,
            teamName: data.teamName,
            parentId: data.parentId
          });
        }

        // Add team to user's teams array
        const user = uniqueUsers.get(data.email)!;
        const team = uniqueTeams.get(data.teamId)!;
        if (!user.teams.find(t => t.teamId === team.teamId)) {
          user.teams.push(team);
        }
      });

      setUsers(Array.from(uniqueUsers.values()));
      // Convert teams to hierarchical structure
      const teamsArray = Array.from(uniqueTeams.values());
      const teamsWithLevels = organizerTeamsHierarchy(teamsArray);
      setTeams(teamsWithLevels);
    } catch (error) {
      console.error('Error loading data:', error);
      showNotification('Failed to load data', 'error');
    } finally {
      setIsLoading(false);
    }
  };

  const isTeamChecked = (teamId: number) => {
    if (selectedType === 'team') {
      return teamId === selectedId;
    }
    if (selectedType === 'user') {
      const user = users.find(u => u.email === selectedId);
      return user?.teams.some(t => t.teamId === teamId) || false;
    }
    return false;
  };

  const isUserChecked = (email: string) => {
    if (selectedType === 'user') {
      return email === selectedId;
    }
    if (selectedType === 'team') {
      const user = users.find(u => u.email === email);
      return user?.teams.some(t => t.teamId === selectedId) || false;
    }
    return false;
  };

  const shouldShowRing = (type: 'team' | 'user', id: number | string) => {
    return selectedType === type && (
      (type === 'team' && id === selectedId) ||
      (type === 'user' && id === selectedId)
    );
  };

  const handleSelection = (type: 'team' | 'user', id: number | string) => {
    // New selection - update both type and id
    setSelectedType(type);
    setSelectedId(id);
  };

  // Clear selection when search query changes
  useEffect(() => {
    setSelectedType(null);
    setSelectedId(null);
  }, [searchQuery]);

  const filteredUsers = users
    .filter(user => {
      if (!searchQuery.trim()) return true;
      const query = searchQuery.toLowerCase();
      return (
        user.name?.toLowerCase().includes(query) ||
        user.email.toLowerCase().includes(query)
      );
    })
    .sort((a, b) => {
      const nameA = a.name?.toLowerCase() || a.email.toLowerCase();
      const nameB = b.name?.toLowerCase() || b.email.toLowerCase();
      return nameA.localeCompare(nameB);
    });

  const renderSearchBar = () => (
    <div className="w-96 relative ml-auto">
      <div className="absolute inset-y-0 left-0 pl-4 flex items-center pointer-events-none">
        <svg className={`h-4 w-4 ${THEME_CLASSES.text.secondary}`} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
          <path fillRule="evenodd" d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z" clipRule="evenodd" />
        </svg>
      </div>
      <input
        type="text"
        placeholder="Search users..."
        value={searchQuery}
        onChange={(e) => setSearchQuery(e.target.value)}
        className={`w-full pl-11 pr-8 py-2 border rounded-md text-xs font-medium focus:outline-none focus:ring-1 focus:ring-blue-500 focus:border-blue-500 ${THEME_CLASSES.input.background} ${THEME_CLASSES.input.border} ${THEME_CLASSES.input.text} ${THEME_CLASSES.input.placeholder}`}
      />
      {searchQuery && (
        <button
          onClick={() => setSearchQuery('')}
          className="absolute inset-y-0 right-0 pr-3 flex items-center"
        >
          <svg className={`h-4 w-4 ${THEME_CLASSES.text.secondary} hover:${THEME_CLASSES.text.primary}`} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
            <path fillRule="evenodd" d="M4.293 4.293a1 1 011.414 0L10 8.586l4.293-4.293a1 1 111.414 1.414L11.414 10l4.293 4.293a1 1 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clipRule="evenodd" />
          </svg>
        </button>
      )}
    </div>
  );

  if (isLoading || isLoadingTeams) {
    return (
      <div className="p-6">
        <div className="mt-4 flex justify-center">
          <Spinner />
        </div>
      </div>
    );
  }

  const handleUserCheckboxClick = async (e: React.MouseEvent, user: UserWithTeams) => {
    e.stopPropagation(); // Prevent label click handling
    if (!selectedType || selectedType !== 'team') return; // Only work when a team is selected
    const teamId = typeof selectedId === 'number' ? selectedId : null;
    if (teamId === null) return;

    const isCurrentlyChecked = isUserChecked(user.email);

    await handleUserTeamChange(user, teams.find(t => t.teamId === teamId)!, isCurrentlyChecked);
  };

  const handleTeamCheckboxClick = async (e: React.MouseEvent, team: Team) => {
    e.stopPropagation(); // Prevent label click handling
    if (!selectedType || selectedType !== 'user') return; // Only work when a user is selected

    const user = users.find(u => u.email === selectedId);
    if (!user?.userId) return;

    const isCurrentlyChecked = isTeamChecked(team.teamId);

    await handleUserTeamChange(user, team, isCurrentlyChecked);
  };

  const handleUserTeamChange = async (user: UserWithTeams, team: Team, isCurrentlyChecked: boolean) => {
    setUsers(prevUsers => prevUsers.map(u => {
      if (u.userId === user.userId) {
        return {
          ...u,
          teams: isCurrentlyChecked
            ? u.teams.filter(t => t.teamId !== team.teamId)
            : [...u.teams, team]
        };
      }
      return u;
    }));

    try {
      if (isCurrentlyChecked) {
        await request(API_ENDPOINTS.DELETE_TEAM_USER, {
          id: team.teamId,
          userId: user.userId
        });
      } else {
        await request(API_ENDPOINTS.POST_TEAM_USER, {
          id: team.teamId,
          userId: user.userId
        });
      }
    } catch (error) {
      console.error('Error updating team membership:', error);
      showNotification('Failed to update team membership', 'error');
      await loadData();
    }
  };

  return (
    <div className="p-6">
      {/* <div className="flex items-center mb-6">
        <div className="text-xs text-gray-500 dark:text-gray-400">
          {selectedType && selectedId && (
            <span>
              Selected: {selectedType === 'team' ? 'Team ' : 'User '}
              {selectedType === 'team'
                ? teams.find(t => t.teamId === selectedId)?.teamName
                : users.find(u => u.email === selectedId)?.name}
            </span>
          )}
        </div>
      </div> */}

      <div className="flex gap-6 h-[calc(100vh-110px)]">
        {/* Teams Panel */}
        <div className="flex-1 overflow-auto flex flex-col">
          <h2 className={`text-xl font-semibold mb-5 ${THEME_CLASSES.text.primary}`}>Teams</h2>
          <div className={`flex-1 space-y-2 p-4 rounded-xl shadow-lg overflow-auto ${THEME_CLASSES.border} ${selectedType === 'team' ? 'bg-blue-50 dark:bg-blue-900/30' : `${THEME_CLASSES.card.background}`}`}>
            {teams.map(team => (
              <div
                key={team.teamId}
                className={`flex items-center p-2 rounded-md ${shouldShowRing('team', team.teamId) ? 'ring-2 ring-blue-500' : ''} bg-transparent`}
                style={{ paddingLeft: `${(team.level * 20) + 8}px` }}
              >
                <input
                  type="checkbox"
                  id={`team-${team.teamId}`}
                  checked={isTeamChecked(team.teamId)}
                  onChange={() => { }} // React requires onChange with checked
                  onClick={(e) => handleTeamCheckboxClick(e, team)}
                  className="mr-2 dark:bg-gray-700 focus:outline-none"
                />
                <label
                  htmlFor={`team-${team.teamId}`}
                  onClick={() => handleSelection('team', team.teamId)}
                  className={`cursor-pointer flex-1 text-xs transition-all duration-200 ${THEME_CLASSES.text.primary} ${THEME_CLASSES.hover.background} flex justify-between`}
                >
                  <span>{team.teamName}</span>
                  <span className="ml-2 text-gray-500 dark:text-gray-400">
                    {users.filter(user => user.teams.some(t => t.teamId === team.teamId)).length}
                  </span>
                </label>
              </div>
            ))}
          </div>
        </div>

        {/* Users Panel */}
        <div className="flex-1 overflow-auto flex flex-col">
          <div className="flex items-center justify-between mb-4">
            <h2 className={`text-sm font-semibold ${THEME_CLASSES.text.primary}`}>Users</h2>
            {renderSearchBar()}
          </div>
          <div className={`flex-1 space-y-2 p-4 rounded-xl shadow-lg overflow-auto ${THEME_CLASSES.border} ${selectedType === 'user' ? 'bg-blue-50 dark:bg-blue-900/30' : `${THEME_CLASSES.card.background}`}`}>
            {filteredUsers.map(user => (
              <div
                key={user.email}
                className={`flex items-center p-2 rounded-md ${shouldShowRing('user', user.email) ? 'ring-2 ring-blue-500' : ''} bg-transparent`}
              >
                <input
                  type="checkbox"
                  id={`user-${user.email}`}
                  checked={isUserChecked(user.email)}
                  onChange={() => { }} // React requires onChange with checked
                  onClick={(e) => handleUserCheckboxClick(e, user)}
                  className="mr-2 dark:bg-gray-700 focus:outline-none"
                />
                <label
                  htmlFor={`user-${user.email}`}
                  onClick={() => handleSelection('user', user.email)}
                  className={`cursor-pointer flex-1 text-xs transition-all duration-200 ${THEME_CLASSES.text.primary} ${THEME_CLASSES.hover.background} flex justify-between`}
                >
                  <span>
                    {highlightText(user.name || null, searchQuery ? [searchQuery] : [])}{' '}
                    ({highlightText(user.email, searchQuery ? [searchQuery] : [])})
                  </span>
                  <span className="ml-2 text-gray-500 dark:text-gray-400">
                    {user.teams.length}
                  </span>
                </label>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Admin;
