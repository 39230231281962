import { useTheme } from '../context/ThemeContext';
import { THEME_CLASSES } from '../constants/themeConstants';
import { useState, useEffect, useRef, MouseEvent as ReactMouseEvent } from 'react';
import QuickSearch from './contact/QuickSearch';
import { getUserData, logout } from '../services/authService';
import { useNavigate } from 'react-router-dom';
import NewNote from './note/NewNote';
import { Contact } from '../models/Contact';
import { useTeams } from '../context/TeamContext';

interface NavbarProps {
  onToggleModal: () => void;
  onToggleChat: () => void;
  currentContact?: Contact;
}

const Navbar: React.FC<NavbarProps> = ({ onToggleModal, onToggleChat, currentContact }) => {
  const { isDark, toggleTheme } = useTheme();
  const [isModalOpen, setModalOpen] = useState<boolean>(false);
  const [isProfileDropdownOpen, setIsProfileDropdownOpen] = useState<boolean>(false);
  const profileDropdownRef = useRef<HTMLDivElement | null>(null);
  const navigate = useNavigate();
  const { teams } = useTeams();

  const toggleModal = (): void => {
    setModalOpen(!isModalOpen);
  };

  // Close profile dropdown when clicking outside
  useEffect(() => {
    const handleClickOutside = (e: MouseEvent): void => {
      const target = e.target as HTMLElement;
      if (!target.closest('.profile-container')) {
        setIsProfileDropdownOpen(false);
      }
    };

    document.addEventListener('click', handleClickOutside);
    return () => document.removeEventListener('click', handleClickOutside);
  }, []);

  const handleLogout = async (e: ReactMouseEvent) => {
    e.preventDefault();
    e.stopPropagation();
    // Call logout and wait for it to complete
    // The logout function internally handles the redirect
    await logout();
  };

  return (
    <>
      <nav className="relative w-full z-50">
        <div className={`${THEME_CLASSES.background} relative`}>
          <div className={`flex items-center justify-between px-4 py-2 md:px-6 ${THEME_CLASSES.background}`}>
            {/* Left side: Logo, Search and Add Note */}
            <div className="flex items-center flex-1">
              {/* Logo */}
              <div onClick={onToggleModal} className="flex items-center mr-4 cursor-pointer">
                <svg className="text-blue-500 w-8 h-8 ml-1.5" viewBox="0 0 24 24" fill="currentColor">
                  <rect x="3" y="3" width="7" height="7" />
                  <rect x="14" y="3" width="7" height="7" />
                  <rect x="3" y="14" width="7" height="7" />
                  <rect x="14" y="14" width="7" height="7" />
                </svg>
              </div>
              {/* Search bar */}
              <QuickSearch
                placeholder="Search with name.."
                className={`w-[200px] md:w-[300px] ${THEME_CLASSES.input.border} ${THEME_CLASSES.input.background} ${THEME_CLASSES.input.text} ${THEME_CLASSES.input.placeholder}`}
              />

              {/* Desktop-only Add Note button */}
              <div className="hidden md:flex items-center ml-4">
                <button
                  onClick={toggleModal}
                  className={`inline-flex items-center px-4 py-2 border border-transparent rounded-md shadow-sm text-xs font-medium ${THEME_CLASSES.button.primary}`}
                >
                  <svg className="h-5 w-5 mr-2" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 4v16m8-8H4" />
                  </svg>
                  Add Note
                </button>
              </div>
            </div>

            {/* Right side: Profile section */}
            <div className="flex items-center gap-2">
              {/* Chat button */}
              <button
                onClick={() => onToggleChat()}
                className={`p-2 rounded-md ${THEME_CLASSES.text.secondary} ${THEME_CLASSES.hover.background}`}
              >
                <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M8 12h.01M12 12h.01M16 12h.01M21 12c0 4.418-4.03 8-9 8a9.863 9.863 0 01-4.255-.949L3 20l1.395-3.72C3.512 15.042 3 13.574 3 12c0-4.418 4.03-8 9-8s9 3.582 9 8z" />
                </svg>
              </button>

              {/* Profile container */}
              <div className="relative profile-container flex items-center" ref={profileDropdownRef}>
                <div
                  className="flex items-center cursor-pointer py-2"
                  onClick={(e: ReactMouseEvent) => {
                    e.stopPropagation();
                    setIsProfileDropdownOpen(!isProfileDropdownOpen);
                  }}
                >
                  <img
                    src={getUserData()?.picture}
                    alt={getUserData()?.name}
                    className="w-10 h-10 rounded-full object-cover"
                    onError={(e) => {
                      const target = e.target as HTMLImageElement;
                      target.src = 'data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAyNCAyNCIgZmlsbD0ibm9uZSIgc3Ryb2tlPSJjdXJyZW50Q29sb3IiPjxwYXRoIHN0cm9rZUxpbmVjYXA9InJvdW5kIiBzdHJva2VMaW5lam9pbj0icm91bmQiIHN0cm9rZVdpZHRoPSIyIiBkPSJNMTYgN2E0IDQgMCAxMS04IDAgNCA0IDAgMDE4IDB6TTEyIDE0YTcgNyAwIDAwLTcgN2gxNGE3IDcgMCAwMC03LTd6Ii8+PC9zdmc+';
                    }}
                  />
                  <span className="hidden md:block text-sm text-gray-700 dark:text-gray-200 ml-2">
                    {getUserData()?.name}
                  </span>
                </div>

                {/* Dropdown Menu */}
                {isProfileDropdownOpen && (
                  <div className={`absolute right-0 top-full mt-3 w-48 ${THEME_CLASSES.background} rounded-md shadow-lg py-1 border ${THEME_CLASSES.border} z-[60]`}>
                    {/* Teams Section */}
                    {teams.map((team) => (
                      <div
                        key={team.teamId}
                        className={`px-4 py-2 text-sm text-left ${THEME_CLASSES.text.secondary}`}
                      >
                        {team.teamName}
                      </div>
                    ))}
                    {/* Divider if there are teams */}
                    {teams.length > 0 && (
                      <div className={`my-1 border-t ${THEME_CLASSES.border}`}></div>
                    )}
                    <button
                      onClick={handleLogout}
                      className={`w-full px-4 py-2 text-sm text-right ${THEME_CLASSES.text.secondary} ${THEME_CLASSES.hover.background}`}
                    >
                      Log Out
                    </button>
                  </div>
                )}
              </div>

              {/* Theme toggle */}
              <button
                onClick={toggleTheme}
                className={`hidden md:flex p-2 rounded-md ${THEME_CLASSES.text.secondary} ${THEME_CLASSES.hover.background}`}
              >
                {isDark ? (
                  <svg className="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 3v1m0 16v1m9-9h-1M4 12H3m15.364 6.364l-.707-.707M6.343 6.343l-.707-.707m12.728 0l-.707.707M6.343 17.657l-.707.707M16 12a4 4 0 11-8 0 4 4 0 018 0z" />
                  </svg>
                ) : (
                  <svg className="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M20.354 15.354A9 9 0 018.646 3.646 9.003 9.003 0 0012 21a9.003 9.003 0 008.354-5.646z" />
                  </svg>
                )}
              </button>
            </div>
          </div>
        </div>

        {/* NewNote component */}
        <NewNote
          isModalOpen={isModalOpen}
          toggleModal={toggleModal}
        />
      </nav>
    </>
  );
};

export default Navbar;
