import { FC, useState, useEffect, useRef, useCallback } from 'react';
import { ColumnState, ColDef } from 'ag-grid-community';
import { FormField } from '../components/common/FormField';
import { useNavigate, useLocation } from 'react-router-dom';
import { THEME_CLASSES } from '../constants/themeConstants';
import { request } from '../services/dataService';
import { API_ENDPOINTS } from '../constants/apiEndpoints';
import { Pipeline } from '../models/Pipeline';
import { useTeams } from '../context/TeamContext';
import Spinner from '../components/common/Spinner';
import OpportunityBoard from '../components/pipeline/OpportunityBoard';
import OpportunityGrid from '../components/pipeline/OpportunityGrid';
import OpportunityForm from '../components/pipeline/OpportunityForm';
import { Opportunity } from '../models/Opportunity';
import StageSelector from '../components/pipeline/StageSelector';
import ColumnSelector from '../components/pipeline/ColumnSelector';
import { createColumnDefs } from '../utils/gridUtils';
const Pipelines: FC = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { teams, isLoading: teamsLoading } = useTeams();

  const [pipelines, setPipelines] = useState<Pipeline[]>([]);
  const [selectedPipeline, setSelectedPipeline] = useState<Pipeline | null>(null);
  const [viewMode, setViewMode] = useState<'board' | 'table'>('board');
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [opportunities, setOpportunities] = useState<Opportunity[]>([]);
  const [searchQuery, setSearchQuery] = useState(() => {
    return localStorage.getItem('pipelineSearchQuery') || '';
  });

  // Stage visibility management (moved from pipelineUtils.ts)
  const [visibleStages, setVisibleStages] = useState<string[]>([]);
  const [isStageSelectionOpen, setIsStageSelectionOpen] = useState(() => {
    const stored = localStorage.getItem('pipeline-stage-selection-open');
    return stored ? JSON.parse(stored) : false;
  });

  // Initialize visible stages when pipeline changes
  useEffect(() => {
    if (!selectedPipeline) return;

    try {
      const stored = localStorage.getItem(`pipeline-${selectedPipeline.id}-visible-stages`);
      let storedStages: string[] = [];

      if (stored) {
        storedStages = JSON.parse(stored);
      }

      // If no stages in storage or stored array is empty, make all stages visible
      if (!stored || storedStages.length === 0) {
        const allStages = selectedPipeline.stages?.map(s => s.id.toString()) || [];
        localStorage.setItem(`pipeline-${selectedPipeline.id}-visible-stages`, JSON.stringify(allStages));
        setVisibleStages(allStages);
      } else {
        setVisibleStages(storedStages);
      }
    } catch (error) {
      console.error('Error loading visible stages:', error);
      // Default to all stages visible
      const allStages = selectedPipeline.stages?.map(s => s.id.toString()) || [];
      setVisibleStages(allStages);
    }
  }, [selectedPipeline?.id]);

  const initializedRef = useRef(false);
  const [modalState, setModalState] = useState<{
    isOpen: boolean;
    opportunity: Opportunity | null;
    pipeline?: Pipeline;
  }>({
    isOpen: false,
    opportunity: null
  });

  const [isColumnSelectionOpen, setIsColumnSelectionOpen] = useState(false);
  const [gridColumnDefs, setGridColumnDefs] = useState<ColDef<Opportunity>[]>([]);

  const initializeColumnDefs = (fields: FormField[]): ColDef<Opportunity>[] => {
    return createColumnDefs<Opportunity>(fields);
  };

  // Load or initialize column defs when pipeline changes
  useEffect(() => {
    if (!selectedPipeline?.fields) return;

    try {
      const savedColDefs = localStorage.getItem(`grid_columns_${selectedPipeline.id}`);
      const parsedColDefs = savedColDefs ? JSON.parse(savedColDefs) : null;

      // Initialize new column definitions
      const newColumnDefs = initializeColumnDefs(selectedPipeline.fields);

      // If we have saved column defs, merge them with the new definitions
      if (parsedColDefs && Array.isArray(parsedColDefs)) {
        console.log('Loading saved column definitions for pipeline', selectedPipeline.id);

        // Check if all fields are present in the saved defs
        const fieldNames = selectedPipeline.fields.map(f => f.name);
        const savedFieldNames = parsedColDefs.map(col => col.field || col.colId);

        // If fields have changed, use new column defs as is
        if (!fieldNames.every(name => savedFieldNames.includes(name))) {
          console.log('Field structure changed, using new column definitions');
          setGridColumnDefs(newColumnDefs);
        } else {
          // Create a map of new column defs for easier access
          const newColDefsMap = new Map(
            newColumnDefs.map(colDef => [colDef.field || colDef.colId, colDef])
          );

          // Apply saved column state while preserving important functionality
          // Keep the saved column order
          const mergedColumnDefs = parsedColDefs.map(savedCol => {
            const fieldOrColId = savedCol.field || savedCol.colId;
            const newColDef = newColDefsMap.get(fieldOrColId);

            if (newColDef) {
              // Keep essential functionality from new definition, but use saved state properties
              return {
                ...newColDef,
                width: savedCol.width || newColDef.width,
                hide: typeof savedCol.hide !== 'undefined' ? savedCol.hide : newColDef.hide,
                sort: savedCol.sort || newColDef.sort,
                sortIndex: typeof savedCol.sortIndex !== 'undefined' ? savedCol.sortIndex : newColDef.sortIndex,
                pinned: savedCol.pinned || newColDef.pinned,
                lockPinned: typeof savedCol.lockPinned !== 'undefined' ? savedCol.lockPinned : newColDef.lockPinned,
                field: newColDef.field,
                colId: newColDef.colId || newColDef.field
              } as ColDef<Opportunity>;
            }
            return undefined;
          }).filter(Boolean) as ColDef<Opportunity>[];

          // Add any new fields that weren't in the saved state
          newColumnDefs.forEach(colDef => {
            const fieldOrColId = colDef.field || colDef.colId;
            if (!parsedColDefs.some(saved => (saved.field || saved.colId) === fieldOrColId)) {
              mergedColumnDefs.push(colDef);
            }
          });

          console.log('Merged column definitions:', mergedColumnDefs);
          setGridColumnDefs(mergedColumnDefs);
        }
      } else {
        console.log('No saved column definitions found, using default');
        setGridColumnDefs(newColumnDefs);
      }
    } catch (error) {
      console.error('Error loading column definitions:', error);
      setGridColumnDefs(initializeColumnDefs(selectedPipeline.fields));
    }
  }, [selectedPipeline]);

  // Unified handler for column definition changes from both grid and selector
  const handleColumnStateChanged = useCallback((updatedColDefs: ColDef<Opportunity, any>[]) => {
    if (!updatedColDefs || !Array.isArray(updatedColDefs) || updatedColDefs.length === 0 || !selectedPipeline) {
      console.warn('Invalid column definitions received', updatedColDefs);
      return;
    }

    // Get the current column definitions from gridColumnDefs
    setGridColumnDefs(prevDefs => {
      // Create a map of existing column definitions for reference
      const existingColsMap = new Map(
        prevDefs.map(col => [(col.field || col.colId)!, col])
      );

      // Merge the updated column definitions with existing ones
      const mergedDefs = updatedColDefs.map<ColDef<Opportunity>>(updatedCol => {
        const colId = updatedCol.field || updatedCol.colId;
        const existingCol = existingColsMap.get(colId!);

        // Keep existing column properties but update position, width, etc.
        const mergedCol = {
          ...existingCol,
          ...updatedCol,
          // Preserve critical configuration
          field: existingCol?.field || updatedCol.field,
          colId: existingCol?.colId || updatedCol.colId,
          // Ensure title column remains pinned
          pinned: colId === 'title' ? 'left' : updatedCol.pinned
        } as ColDef<Opportunity>;

        return mergedCol;
      });

      try {
        // Save the full column state to localStorage
        localStorage.setItem(`grid_columns_${selectedPipeline.id}`, JSON.stringify(mergedDefs));
        console.log('Column definitions updated and saved for pipeline', selectedPipeline.id);
      } catch (error) {
        console.error('Error saving column definitions:', error);
      }

      return mergedDefs;
    });
  }, [selectedPipeline]);

  // Enable column selector for table view
  useEffect(() => {
    setIsColumnSelectionOpen(false);
  }, [viewMode]);

  const stageSelectRef = useRef<HTMLDivElement>(null);

  const hasTeamAccess = useRef((teamId: number) => {
    if (teamsLoading) return false;
    return teams.some(team => team.teamId === teamId);
  });

  useEffect(() => {
    hasTeamAccess.current = (teamId: number) => {
      if (teamsLoading) return false;
      return teams.some(team => team.teamId === teamId);
    };
  }, [teams, teamsLoading]);

  const handleModalClose = (wasUpdated = false) => {
    setModalState({ isOpen: false, opportunity: null });
    if (wasUpdated) {
      refreshPipeline();
    }
  };

  const refreshPipeline = async () => {
    if (selectedPipeline?.id) {
      try {
        const stageData = await request(API_ENDPOINTS.GET_PIPELINE_STAGES, { id: selectedPipeline.id });
        const updatedPipeline = {
          ...selectedPipeline,
          stages: stageData,
          fields: JSON.parse(selectedPipeline.schema)
        };
        setSelectedPipeline(updatedPipeline);
      } catch (err) {
        console.error('Failed to refresh pipeline:', err);
      }
    }
  };

  const getPipelineIdFromUrl = () => {
    const match = location.pathname.match(/\/pipelines\/(\d+)/);
    return match ? parseInt(match[1]) : null;
  };

  const handlePipelineChange = async (event: React.ChangeEvent<HTMLSelectElement>) => {
    const selectedId = parseInt(event.target.value);
    const pipeline = pipelines.find((p: Pipeline) => p.id === selectedId);

    if (!pipeline || (selectedPipeline && selectedPipeline.id === pipeline.id)) {
      setSelectedPipeline(null);
      setModalState(state => ({
        ...state,
        pipeline: undefined
      }));
      setError(null);
      return;
    }

    if (!teamsLoading && !hasTeamAccess.current(pipeline.teamId)) {
      setError('You do not have access to this pipeline');
      setSelectedPipeline(null);
      setModalState(state => ({
        ...state,
        pipeline: undefined
      }));
      return;
    }

    try {
      const stageData = await request(API_ENDPOINTS.GET_PIPELINE_STAGES, { id: pipeline.id });
      const updatedPipeline = {
        ...pipeline,
        stages: stageData,
        fields: JSON.parse(pipeline.schema)
      };
      setSelectedPipeline(updatedPipeline);
      setModalState(state => ({
        ...state,
        pipeline: updatedPipeline
      }));
      setError(null);
    } catch (err) {
      console.error('Failed to load pipeline stages:', err);
      setError('Failed to load pipeline stages');
      setSelectedPipeline(null);
    }
  };

  useEffect(() => {
    let isMounted = true;

    const initializePipeline = async () => {
      try {
        const urlPipelineId = getPipelineIdFromUrl();
        const newViewMode = location.pathname.endsWith('/table') ? 'table' : 'board';

        setLoading(true);
        setError(null);

        const pipelineData = await request(API_ENDPOINTS.GET_PIPELINES);
        if (!isMounted) return;
        setPipelines(pipelineData);

        let targetPipeline;

        if (urlPipelineId) {
          targetPipeline = pipelineData.find((p: Pipeline) => p.id === urlPipelineId);
          if (!targetPipeline) {
            setError('Pipeline not found');
            setSelectedPipeline(null);
            return;
          }
        }

        if (!targetPipeline) {
          targetPipeline = pipelineData.find((p: Pipeline) => !teamsLoading && hasTeamAccess.current(p.teamId));
          if (!targetPipeline) {
            setError(pipelineData.length === 0 ? 'No pipelines available' : 'No accessible pipelines found');
            return;
          }
          navigate(`/pipelines/${targetPipeline.id}`);
        }

        if (!teamsLoading && !hasTeamAccess.current(targetPipeline.teamId)) {
          setError('You do not have access to this pipeline');
          setSelectedPipeline(null);
          return;
        }

        if (initializedRef.current && selectedPipeline?.id === targetPipeline.id) {
          setViewMode(newViewMode);
          return;
        }

        const [stageData, opportunitiesData] = await Promise.all([
          request(API_ENDPOINTS.GET_PIPELINE_STAGES, { id: targetPipeline.id }),
          request(API_ENDPOINTS.GET_PIPELINE_OPPORTUNITIES, { id: targetPipeline.id })
        ]);

        if (!isMounted) return;

        const fields = targetPipeline.schema ? JSON.parse(targetPipeline.schema) : [];
        const updatedPipeline = {
          ...targetPipeline,
          stages: stageData,
          fields: fields
        };

        setSelectedPipeline(updatedPipeline);
        setModalState(state => ({
          ...state,
          pipeline: updatedPipeline
        }));
        setOpportunities(opportunitiesData || []);
        setError(null);
        setViewMode(newViewMode);
        initializedRef.current = true;
      } catch (err) {
        if (isMounted) {
          console.error('Failed to initialize pipeline:', err);
          setError('Failed to load pipeline data');
          setSelectedPipeline(null);
        }
      } finally {
        if (isMounted) {
          setLoading(false);
        }
      }
    };

    initializePipeline();
    return () => { isMounted = false; };
  }, [location.pathname, teamsLoading, teams, navigate]);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (stageSelectRef.current && !stageSelectRef.current.contains(event.target as Node)) {
        setIsStageSelectionOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  // Save stage visibility and selection state
  useEffect(() => {
    if (selectedPipeline?.id) {
      localStorage.setItem(`pipeline-${selectedPipeline.id}-visible-stages`, JSON.stringify(visibleStages));
    }
    localStorage.setItem('pipeline-stage-selection-open', JSON.stringify(isStageSelectionOpen));
  }, [visibleStages, isStageSelectionOpen, selectedPipeline?.id]);

  if (teamsLoading || loading) {
    return (
      <div className="fixed inset-0 flex items-center justify-center">
        <Spinner />
      </div>
    );
  }

  return (
    <div className='h-full flex flex-col'>
      <div className={`py-3 px-4 border-b ${THEME_CLASSES.border} ${THEME_CLASSES.background}`}>
        <div>
          <div className="flex flex-col gap-4 lg:flex-row lg:items-center lg:justify-between">
            <div className="flex items-center gap-3 min-w-0 w-full lg:w-72 shrink-0">
              <h1 className={`text-xl font-semibold my-auto ${THEME_CLASSES.text.primary} whitespace-nowrap`}>Pipelines</h1>
              <select
                className={`px-3 py-1.5 border rounded-md w-full text-sm text-left leading-6 ${THEME_CLASSES.input.background} ${THEME_CLASSES.input.border} ${THEME_CLASSES.input.text}`}
                onChange={(e) => {
                  handlePipelineChange(e);
                  navigate(`/pipelines/${e.target.value}`);
                }}
                value={selectedPipeline?.id || ''}
              >
                <option value="">Select a pipeline</option>
                {pipelines.map((pipeline: Pipeline) => (
                  <option key={pipeline.id} value={pipeline.id}>
                    {pipeline.name} {!hasTeamAccess.current(pipeline.teamId) ? '(No access)' : ''}
                  </option>
                ))}
              </select>
            </div>

            {selectedPipeline && (
              <>
                <div className="flex gap-1 items-center justify-center min-w-0">
                  <div className="w-full max-w-md lg:max-w-sm flex justify-center">
                    <div className="inline-flex gap-1 items-center bg-gray-50/50 dark:bg-gray-800/50 rounded-full p-1 w-full lg:w-auto">
                      <button
                        onClick={() => {
                          setViewMode('board');
                          navigate(`/pipelines/${selectedPipeline.id}/board`);
                        }}
                        className={`flex-1 lg:flex-none rounded-full py-1.5 px-4 text-xs font-medium leading-6 transition-colors ${viewMode === 'board'
                          ? `${THEME_CLASSES.button.primary} font-bold`
                          : `bg-transparent hover:bg-gray-200 dark:hover:bg-gray-700 text-gray-600 dark:text-gray-300`
                          }`}
                      >
                        Board
                      </button>
                      <button
                        onClick={() => {
                          setViewMode('table');
                          navigate(`/pipelines/${selectedPipeline.id}/table`);
                        }}
                        className={`flex-1 lg:flex-none rounded-full py-1.5 px-4 text-xs font-medium leading-6 transition-colors ${viewMode === 'table'
                          ? `${THEME_CLASSES.button.primary} font-bold`
                          : `bg-transparent hover:bg-gray-200 dark:hover:bg-gray-700 text-gray-600 dark:text-gray-300`
                          }`}
                      >
                        Table
                      </button>
                    </div>
                  </div>
                </div>

                {(viewMode === 'board' || viewMode === 'table') && (
                  <div className="flex items-center gap-2 w-full lg:w-auto justify-end">
                    <div className="flex items-center gap-2 flex-1 lg:flex-1">
                      {viewMode === 'board' ? (
                        <>
                          {selectedPipeline && opportunities.length > 0 && (
                            <StageSelector
                              pipeline={selectedPipeline}
                              visibleStages={visibleStages}
                              setVisibleStages={setVisibleStages}
                              isOpen={isStageSelectionOpen}
                              setIsStageSelectionOpen={setIsStageSelectionOpen}
                            />
                          )}
                        </>
                      ) : (
                        <>
                          {selectedPipeline && (
                            <ColumnSelector<Opportunity>
                              isOpen={isColumnSelectionOpen}
                              setIsOpen={setIsColumnSelectionOpen}
                              columnDefs={gridColumnDefs}
                              onColumnDefsChanged={handleColumnStateChanged}
                            />
                          )}
                        </>
                      )}

                      <div className="relative flex-1 min-w-[200px] max-w-[200px]">
                        <div className="absolute inset-y-0 left-0 pl-4 flex items-center pointer-events-none">
                          <svg className={`h-4 w-4 ${THEME_CLASSES.text.secondary}`} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                            <path fillRule="evenodd" d="M8 4a4 4 0 1 0 0 8 4 4 0 0 0 0-8zM2 8a6 6 0 1 1 10.89 3.476l4.817 4.817a1 1 0 0 1-1.414 1.414l-4.816-4.816A6 6 0 0 1 2 8z" clipRule="evenodd" />
                          </svg>
                        </div>
                        <input
                          type="text"
                          placeholder="Search..."
                          value={searchQuery}
                          onChange={(e) => {
                            const value = e.target.value;
                            setSearchQuery(value);
                            localStorage.setItem('pipelineSearchQuery', value);
                          }}
                          className={`w-full pl-11 pr-8 py-2 border rounded-md text-xs font-medium focus:outline-none focus:ring-1 focus:ring-blue-500 ${searchQuery ? 'border-red-500' : THEME_CLASSES.input.border} ${THEME_CLASSES.input.background} ${THEME_CLASSES.input.text} ${THEME_CLASSES.input.placeholder}`}
                        />
                        {searchQuery && (
                          <button
                            onClick={() => {
                              setSearchQuery('');
                              localStorage.setItem('pipelineSearchQuery', '');
                            }}
                            className="absolute inset-y-0 right-0 pr-3 flex items-center"
                          >
                            <svg className={`h-4 w-4 ${THEME_CLASSES.text.secondary} hover:${THEME_CLASSES.text.primary}`} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                              <path fillRule="evenodd" d="M4.293 4.293a1 1 0 1 1 1.414 0L10 8.586l4.293-4.293a1 1 0 1 1 1.414 1.414L11.414 10l4.293 4.293a1 1 0 0 1-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 0 1-1.414-1.414L8.586 10 4.293 5.707a1 1 0 0 1 0-1.414z" clipRule="evenodd" />
                            </svg>
                          </button>
                        )}
                      </div>
                    </div>

                    {hasTeamAccess.current(selectedPipeline.teamId) && (
                      <button
                        onClick={() => setModalState(state => ({
                          ...state,
                          isOpen: true,
                          opportunity: null,
                          pipeline: selectedPipeline
                        }))}
                        className="px-2 py-1.5 text-xs font-medium leading-6 text-white bg-green-600 rounded-md hover:bg-green-700 whitespace-nowrap shrink-0 ml-2"
                      >
                        Add Opportunity
                      </button>
                    )}
                  </div>
                )}
              </>
            )}
          </div>
          {error && (
            <div className={`mt-2 text-sm text-red-600 dark:text-red-400`}>
              {error}
            </div>
          )}
        </div>
      </div>

      <div className="p-4 flex flex-col h-full">
        {!selectedPipeline || !selectedPipeline.stages?.length ? (
          <div className="p-4">
            <p className="text-gray-500">Select a pipeline with stages to view details</p>
          </div>
        ) : error ? (
          <div className="p-4">
            <div className="p-4 rounded bg-yellow-50 dark:bg-yellow-900/20 border border-yellow-200 dark:border-yellow-800">
              <p className="text-sm text-yellow-800 dark:text-yellow-200">
                {error}
              </p>
              {error.includes('access') && (
                <p className="mt-2 text-xs text-yellow-600 dark:text-yellow-400">
                  This could be because you are not a member of the team that owns this pipeline.
                </p>
              )}
            </div>
          </div>
        ) : (
          <div className="h-full">
            {viewMode === 'board' ? (
              <OpportunityBoard
                pipeline={selectedPipeline}
                opportunities={opportunities}
                setOpportunities={setOpportunities}
                onEditOpportunity={(opportunity) => setModalState({
                  isOpen: true,
                  opportunity,
                  pipeline: selectedPipeline
                })}
                searchQuery={searchQuery}
                visibleStages={visibleStages}
              />
            ) : (
              <OpportunityGrid
                opportunities={opportunities}
                onEditOpportunity={(opportunity) => setModalState({
                  isOpen: true,
                  opportunity,
                  pipeline: selectedPipeline
                })}
                searchQuery={searchQuery}
                columnDefs={gridColumnDefs}
                onColumnStateChanged={handleColumnStateChanged}
              />
            )}
          </div>
        )}
      </div>

      <dialog
        open={modalState.isOpen}
        onClose={() => handleModalClose(false)}
        className="fixed inset-0 z-50 bg-transparent"
      >
        <div className="fixed inset-0 bg-black/50" />
        <div className={`fixed left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 p-8 rounded-lg w-[800px] max-h-[90vh] overflow-y-auto ${THEME_CLASSES.background}`}>
          <div className={`flex justify-between items-center mb-2 ${THEME_CLASSES.note.card.background.gray} -m-8 rounded-t-lg`}>
            <h3 className="text-lg font-semibold text-gray-900 dark:text-white pl-4 pt-4">
              {modalState.opportunity ? 'Edit Opportunity' : 'Create Opportunity'}
            </h3>
            <button
              onClick={() => handleModalClose(false)}
              className="p-4 text-gray-500 hover:text-gray-700 dark:text-gray-400 dark:hover:text-gray-300"
              aria-label="Close"
            >
              <svg className="w-6 h-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
              </svg>
            </button>
          </div>

          {selectedPipeline && (
            <OpportunityForm
              pipeline={selectedPipeline}
              initialData={modalState.opportunity || {
                id: 0,
                stageId: selectedPipeline.stages?.[0]?.id || 0,
                data: ''
              }}
              onSubmit={() => handleModalClose(true)}
              onCancel={() => handleModalClose(false)}
            />
          )}
        </div>
      </dialog>
    </div>
  );
};

export default Pipelines;
