/**
 * Utility functions for Google Calendar and Gmail integration
 */

/**
 * Creates a Google Calendar event URL with the provided title and details
 *
 * @param title - Event title (will be encoded)
 * @param description - Event description/details (will be encoded)
 * @param startDate - Optional start date for the event
 * @param endDate - Optional end date for the event
 * @param location - Optional location for the event
 * @returns URL string to create a new Google Calendar event
 */
export const buildGoogleCalendarUrl = (
  title: string,
  description: string,
  startDate?: Date,
  endDate?: Date,
  location?: string
): string => {
  // Base Google Calendar URL
  let calendarUrl = 'https://calendar.google.com/calendar/u/0/r/eventedit';

  // Add query parameters
  const params = new URLSearchParams();

  // Required parameters
  params.append('text', title);
  params.append('details', description);

  // Optional parameters
  if (location) {
    params.append('location', location);
  }

  // Add date parameters if provided
  if (startDate) {
    // Format: YYYYMMDDTHHMM00Z
    const formattedStart = startDate.toISOString()
      .replace(/-|:|\.\d+/g, '')
      .replace(/[A-Z]/, 'T');
    params.append('dates', formattedStart);

    // Add end date if provided, otherwise default to 1 hour after start
    if (endDate) {
      const formattedEnd = endDate.toISOString()
        .replace(/-|:|\.\d+/g, '')
        .replace(/[A-Z]/, 'T');
      params.set('dates', `${formattedStart}/${formattedEnd}`);
    } else {
      // Default to 1 hour meeting
      const defaultEnd = new Date(startDate.getTime() + 60 * 60 * 1000);
      const formattedEnd = defaultEnd.toISOString()
        .replace(/-|:|\.\d+/g, '')
        .replace(/[A-Z]/, 'T');
      params.set('dates', `${formattedStart}/${formattedEnd}`);
    }
  }

  // Append parameters to URL
  return `${calendarUrl}?${params.toString()}`;
};

/**
 * Creates a Gmail composition URL with the provided subject and body
 *
 * @param subject - Email subject (will be encoded)
 * @param body - Email body (will be encoded)
 * @param to - Optional recipient email addresses (comma-separated)
 * @param cc - Optional CC recipient email addresses (comma-separated)
 * @param bcc - Optional BCC recipient email addresses (comma-separated)
 * @returns URL string to compose a new Gmail message
 */
export const buildGmailUrl = (
  subject: string,
  body: string,
  to?: string,
  cc?: string,
  bcc?: string
): string => {
  // Base Gmail URL
  const gmailUrl = 'https://mail.google.com/mail/u/0/';

  // Add query parameters
  const params = new URLSearchParams();

  // Required parameters
  params.append('view', 'cm');
  params.append('fs', '1');
  params.append('su', subject);
  params.append('body', body);

  // Optional parameters
  if (to) {
    params.append('to', to);
  }

  if (cc) {
    params.append('cc', cc);
  }

  if (bcc) {
    params.append('bcc', bcc);
  }

  // Append parameters to URL
  return `${gmailUrl}?${params.toString()}`;
};

/**
 * Import Note model for note-specific utilities
 */
import { Note } from '../models/Note';

/**
 * Formats note content into a structured string with consistent formatting
 *
 * @param note - The Note object to format
 * @param includeSignature - Whether to include a signature line (default: false)
 * @returns Formatted note content as a string
 */
const formatNoteContent = (note: Note, includeSignature: boolean = false): string => {
  // Create a CRM link to the note
  let crmLink = '';
  if (note.contactId) {
    crmLink = `https://crm.rlpnetwork.com/accounts/${note.contactId}/notes/${note.id}`;
  } else if (note.accountId) {
    crmLink = `https://crm.rlpnetwork.com/accounts/${note.accountId}/notes/${note.id}`;
  }

  // Format the description with note details
  const contentParts = [
    note.content,
    '',
    '----------------------------------------\nNote:',
    '',
    // Add related entity information if available
    note.accountName ? `Account: ${note.accountName}` : '',
    note.contactName ? `Contact: ${note.contactName}` : '',
    '',
    `Created by: ${note.author || 'Unknown'} on ${note.createdAt.toLocaleString()}`,
    '',
    // Add hyperlink to the CRM
    crmLink ? `View in CRM: ${crmLink}` : '',
  ];

  // Add signature for emails if requested
  if (includeSignature) {
    contentParts.push('');
    contentParts.push('Sent from RLP CRM');
  }

  return contentParts.filter(part => part !== '').join('\n'); // Remove empty lines
};

/**
 * Creates a Google Calendar event URL from a Note object
 *
 * @param note - The Note object to create a calendar event from
 * @param startDate - Start date for the event
 * @param endDate - Optional end date for the event (defaults to 1 hour after start)
 * @param location - Optional location for the event
 * @returns URL string to create a new Google Calendar event based on the note
 */
export const noteToCalendarUrl = (
  note: Note,
  startDate: Date,
  endDate?: Date,
  location?: string
): string => {
  // Use the helper function to format the note content
  const description = formatNoteContent(note);

  // Build the calendar URL with the note information
  return buildGoogleCalendarUrl(
    note.title,
    description,
    startDate,
    endDate,
    location
  );
};

/**
 * Creates a Gmail composition URL from a Note object
 *
 * @param note - The Note object to create an email from
 * @param to - Recipient email address(es) (comma-separated)
 * @param cc - Optional CC recipient email address(es) (comma-separated)
 * @param bcc - Optional BCC recipient email address(es) (comma-separated)
 * @returns URL string to compose a new Gmail message based on the note
 */
export const noteToGmailUrl = (
  note: Note,
  to: string,
  cc?: string,
  bcc?: string
): string => {
  // Create a subject line that includes note title and related entity info
  const subjectParts = [note.title];

  if (note.objectType === 'account' && note.accountName) {
    subjectParts.push(`[Account: ${note.accountName}]`);
  } else if (note.objectType === 'contact' && note.contactName) {
    subjectParts.push(`[Contact: ${note.contactName}]`);
  } else if (note.objectType === 'opportunity') {
    subjectParts.push(`[Opportunity ID: ${note.opportunityId}]`);
  } else if (note.objectType === 'lead') {
    subjectParts.push('[Lead]');
  }

  const subject = subjectParts.join(' ');

  // Use the helper function to format the note content, including signature for emails
  const body = formatNoteContent(note, true);

  // Build the Gmail URL with the note information
  return buildGmailUrl(
    subject,
    body,
    to,
    cc,
    bcc
  );
};

/**
 * Opens a Google Calendar event creation page in a new tab based on a note
 *
 * @param note - The Note object to create a calendar event from
 * @param startDate - Start date for the event
 * @param endDate - Optional end date for the event
 * @param location - Optional location for the event
 */
export const openNoteInCalendar = (
  note: Note,
  startDate: Date,
  endDate?: Date,
  location?: string
): void => {
  const url = noteToCalendarUrl(note, startDate, endDate, location);
  window.open(url, '_blank');
};

/**
 * Opens a Gmail composition page in a new tab based on a note
 *
 * @param note - The Note object to create an email from
 * @param to - Recipient email address(es)
 * @param cc - Optional CC recipient email address(es)
 * @param bcc - Optional BCC recipient email address(es)
 */
export const openNoteInGmail = (
  note: Note,
  to: string,
  cc?: string,
  bcc?: string
): void => {
  const url = noteToGmailUrl(note, to, cc, bcc);
  window.open(url, '_blank');
};