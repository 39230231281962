import { z } from "zod";
import { DataRoutes } from "./types";

const schemaRoutes: DataRoutes = {
  // Get list of all tables in the database
  'get /api/data/schema': {
    query: `SELECT 
      id,
      name,
      teamId,
      entityType,
      rlp,
      [Schema] as [schema]
    FROM DataSchema

    UNION ALL

    SELECT
      id,
      name,
      teamId,
      3 as entityType,
      0 as rlp,
      [Schema] as [schema]
    FROM Pipelines
    `
  },

  // Get foreign key relationships for a specific table
  'patch /api/data/schema': {
    schema: z.object({
      name: z.string(),
      schema: z.string()
    }),
    query: `UPDATE DataSchema
    SET [Schema] = @schema,
        updatedBy = dbo.fnGetContextUser(),
        updatedAt = GETDATE()
    WHERE Name = @name

    UPDATE Pipelines
    SET [Schema] = @schema,
        updatedBy = dbo.fnGetContextUser(),
        updatedAt = GETDATE()    
    WHERE Name = @name;
    `
  }
};

export default schemaRoutes;