import { FC, useState } from 'react';
import { Opportunity } from '../../models/Opportunity';
import { Pipeline } from '../../models/Pipeline';
import { request } from '../../services/dataService';
import { API_ENDPOINTS } from '../../constants/apiEndpoints';
import DynamicForm from '../common/DynamicForm';
import { FormField } from '../common/FormField';
import EntityTypeahead from '../common/EntityTypeahead';
import { TypeaheadResult } from '../common/Typeahead';
import { THEME_CLASSES } from '../../constants/themeConstants';

interface OpportunityFormProps {
  pipeline: Pipeline;
  initialData?: Partial<Opportunity>;
  onSubmit: () => void;
  onCancel: (wasUpdated: boolean) => void;
}

const OpportunityForm: FC<OpportunityFormProps> = ({
  pipeline,
  initialData,
  onSubmit,
  onCancel
}) => {
  const fields: FormField[] = pipeline?.schema ? JSON.parse(pipeline.schema) : [];
  const [selectedAccount, setSelectedAccount] = useState<TypeaheadResult | null>(
    initialData?.accountId ? { id: initialData.accountId, name: initialData.accountName || '', type: 'Account' } : null
  );
  const [wasUpdated, setWasUpdated] = useState(false);

  const handleSubmit = async (formData: Record<string, any>) => {
    const formattedData = {
      ...formData,
      accountId: selectedAccount?.id,
    };

    try {
      if (initialData?.id) {
        await request(API_ENDPOINTS.PATCH_OPPORTUNITY_DATA, {
          id: initialData.id,
          data: JSON.stringify(formattedData)
        });
      } else {
        await request(API_ENDPOINTS.POST_OPPORTUNITY, {
          stageId: initialData?.stageId ?? 0,
          data: JSON.stringify(formattedData)
        });
      }
      setWasUpdated(true);
      onSubmit();
    } catch (error) {
      console.error('Failed to save opportunity:', error);
      setWasUpdated(false);
    }
  };

  const handleCancel = () => {
    onCancel(wasUpdated);
  };

  return (
    <div className="flex flex-col gap-4">
      {!initialData?.id && (
        <div className={`p-4 ${THEME_CLASSES.border} border-b`}>
          <div className="flex items-center gap-4">
            <label className="font-medium text-gray-700 dark:text-gray-200 whitespace-nowrap">
              Account or Contact
            </label>
            <div className="flex flex-1 space-x-4">
              <EntityTypeahead
                onSelect={setSelectedAccount}
                placeholder="Search accounts and contacts..."
                className="w-72"
              />
              {selectedAccount && (
                <div className="flex items-center space-x-2">
                  <span>{selectedAccount.name}</span>
                  <button
                    onClick={() => setSelectedAccount(null)}
                    className="text-gray-500 hover:text-gray-700"
                  >
                    ×
                  </button>
                </div>
              )}
            </div>
          </div>
        </div>
      )}

      <DynamicForm
        fields={fields}
        initialData={initialData}
        onSubmit={handleSubmit}
        onCancel={handleCancel}
        submitLabel={initialData?.id ? 'Update' : 'Create'}
        cancelLabel="Cancel"
      />
    </div>
  );
};

export default OpportunityForm;
