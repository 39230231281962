import React from 'react';
import { THEME_CLASSES } from '../constants/themeConstants';

interface TutorialVideo {
  title: string;
  description: string;
  videoUrl: string;
}

// Import tutorial videos data
import tutorialVideosData from '../data/tutorialVideos.json';

const Help: React.FC = () => {
  const tutorialVideos: TutorialVideo[] = tutorialVideosData.tutorialVideos;

  const VideoCard: React.FC<TutorialVideo> = ({ title, description, videoUrl }) => {
    // Extract video ID from YouTube URL
    const videoId = videoUrl.split('/').pop();    
    return (
      <div className={`rounded-lg overflow-hidden shadow-md ${THEME_CLASSES.card} transition-transform hover:scale-105`}>
        <div className="relative w-full h-48">
          <iframe
            src={`https://www.youtube.com/embed/${videoId}?modestbranding=1&showinfo=0`}
            title={title}
            className="w-full h-full"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
          />
        </div>
        <div className="p-4">
          <h3 className={`font-bold text-lg mb-2 ${THEME_CLASSES.text.primary}`}>{title}</h3>
          <p className={`text-sm ${THEME_CLASSES.text.secondary}`}>{description}</p>
        </div>
      </div>
    );
  };

  return (
    <div className="h-screen flex flex-col overflow-hidden">
      {/* Fixed Header */}
      <div className="px-4 py-4">
        <div className="flex justify-between items-center mb-4">
          <h1 className={`text-2xl font-bold ${THEME_CLASSES.text.primary}`}>Help & Tutorials</h1>
        </div>
        <p className={`mb-4 ${THEME_CLASSES.text.secondary}`}>
          Welcome to the rlpCRM help center. Watch these short tutorial videos to learn how to use the system effectively.
        </p>
      </div>

      {/* Scrollable container for cards */}
      <div className="flex-1 overflow-y-auto pb-8">
        <div className="px-4">
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
            {tutorialVideos.map((video, index) => (
              <VideoCard key={index} {...video} />
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Help;
