import React from 'react';
import { useState, useEffect, useRef, KeyboardEvent, useCallback } from 'react';
import useDebounce from '../../hooks/useDebounce';
import { THEME_CLASSES } from '../../constants/themeConstants';
import { request } from '../../services/dataService';
import { highlightText } from '../../utils/highlightUtils';

export interface TypeaheadResult {
  id: number;
  name: string;
  type: string;
  personId?: number;
  teamId?: number;
}

export interface SectionFilter {
  type: string;
  hasId?: boolean;
}

export interface Section {
  title: string;
  filter: SectionFilter;
  itemClassName?: string;
  avatarColor?: string;
  renderItem?: (item: TypeaheadResult, index: number, isActive: boolean, searchValue: string) => React.ReactNode;
}

interface TypeaheadProps {
  onSelect: (result: TypeaheadResult) => void;
  sections: Section[];
  searchEndpoint?: string;
  searchParams?: Record<string, any>;
  results?: TypeaheadResult[];
  error?: string | null;
  placeholder?: string;
  className?: string;
  autoFocus?: boolean;
}

const Typeahead: React.FC<TypeaheadProps> = (props) => {
  const {
    onSelect,
    sections,
    searchEndpoint,
    searchParams,
    results: externalResults,
    error = null,
    placeholder = 'Search...',
    className = '',
    autoFocus = false
  } = props;
  const [showDropdown, setShowDropdown] = useState(false);
  const [activeIndex, setActiveIndex] = useState(-1);
  const [isLoading, setIsLoading] = useState(false);
  const [internalResults, setInternalResults] = useState<TypeaheadResult[]>([]);
  const searchContainerRef = useRef<HTMLDivElement>(null);
  const inputRef = useRef<HTMLInputElement>(null);
  const [searchValue, setSearchValue] = useState('');

  const debouncedSearchValue = useDebounce(searchValue, 300);
  const abortControllerRef = useRef<AbortController | null>(null);
  const previousSearchRef = useRef<string>('');

  // Memoized fetch function
  const fetchResults = useCallback(async (
    value: string,
    endpoint: string,
    controller: AbortController,
    params: Record<string, any> | undefined
  ) => {
    try {
      const results = await request(endpoint, { ...(params || {}), q: value });
      return !controller.signal.aborted ? results : null;
    } catch (err) {
      if (!controller.signal.aborted) {
        console.error('Search error:', err);
      }
      return null;
    }
  }, []);

  // Memoized search function
  const search = useCallback(async (searchValue: string) => {
    if (searchValue.length < 2 || !searchEndpoint) {
      return {
        results: [],
        loading: false,
        previousSearch: '',
        showDropdown: false
      };
    }

    if (searchValue === previousSearchRef.current) {
      return null;
    }

    const controller = abortControllerRef.current;
    if (controller) {
      controller.abort();
    }

    const newController = new AbortController();
    abortControllerRef.current = newController;

    const results = await fetchResults(
      searchValue,
      searchEndpoint,
      newController,
      searchParams
    );

    if (!newController.signal.aborted) {
      return {
        results: results || [],
        loading: false,
        previousSearch: searchValue,
        showDropdown: !!results && results.length > 0
      };
    }

    return null;
  }, [searchEndpoint, searchParams, fetchResults]);

  // Handle API calls when debounced value changes
  useEffect(() => {
    const updateState = async () => {
      const result = await search(debouncedSearchValue);
      if (result) {
        setInternalResults(result.results);
        setIsLoading(result.loading);
        previousSearchRef.current = result.previousSearch;
        setShowDropdown(result.showDropdown);
      }
    };

    setIsLoading(true);
    updateState();

    return () => {
      if (abortControllerRef.current) {
        abortControllerRef.current.abort();
      }
    };
  }, [debouncedSearchValue, search]);

  // Handle input change
  const handleChange = (value: string) => {
    setSearchValue(value);
    setActiveIndex(-1);
  };

  // Handle result selection
  const handleSelect = (result: TypeaheadResult) => {
    onSelect(result);
    setSearchValue('');
    setActiveIndex(-1);
    setShowDropdown(false);
    inputRef.current?.blur();
  };

  // Keyboard navigation
  const handleKeyDown = (e: KeyboardEvent) => {
    const results = externalResults || internalResults;
    if (e.key === 'ArrowDown') {
      e.preventDefault();
      setActiveIndex(prev => Math.min(prev + 1, results.length - 1));
    } else if (e.key === 'ArrowUp') {
      e.preventDefault();
      setActiveIndex(prev => Math.max(prev - 1, -1));
    } else if (e.key === 'Enter' && activeIndex >= 0) {
      e.preventDefault();
      handleSelect(results[activeIndex]);
    }
  };

  // Close dropdown when clicking outside
  useEffect(() => {
    const handleClickOutside = (e: MouseEvent) => {
      if (searchContainerRef.current &&
        !searchContainerRef.current.contains(e.target as Node)) {
        setShowDropdown(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, []);


  const getAvatarColor = (item: TypeaheadResult, section: Section) => {
    if (item.type === 'Account') {
      return !item.teamId || item.teamId === 0 ? 'bg-red-500' : 'bg-blue-500';
    }
    if (item.type === 'Contact') {
      return item.personId && item.personId > 0 ? 'bg-red-500' : 'bg-blue-500';
    }
    return section.avatarColor || 'bg-gray-500';
  };

  const renderDefaultItem = (item: TypeaheadResult, _index: number, isActive: boolean, section: Section) => {
    const nameParts = item.name.split(' ');
    const initials = `${nameParts[0]?.[0] || ''}${nameParts[1]?.[0] || ''}`;
    const avatarColor = getAvatarColor(item, section);

    return (
      <li
        key={`${item.type}-${item.id}-${item.personId}`}
        onClick={() => handleSelect(item)}
        className={`px-4 py-4 cursor-pointer ${isActive ? THEME_CLASSES.hover.background : ''} ${THEME_CLASSES.hover.background} hover:bg-opacity-90 transition-all duration-150 flex items-center ${section.itemClassName || ''}`}
      >
        <div className={`w-8 h-8 rounded-full ${avatarColor} flex items-center justify-center flex-shrink-0`}>
          <span className="text-sm font-medium text-white">{initials}</span>
        </div>
        <div className="ml-3 flex-grow">
          <div className={`text-base font-semibold ${THEME_CLASSES.input.text}`}>
            {highlightText(item.name, searchValue.trim().split(/\s+/).filter(term => term.length > 0))}
          </div>
        </div>
      </li>
    );
  };

  return (
    <div ref={searchContainerRef} className={`relative ${className}`}>
      <div className="relative">
        <input
          ref={inputRef}
          type="text"
          value={searchValue}
          onChange={(e) => handleChange(e.target.value)}
          onKeyDown={handleKeyDown}
          placeholder={placeholder}
          autoFocus={autoFocus}
          className={`w-full pl-10 pr-10 py-2 rounded-md text-sm focus:outline-none focus:ring-1 ${THEME_CLASSES.input.border} ${THEME_CLASSES.input.background} ${THEME_CLASSES.input.text} ${THEME_CLASSES.input.placeholder}`}
        />
        <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
          {isLoading ? (
            <div className="animate-spin h-5 w-5 text-gray-400 border-2 border-gray-400 border-t-transparent rounded-full" />
          ) : (
            <svg className="h-5 w-5 text-gray-400" fill="none" stroke="currentColor" viewBox="0 0 24 24">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z" />
            </svg>
          )}
        </div>
      </div>

      {showDropdown && (
        <div className={`absolute z-50 w-full mt-2 rounded-md shadow-2xl ring-1 ring-black ring-opacity-5 ${THEME_CLASSES.input.background} ${THEME_CLASSES.border}`}>
          {sections.map((section) => {
            const sectionItems = (externalResults || internalResults).filter(item =>
              item.type === section.filter.type &&
              (section.filter.hasId === undefined || !!item.id === section.filter.hasId)
            );

            if (sectionItems.length === 0) return null;

            return (
              <React.Fragment key={section.title}>
                <div className={`px-4 py-2 text-xs font-semibold ${THEME_CLASSES.input.text} bg-gray-50 dark:bg-gray-800`}>
                  {section.title}
                </div>
                <ul className="py-2">
                  {sectionItems.map((item, index) => (
                    <div key={`${item.type}-${item.id}-${item.personId}`} onClick={() => handleSelect(item)}>
                      {section.renderItem?.(item, index, index === activeIndex, searchValue) ||
                        renderDefaultItem(item, index, index === activeIndex, section)}
                    </div>
                  ))}
                </ul>
              </React.Fragment>
            );
          })}
        </div>
      )}

      {error && (
        <div className={`text-sm mt-1 ${THEME_CLASSES.text.secondary}`}>{error}</div>
      )}
    </div>
  );
};

export default Typeahead;
