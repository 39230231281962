import { useState, useEffect, useMemo } from 'react';
import type { Note } from '../../models/Note';
import { THEME_CLASSES } from '../../constants/themeConstants';
import { getAvatarConfig } from '../../utils/avatarUtils';
import { highlightText } from '../../utils/highlightUtils';
import { openNoteInCalendar, openNoteInGmail } from '../../utils/googleUtils';

interface DashboardNotesProps {
  notes: Note[];
  isLoading?: boolean;
  selectedTeamFilter?: number | null;
  selectedTeamName?: string | null;
  onClearTeamFilter?: () => void;
}

export default function DashboardNotes({ notes, isLoading, selectedTeamFilter, selectedTeamName, onClearTeamFilter }: DashboardNotesProps) {
  const [currentMonth, setCurrentMonth] = useState(() => {
    const date = new Date();
    return new Date(date.getFullYear(), date.getMonth(), 1);
  });
  const [selectedDate, setSelectedDate] = useState<Date | null>(null);
  const [searchQuery, setSearchQuery] = useState(() => {
    return localStorage.getItem('notes-search') || '';
  });

  useEffect(() => {
    localStorage.setItem('notes-search', searchQuery);
  }, [searchQuery]);

  const keywords = useMemo(() =>
    searchQuery
      .toLowerCase()
      .split(' ')
      .map(k => k.trim())
      .filter(k => k.length > 0),
    [searchQuery]
  );

  // Filter by date and search, then sort by updatedAt desc
  const filteredNotes = useMemo(() => {
    return notes
      .sort((a, b) => new Date(b.updatedAt).getTime() - new Date(a.updatedAt).getTime())
      .filter(item => {
      const dateMatch = !selectedDate ||
        new Date(item.updatedAt).toDateString() === selectedDate.toDateString();

      const searchMatch = keywords.length === 0 ||
        keywords.every(k => {
          const searchableFields = [
            item.content || '',
            item.title || '',
            item.author || '',
            item.accountName || '',
            item.contactName || '',
            item.type || ''
          ].map(field => field.toLowerCase());
          return searchableFields.some(field => field.includes(k));
        });

      return dateMatch && searchMatch;
    });
  }, [notes, selectedDate, keywords]);

  const getCalendarDates = () => {
    const startDate = new Date(currentMonth);
    startDate.setDate(1);
    const startDay = startDate.getDay();
    startDate.setDate(startDate.getDate() - startDay);

    // Get the last day of the month
    const endDate = new Date(currentMonth);
    endDate.setMonth(endDate.getMonth() + 1);
    endDate.setDate(0);
    
    // Calculate if we need 6 weeks
    const lastDayPosition = startDay + endDate.getDate() - 1;
    const needsSixWeeks = lastDayPosition > 34; // If last day is beyond 5th week
    const daysToShow = needsSixWeeks ? 42 : 35;

    const dates = [];
    for (let i = 0; i < daysToShow; i++) {
      const date = new Date(startDate);
      date.setDate(date.getDate() + i);
      dates.push(date);
    }
    return dates;
  };

  const dates = getCalendarDates();

  return (
    <div className="h-full flex flex-col lg:flex-row gap-4">
      {/* Calendar */}
      <div className="hidden lg:block lg:w-1/4 flex-shrink-0 flex flex-col">
        {/* Calendar Header */}
        <div className="flex items-center justify-between mb-4">
          <button
            onClick={() => {
              const newDate = new Date(currentMonth.getFullYear(), currentMonth.getMonth() - 1, 1);
              setCurrentMonth(newDate);
            }}
            className="p-1 hover:bg-gray-100 dark:hover:bg-gray-800 rounded text-gray-600 dark:text-gray-300"
          >
            <svg className="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M15 19l-7-7 7-7" />
            </svg>
          </button>
          <span className="text-sm font-medium text-gray-900 dark:text-gray-100">
            {currentMonth.toLocaleString('default', { month: 'long', year: 'numeric' })}
          </span>
          <button
            onClick={() => {
              const newDate = new Date(currentMonth.getFullYear(), currentMonth.getMonth() + 1, 1);
              setCurrentMonth(newDate);
            }}
            className="p-1 hover:bg-gray-100 dark:hover:bg-gray-800 rounded text-gray-600 dark:text-gray-300"
          >
            <svg className="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 5l7 7-7 7" />
            </svg>
          </button>
        </div>

        {/* Calendar Grid */}
        <div className="bg-white dark:bg-gray-800 rounded-xl shadow-lg hover:shadow-2xl p-4 border dark:border-gray-700 overflow-y-auto flex-1">
          <div className="grid grid-cols-7 gap-0.5 mb-4">
            {['Su', 'Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa'].map(day => (
              <div key={day} className={`text-center text-base font-bold py-2 ${THEME_CLASSES.text.primary}`}>
                {day}
              </div>
            ))}
          </div>
          <div className="grid grid-cols-7 gap-2">
            {dates.map((date, index) => {
              const dayNotes = notes.filter(item =>
                new Date(item.updatedAt).toDateString() === date.toDateString()
              );

              const isCurrentMonth = date.getMonth() === currentMonth.getMonth();
              const isToday = date.toDateString() === new Date().toDateString();

              return (
                <div key={date.toISOString()} className="h-12">
                  <button
                    onClick={() => {
                      if (selectedDate?.toDateString() === date.toDateString()) {
                        setSelectedDate(null);
                      } else {
                        setSelectedDate(date);
                      }
                    }}
                    className={`w-10 h-10 flex items-center justify-center text-sm rounded-full relative
                      ${isToday ? 'font-bold' : ''}
                      ${isCurrentMonth
                        ? 'text-gray-900 dark:text-gray-100 hover:bg-gray-100 dark:hover:bg-gray-800'
                        : 'opacity-50'
                      }
                      ${selectedDate?.toDateString() === date.toDateString()
                        ? 'ring-2 ring-blue-500 rounded-full'
                        : ''
                      }
                    `}
                  >
                    {date.getDate()}
                    {dayNotes.length > 0 && (
                      <span className="absolute bottom-0.5 left-1/2 transform -translate-x-1/2 w-1.5 h-1.5 rounded-full bg-blue-500" />
                    )}
                  </button>
                </div>
              );
            })}
          </div>
        </div>
      </div>

      {/* Notes */}
      <div className="flex-1 flex flex-col overflow-hidden">
        {/* Search Header */}
        <div className="flex items-center justify-between mb-4 px-4 flex-wrap">
          {/* Left side with title */}
          <div className="flex items-center">
            <h2 className={`${THEME_CLASSES.text.primary} text-xl font-semibold my-auto flex-shrink-0`}>
              Recent Notes
            </h2>
          </div>

          {/* Right side with team filter, count, and search box */}
          <div className="flex items-center gap-3">
            {/* Team filter tag */}
            {selectedTeamFilter !== null && selectedTeamName && (
              <div className="flex items-center flex-shrink-0">
                <span className="px-2 py-1 bg-blue-50 dark:bg-blue-900/20 border border-blue-200 dark:border-blue-800 rounded-md">
                  <span className="text-xs text-blue-700 dark:text-blue-300">{selectedTeamName}</span>
                </span>
                {onClearTeamFilter && (
                  <button
                    onClick={onClearTeamFilter}
                    className="ml-1 text-blue-400 hover:text-blue-600 dark:hover:text-blue-200 p-1"
                    title="Clear team filter"
                  >
                    <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4" viewBox="0 0 20 20" fill="currentColor">
                      <path fillRule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clipRule="evenodd" />
                    </svg>
                  </button>
                )}
              </div>
            )}

            {/* Note count badge */}
            <div className="flex-shrink-0">
              <span className="px-2 py-1 bg-gray-100 dark:bg-gray-700 text-gray-600 dark:text-gray-300 rounded-full text-xs font-medium">
                {filteredNotes.length} {filteredNotes.length === 1 ? 'note' : 'notes'}
              </span>
            </div>

            {/* Search box */}
            <div className="w-64 relative">
              <div className="absolute inset-y-0 left-0 pl-4 flex items-center pointer-events-none">
                <svg className={`h-4 w-4 ${THEME_CLASSES.text.secondary}`} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                  <path fillRule="evenodd" d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z" clipRule="evenodd" />
                </svg>
              </div>
              <input
                type="text"
                placeholder="Search notes..."
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
                className={`w-full pl-11 pr-8 py-2 border rounded-md text-xs font-medium focus:outline-none focus:ring-1 focus:ring-blue-500 ${searchQuery ? 'border-red-500' : THEME_CLASSES.input.border} ${THEME_CLASSES.input.background} ${THEME_CLASSES.input.text} ${THEME_CLASSES.input.placeholder}`}
              />
              {searchQuery && (
                <button
                  onClick={() => setSearchQuery('')}
                  className="absolute inset-y-0 right-0 pr-3 flex items-center"
                >
                  <svg className={`h-4 w-4 ${THEME_CLASSES.text.secondary} hover:${THEME_CLASSES.text.primary}`} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                    <path fillRule="evenodd" d="M4.293 4.293a1 1 0 1 1 1.414 0L10 8.586l4.293-4.293a1 1 0 1 1 1.414 1.414l-4.293 4.293 4.293 4.293a1 1 0 0 1-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 0 1-1.414-1.414L8.586 10 4.293 5.707a1 1 0 0 1 0-1.414z" clipRule="evenodd" />
                  </svg>
                </button>
              )}
            </div>
          </div>
        </div>

        {/* Scrollable Notes Content */}
        <div className="flex-1 overflow-y-auto p-4">
          <div className="grid grid-cols-1 lg:grid-cols-2 xl:grid-cols-3 gap-4">
            {isLoading ? (
              // Simplified skeleton cards
              [...Array(6)].map((_, index) => (
                <div key={`skeleton-${index}`} className="bg-white dark:bg-gray-800 rounded-xl shadow-lg p-6 border dark:border-gray-700 flex flex-col h-full animate-pulse">
                  {/* Avatar and author area */}
                  <div className="flex items-center gap-2 mb-4">
                    <div className="w-8 h-8 rounded-full bg-gray-200 dark:bg-gray-700"></div>
                    <div className="w-24 h-4 bg-gray-200 dark:bg-gray-700 rounded"></div>
                  </div>

                  {/* Title skeleton */}
                  <div className="w-3/4 h-5 bg-gray-200 dark:bg-gray-700 rounded mb-4"></div>

                  {/* Content preview skeleton - multiple lines */}
                  <div className="space-y-2">
                    <div className="w-full h-4 bg-gray-200 dark:bg-gray-700 rounded"></div>
                    <div className="w-full h-4 bg-gray-200 dark:bg-gray-700 rounded"></div>
                  </div>
                </div>
              ))
            ) : (
              filteredNotes.map((item) => {
                const author = item.author || 'Unknown User';
                const [firstName, lastName] = author.split(' ');
                const contact = { firstName, lastName, employeeStatus: 'Active' };
                const avatarConfig = getAvatarConfig(contact as any);

                // Get the note type badge color
                const getTypeBadgeClasses = (type: string) => {
                  switch (type) {
                    case 'meeting': return 'bg-purple-100 dark:bg-purple-900/30 text-purple-700 dark:text-purple-300';
                    case 'phone call': return 'bg-blue-100 dark:bg-blue-900/30 text-blue-700 dark:text-blue-300';
                    case 'email': return 'bg-green-100 dark:bg-green-900/30 text-green-700 dark:text-green-300';
                    case 'in person': return 'bg-red-100 dark:bg-red-900/30 text-red-700 dark:text-red-300';
                    case 'voice mail': return 'bg-yellow-100 dark:bg-yellow-900/30 text-yellow-700 dark:text-yellow-300';
                    default: return 'bg-gray-100 dark:bg-gray-700 text-gray-600 dark:text-gray-300';
                  }
                };

                return (
                  <div
                    key={`${item.id}-${item.accountId || ''}-${item.contactId || ''}-${item.type || ''}`}
                    className={`${THEME_CLASSES.card.background} rounded-xl shadow-lg p-6 ${THEME_CLASSES.card.border} flex flex-col h-full cursor-pointer ${THEME_CLASSES.card.hover.full}`}
                    onClick={() => {
                      if (item.accountId) {
                        window.location.href = `/accounts/${item.accountId}/notes/${item.id}`;
                      } else if (item.contactId) {
                        window.location.href = `/contacts/${item.contactId}/notes/${item.id}`;
                      }
                    }}
                  >
                    <div className="flex items-center justify-between mb-2">
                      <div className="flex items-center gap-2">
                        <div className={`w-8 h-8 rounded-full flex items-center justify-center ${avatarConfig.bgColor} ${avatarConfig.textColor}`}>
                          <span className="text-xs font-medium">
                            {avatarConfig.initials}
                          </span>
                        </div>
                        <div className={`${THEME_CLASSES.text.primary} text-sm font-medium`}>
                          {highlightText(author, keywords)}
                        </div>
                      </div>
                      <div className={`${THEME_CLASSES.text.secondary} text-xs`}>
                        {new Date(item.updatedAt).toLocaleDateString()}
                      </div>
                    </div>

                    <div className="flex items-center justify-between mb-2">
                      {/* Entity (Account/Contact) info - Left aligned */}
                      {(item.accountId || item.contactId) && (
                        <div className={`text-xs ${THEME_CLASSES.text.secondary}`}>
                          {item.accountId ? (
                            <>Account: {highlightText(item.accountName || `#${item.accountId}`, keywords)}</>
                          ) : item.contactId ? (
                            <>Contact: {highlightText(item.contactName || `#${item.contactId}`, keywords)}</>
                          ) : null}
                        </div>
                      )}
                    </div>

                    {/* Title */}
                    {(item.title || item.content) && (
                      <h3 className={`${THEME_CLASSES.text.primary} font-medium mb-1 whitespace-nowrap overflow-hidden text-ellipsis`}>
                        {highlightText(item.title || item.content?.split('\n')[0] || '', keywords)}
                      </h3>
                    )}

                    {/* Content */}
                    <p className={`${THEME_CLASSES.text.secondary} text-sm mt-1 line-clamp-3 flex-1`}>
                      {highlightText(
                        item.content
                          ? item.content
                            .split('\n')
                            .slice(1)
                            .map(line => line.trim())
                            .join('\n')
                            .trim()
                          : '',
                        keywords
                      )}
                    </p>

                    {/* Type badge and action links */}
                    <div className="flex items-center justify-between mt-3">
                      <div className="flex items-center gap-2">
                        {/* Type badge */}
                        {item.type && item.type !== 'general' && (
                          <span className={`px-2 py-0.5 text-xs rounded-full ${getTypeBadgeClasses(item.type)}`}>
                            {highlightText(item.type, keywords)}
                          </span>
                        )}

                        {/* Team ID badge */}
                        {/* {item.teamIds && (
                          <span className="px-2 py-0.5 text-xs rounded-full font-medium bg-teal-100 dark:bg-teal-900/30 text-teal-700 dark:text-teal-300">
                            {highlightText(`team: ${item.teamIds}`, keywords)}
                          </span>
                        )} */}
                      </div>

                      <div className="flex gap-2 ml-auto">
                        <button
                          onClick={() => {
                            // Default start time to now rounded to nearest hour
                            const startDate = new Date();
                            startDate.setMinutes(0, 0, 0);
                            startDate.setHours(startDate.getHours() + 1);
                            openNoteInCalendar(item, startDate);
                          }}
                          className={`p-1 rounded-md ${THEME_CLASSES.text.secondary} ${THEME_CLASSES.hover.background} text-xs`}
                          title="Add to Google Calendar"
                        >
                          <svg className="w-4 h-4" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M8 7V3m8 4V3m-9 8h10M5 21h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v12a2 2 0 002 2z" />
                          </svg>
                        </button>
                        <button
                          onClick={() => {
                            openNoteInGmail(item, ''); // Open with empty recipient
                          }}
                          className={`p-1 rounded-md ${THEME_CLASSES.text.secondary} ${THEME_CLASSES.hover.background} text-xs`}
                          title="Compose Gmail"
                        >
                          <svg className="w-4 h-4" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M3 8l7.89 5.26a2 2 0 002.22 0L21 8M5 19h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z" />
                          </svg>
                        </button>
                      </div>
                    </div>
                  </div>
                );
              })
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
