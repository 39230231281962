import React, { useState, useEffect, useRef } from 'react';
import { Account } from '../../models/Account';
import { useTeams } from '../../context/TeamContext';
import { Note, NOTE_TYPES } from '../../models/Note';
import { THEME_CLASSES } from '../../constants/themeConstants';
import NoteEditor from './NoteEditor';
import { request } from '../../services/dataService';
import { API_ENDPOINTS } from '../../constants/apiEndpoints';
import EntityTypeahead, { EntityTypeaheadResult } from '../common/EntityTypeahead';
import { openNoteInCalendar, openNoteInGmail } from '../../utils/googleUtils';

interface NewNoteProps {
  isModalOpen: boolean;
  toggleModal: () => void;
  standalone?: boolean;
}

type NoteType = typeof NOTE_TYPES[number];

interface FormState {
  title: string;
  content: string;
  type: NoteType;
  teamIds: number[];
  objectIds: string[];
  objectType: 'account' | 'contact';
}

interface SelectedEntity {
  id: number;
  name: string;
  type: string;
  email?: string;
}

const initialFormState: FormState = {
  title: '',
  content: '',
  type: 'Note',
  teamIds: [],
  objectIds: [],
  objectType: 'account'
};

const NewNote: React.FC<NewNoteProps> = ({ isModalOpen, toggleModal, standalone = false }) => {
  const { teams } = useTeams();
  const [currentUserId] = useState<number>(3);
  const [selectedAccountId, setSelectedAccountId] = useState<number | null>(null);
  const [selectedEntity, setSelectedEntity] = useState<SelectedEntity | null>(null);
  const dialogRef = useRef<HTMLDialogElement>(null);
  const [form, setForm] = useState<FormState>(initialFormState);
  const [submissionState, setSubmissionState] = useState<'idle' | 'success' | 'error'>('idle');
  const [error, setError] = useState<string | null>(null);

  // Add this effect to reset fields when modal opens
  useEffect(() => {
    if (isModalOpen) {
      setForm(initialFormState);
      setSelectedAccountId(null);
      setSelectedEntity(null);
      setSubmissionState('idle');
      setError(null);
      // Preserve team selection if user has only one team
      if (teams.length === 1) {
        setForm(prev => ({
          ...initialFormState,
          teamIds: [teams[0].teamId]
        }));
      }
    }
  }, [isModalOpen, teams]);

  // Auto-select team if user has only one team
  useEffect(() => {
    if (teams.length === 1) {
      setForm(prev => ({
        ...prev,
        teamIds: [teams[0].teamId]
      }));
    }
  }, [teams]);

  useEffect(() => {
    if (isModalOpen && dialogRef.current) {
      dialogRef.current.showModal();
    } else if (dialogRef.current) {
      dialogRef.current.close();
    }
  }, [isModalOpen]);

  const handleEntitySelect = (result: EntityTypeaheadResult): void => {
    setSelectedAccountId(result.id);
    setSelectedEntity({
      id: result.id,
      name: result.name || '',
      type: result.type,
      email: result.email
    });
    setForm(prev => ({
      ...prev,
      objectIds: [result?.id?.toString() || (result.personId ? 99000000 + result.personId : null)?.toString() || ''],
      objectType: result.type === 'Account' ? 'account' : 'contact'
    }));
    // Clear any existing error when account/contact is selected
    setError(null);
  };

  const removeSelectedEntity = (): void => {
    setSelectedAccountId(null);
    setSelectedEntity(null);
    setForm(prev => ({
      ...prev,
      objectIds: []
    }));
  };

  const handleOpenCalendar = () => {
    // Create temporary note object for use with the utility
    const tempNote: Note = {
      id: 0,
      title: form.content?.split('\n')?.[0]?.slice(0, 250) ?? '',
      content: form.content,
      type: form.type,
      teamId: 0,
      teamIds: form.teamIds.join(','),
      createdBy: currentUserId || 0,
      createdAt: new Date(),
      updatedBy: currentUserId || 0,
      updatedAt: new Date(),
      objectIds: form.objectIds.join(','),
      objectType: form.objectType,
      accountName: selectedEntity?.type === 'Account' ? selectedEntity.name : undefined,
      contactName: selectedEntity?.type === 'Contact' ? selectedEntity.name : undefined
    };

    // Default start time to now rounded to nearest hour
    const startDate = new Date();
    startDate.setMinutes(0, 0, 0);
    startDate.setHours(startDate.getHours() + 1);

    openNoteInCalendar(tempNote, startDate);
  };

  const handleOpenGmail = () => {
    // Create temporary note object for use with the utility
    const tempNote: Note = {
      id: 0,
      title: form.content?.split('\n')?.[0]?.slice(0, 250) ?? '',
      content: form.content,
      type: form.type,
      teamId: 0,
      teamIds: form.teamIds.join(','),
      createdBy: currentUserId || 0,
      createdAt: new Date(),
      updatedBy: currentUserId || 0,
      updatedAt: new Date(),
      objectIds: form.objectIds.join(','),
      objectType: form.objectType,
      accountName: selectedEntity?.type === 'Account' ? selectedEntity.name : undefined,
      contactName: selectedEntity?.type === 'Contact' ? selectedEntity.name : undefined
    };

    openNoteInGmail(tempNote, selectedEntity?.email || '');
  };

  const validateForm = (): boolean => {
    // Validate in specific order
    // if (!selectedAccountId) {
    //   setError('Please select an account or contact');
    //   return false;
    // }
    if (!form.content.trim()) {
      setError('Please enter note content');
      return false;
    }
    if (!form.objectIds || form.objectIds.length === 0) {
      console.error('No object IDs found in form:', form);
      setError('Please select at least one contact or account');
      return false;
    }
    // Clear error if all validations pass
    setError(null);
    return true;
  };

  const handleSubmit = async (): Promise<void> => {
    if (standalone) {
      return;
    }

    if (!validateForm()) {
      return;
    }

    try {
      const noteData: Note = {
        title: form.content?.split('\n')?.[0]?.slice(0, 250) ?? '',
        content: form.content,
        type: form.type,
        teamId: 0,
        teamIds: form.teamIds.join(','),
        objectType: form.objectType,
        objectIds: form.objectIds.join(','),
        createdBy: currentUserId || 0,
        createdAt: new Date(),
        updatedBy: currentUserId || 0,
        updatedAt: new Date(),
        id: 0
      };

      await request(API_ENDPOINTS.POST_NOTE, noteData);
      setSubmissionState('success');
      setError(null); // Clear any existing errors on success
    } catch (error) {
      console.error('Failed to create note:', error);
      setError('Failed to create note. Please try again.');
      setSubmissionState('error');
    }
  };

  const renderSuccessView = () => (
    <div className="flex-1 flex flex-col h-full">
      <div className="flex-1 flex items-center justify-center p-6">
        <div className="text-center">
          <div className={`mb-6 ${THEME_CLASSES.text.secondary}`}>
            Your note has been created successfully.
          </div>
          <div className="flex gap-4 justify-center mb-6">
            <button
              onClick={handleOpenCalendar}
              className={`inline-flex items-center px-3 py-1 text-xs rounded-md border ${THEME_CLASSES.border} ${THEME_CLASSES.text.primary} transition-all duration-200 hover:bg-blue-50 dark:hover:bg-blue-900/20 hover:border-blue-300 dark:hover:border-blue-700 hover:shadow-md hover:scale-105 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2`}
            >
              <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4 mr-1" viewBox="0 0 20 20" fill="currentColor">
                <path fillRule="evenodd" d="M6 2a1 1 0 00-1 1v1H4a2 2 0 00-2 2v10a2 2 0 002 2h12a2 2 0 002-2V6a2 2 0 00-2-2h-1V3a1 1 0 10-2 0v1H7V3a1 1 0 00-1-1zm0 5a1 1 0 000 2h8a1 1 0 100-2H6z" clipRule="evenodd" />
              </svg>
              Add to Google Calendar
            </button>
            <button
              onClick={handleOpenGmail}
              className={`inline-flex items-center px-3 py-1 text-xs rounded-md border ${THEME_CLASSES.border} ${THEME_CLASSES.text.primary} transition-all duration-200 hover:bg-blue-50 dark:hover:bg-blue-900/20 hover:border-blue-300 dark:hover:border-blue-700 hover:shadow-md hover:scale-105 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2`}
            >
              <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4 mr-1" viewBox="0 0 20 20" fill="currentColor">
                <path d="M2.003 5.884L10 9.882l7.997-3.998A2 2 0 0016 4H4a2 2 0 00-1.997 1.884z" />
                <path d="M18 8.118l-8 4-8-4V14a2 2 0 002 2h12a2 2 0 002-2V8.118z" />
              </svg>
              Send via Gmail
            </button>
          </div>
        </div>
      </div>
      <div className={`${THEME_CLASSES.border} border-t`}>
        {error && (
          <div className="px-6 py-2 text-red-600 text-sm">{error}</div>
        )}
        <div className="flex items-center justify-end gap-4 py-4 px-6">
          <button
            onClick={() => {
              dialogRef.current?.close();
              toggleModal();
              // Refresh the page after successful note creation
              window.location.reload();
            }}
            className={`inline-flex items-center px-4 py-2 border border-transparent rounded-md shadow-sm text-xs font-medium ${THEME_CLASSES.button.primary}`}
          >
            Close
          </button>
        </div>
      </div>
    </div>
  );

  const renderForm = () => (
    <>
      <div className={`p-6 ${THEME_CLASSES.border} border-b`}>
        <label className="block mb-1 font-medium">Account or Contact</label>
        <div className="relative">
          <EntityTypeahead
            placeholder="Search accounts and contacts..."
            onSelect={handleEntitySelect}
            className={`w-full ${selectedEntity ? 'hidden' : ''}`}
          />
          {selectedEntity && (
            <div className="flex items-center gap-2">
              <div className={`
                flex items-center px-3 py-1.5 rounded-md text-sm
                ${selectedEntity.type === 'Account' ? 'bg-blue-100 text-blue-800' : 'bg-purple-100 text-purple-800'}
              `}>
                {selectedEntity.name}
                <button
                  onClick={removeSelectedEntity}
                  className="ml-2 hover:text-opacity-75 transition-opacity"
                  aria-label={`Remove ${selectedEntity.name}`}
                >
                  <svg className="w-4 h-4" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
                  </svg>
                </button>
              </div>
            </div>
          )}
        </div>
      </div>
      <NoteEditor
        content={form.content}
        type={form.type}
        teamIds={form.teamIds}
        parent="NewNote"
        onContentChange={(content) => {
          setForm(prev => ({ ...prev, content }));
          if (content.trim()) {
            setError(null); // Clear error when content is added
          }
        }}
        onTypeChange={(type) => setForm(prev => ({ ...prev, type }))}
        onTeamChange={(teamIds) => {
          setForm(prev => ({ ...prev, teamIds }));
          if (teamIds.length > 0) {
            setError(null); // Clear error when teams are selected
          }
        }}
        onSubmit={handleSubmit}
        onCancel={() => dialogRef.current?.close()}
        currentUserId={currentUserId}
        isModalOpen={isModalOpen}
        toggleModal={toggleModal}
        error={error}
        setError={setError}
      />
      <div className={`${THEME_CLASSES.border} border-t`}>
        {error && (
          <div className="px-6 py-2 text-red-600 text-sm">{error}</div>
        )}
      </div>
    </>
  );

  return (
    <dialog
      ref={dialogRef}
      className="fixed inset-0 z-50 bg-transparent"
      onClose={toggleModal}
    >
      <div className="fixed inset-0 bg-black/50" />
      <div className={`fixed left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 rounded-lg w-[800px] h-[80vh] flex flex-col ${THEME_CLASSES.background}`}>
        <div className={`flex justify-between items-center ${THEME_CLASSES.note.card.background.gray} rounded-t-lg p-4`}>
          <h3 className={`text-lg font-semibold ${THEME_CLASSES.text.primary} pl-4 pt-2 my-auto`}>
            Add New Note
          </h3>
          <button
            type="button"
            onClick={() => dialogRef.current?.close()}
            className={`${THEME_CLASSES.text.secondary} ${THEME_CLASSES.hover.text} ${THEME_CLASSES.hover.background} rounded-full mr-4`}
            aria-label="Close"
          >
            ×
          </button>
        </div>
        <div className="flex-1 flex flex-col h-full overflow-hidden">
          {submissionState === 'success' ? renderSuccessView() : renderForm()}
        </div>
      </div>
    </dialog>
  );
};

export default NewNote;
