import { FC, useEffect, useState, useCallback, useRef, useMemo } from 'react';
import { THEME_CLASSES } from '../constants/themeConstants';
import { SchemaProvider, type Schema } from '../context/SchemaContext';
import NewAccount from '../components/account/NewAccount';
import useDebounce from '../hooks/useDebounce';
import AccountList from '../components/account/AccountList';
import AccountTable from '../components/account/AccountTable';
import SchemaSelector from '../components/common/SchemaSelector';
import Spinner from '../components/common/Spinner';
import { useLocation, useNavigate } from 'react-router-dom';
import { useTeams } from '../context/TeamContext';

// Inner component that uses SchemaContext
const AccountsContent: FC = () => {
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const activeTab: 'all' | 'my' = pathname.endsWith('/all') ? 'all' : 'my';
  const { isLoading: isLoadingUser, hasTeams } = useTeams();

  // UI state
  const [searchQuery, setSearchQuery] = useState(() => localStorage.getItem('accounts-search') || '');
  const [isNewAccountOpen, setIsNewAccountOpen] = useState(false);
  const [selectedSchema, setSelectedSchema] = useState<Schema | null>(null);
  const [rowCount, setRowCount] = useState(0);
  // Debounce search query
  const debouncedSearchQuery = useDebounce(searchQuery, 500);

  // Handle initial tab restoration on mount
  const isInitialMount = useRef(true);
  useEffect(() => {
    if (isInitialMount.current) {
      const storedTab = localStorage.getItem('accounts-tab');
      if (storedTab && storedTab !== activeTab) {
        navigate(storedTab === 'all' ? '/accounts/all' : '/accounts/my');
      }
      isInitialMount.current = false;
    }
  }, [navigate, activeTab]);

  // Persist search and tab in localStorage
  useEffect(() => {
    localStorage.setItem('accounts-search', searchQuery);
    localStorage.setItem('accounts-tab', activeTab);
  }, [searchQuery, activeTab]);

  if (isLoadingUser) {
    return (
      <div className={`p-6 ${THEME_CLASSES.text.primary}`}>
        <div className="flex items-center justify-center h-64">
          <Spinner />
          <span className="ml-3">Loading user data...</span>
        </div>
      </div>
    );
  }

  if (!hasTeams) {
    return (
      <div className={`p-6 ${THEME_CLASSES.text.primary}`}>
        <div className="flex flex-col items-center justify-center h-64 text-center">
          <div className="text-red-500 mb-4">
            <svg xmlns="http://www.w3.org/2000/svg" className="h-16 w-16 mx-auto" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z" />
            </svg>
          </div>
          <h2 className="text-xl font-semibold mb-2">No Team Assignment</h2>
          <p className="mb-4">Your account doesn't have any team assignments.</p>
          <p>Please contact your administrator to get assigned to a team.</p>
        </div>
      </div>
    );
  }

  return (
    <>
      <div className="h-full flex flex-col">
        {/* header */}
        <div className="flex items-center justify-between mb-6 mx-6">
          <div className="flex items-center gap-2">
            <h1 className={`text-xl font-semibold my-auto ${THEME_CLASSES.text.primary}`}>Accounts</h1>
            {activeTab === 'all' && (
              <button
                onClick={() => setIsNewAccountOpen(true)}
                className={`p-1 rounded-full ${THEME_CLASSES.button.primary}`}
                title="Create new account"
              >
                <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4" viewBox="0 0 20 20" fill="currentColor">
                  <path fillRule="evenodd" d="M10 3a1 1 0 0 1 1 1v5h5a1 1 0 1 1 0 2h-5v5a1 1 0 1 1-2 0v-5H4a1 1 0 1 1 0-2h5V4a1 1 0 0 1 1-1z" clipRule="evenodd" />
                </svg>
              </button>
            )}
          </div>
          <div className="flex gap-2">
            <button
              onClick={() => {
                navigate('/accounts/my');
                setSearchQuery('');
              }}
              className={`px-4 py-1 text-xs font-medium rounded-full ${activeTab === 'my'
                ? `${THEME_CLASSES.button.primary} font-bold`
                : `${THEME_CLASSES.button.secondary} ${THEME_CLASSES.hover.background}`}`}
            >
              My Accounts
            </button>
            <button
              onClick={() => navigate('/accounts/all')}
              className={`px-4 py-1 text-xs font-medium rounded-full ${activeTab === 'all'
                ? `${THEME_CLASSES.button.primary} font-bold`
                : `${THEME_CLASSES.button.secondary} ${THEME_CLASSES.hover.background}`}`}
            >
              All Accounts
            </button>
          </div>
          <div className="relative flex items-center gap-4">
            {/* Search box */}
            <div className="w-64 relative">
              <div className="absolute inset-y-0 left-0 pl-4 flex items-center pointer-events-none">
                <svg className={`h-4 w-4 ${THEME_CLASSES.text.secondary}`} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                  <path fillRule="evenodd" d="M8 4a4 4 0 1 0 0 8 4 4 0 0 0 0-8zM2 8a6 6 0 1 1 10.89 3.476l4.817 4.817a1 1 0 0 1-1.414 1.414l-4.816-4.816A6 6 0 0 1 2 8z" clipRule="evenodd" />
                </svg>
              </div>
              {/* Record count */}
              <div className="absolute left-0 -translate-x-[calc(100%+0.75rem)] text-xs font-medium px-3 py-1.5 rounded-full bg-gray-100 dark:bg-gray-700 text-gray-600 dark:text-gray-300">
                {rowCount}
              </div>
              <input
                type="text"
                placeholder="Search accounts..."
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
                className={`w-full pl-11 pr-8 py-2 border rounded-md text-xs font-medium focus:outline-none focus:ring-1 focus:ring-blue-500 ${searchQuery ? 'border-red-500' : THEME_CLASSES.input.border} ${THEME_CLASSES.input.background} ${THEME_CLASSES.input.text} ${THEME_CLASSES.input.placeholder}`}
              />
              {searchQuery && (
                <button
                  onClick={() => setSearchQuery('')}
                  className="absolute inset-y-0 right-0 pr-3 flex items-center"
                >
                  <svg className={`h-4 w-4 ${THEME_CLASSES.text.secondary} hover:${THEME_CLASSES.text.primary}`} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                    <path fillRule="evenodd" d="M4.293 4.293a1 1 0 1 1 1.414 0L10 8.586l4.293-4.293a1 1 0 1 1 1.414 1.414l-4.293 4.293 4.293 4.293a1 1 0 0 1-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 0 1-1.414-1.414l4.293-4.293-4.293-4.293a1 1 0 0 1 0-1.414z" clipRule="evenodd" />
                  </svg>
                </button>
              )}
            </div>
            {/* Schema Selector */}
            {activeTab === 'all' && (
              <SchemaSelector
                onSchemaChange={setSelectedSchema}
                entityType={2}
                storageKey="accounts-team"
              />
            )}
          </div>
        </div>

        {/* main area */}
        <div className="h-[calc(100vh-150px)] overflow-y-auto relative">
          {activeTab === 'all' ? (
            selectedSchema ? (
              <AccountTable
                searchQuery={debouncedSearchQuery}
                schema={selectedSchema}
                onRowCountChange={setRowCount}
              />
            ) : null
          ) : (
            <AccountList
              searchValue={debouncedSearchQuery}
              onRowCountChange={setRowCount}
            />
          )}
        </div>
      </div>
      <NewAccount isOpen={isNewAccountOpen} onClose={() => setIsNewAccountOpen(false)} />
    </>
  );
};

const Accounts: FC = () => {
  return (
    <SchemaProvider>
      <AccountsContent />
    </SchemaProvider>
  );
};

export default Accounts;
