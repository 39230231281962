import { z } from "zod";
import { DataRoutes } from "./types";

const usersRoutes: DataRoutes = {

  // Get all users
  'get /api/data/users': {
    query: `SELECT id, name, email FROM [Users]`
  },

  // Add a contact to user's contact list by email
  'post /api/data/users/:userId/contacts': {
    schema: z.object({
      id: z.number().int().default(-1)
    }),
    query: `
    IF NOT EXISTS (SELECT 1 FROM [UserContacts] WHERE [UserId] = @userId AND ContactId = @id)
    BEGIN
      INSERT INTO [UserContacts] (UserId, ContactId) VALUES (@userId, @id)
    END
    `
  },

  // Remove a contact from user's contact list
  'delete /api/data/users/:userId/contacts/:id': {
    schema: z.object({
      userId: z.number().int(),
      id: z.number().int()
    }),
    query: `DELETE FROM [UserContacts] WHERE [UserId] = @userId AND ContactId = @id`
  },

  // Add an account to user's account list by account id
  'post /api/data/users/:userId/accounts': {
    schema: z.object({
      id: z.number().int()
    }),
    query: `
        IF NOT EXISTS (SELECT 1 FROM [UserAccounts] WHERE [UserId] = @userId AND AccountId = @id)
        BEGIN
            INSERT INTO [UserAccounts] (UserId, AccountId) VALUES (@userId, @id)
        END`
  },

  // Remove a account from user's account list
  'delete /api/data/users/:userId/accounts/:id': {
    schema: z.object({
      userId: z.number().int(),
      id: z.number().int()
    }),
    query: `DELETE FROM [UserAccounts] WHERE [UserId] = @userId AND AccountId = @id`
  },

  'get /api/data/who': {
    query: `EXEC [dbo].[GetSessionUser]`
  }
}

export default usersRoutes;
