import { FC, useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Contact } from '../models/Contact';
import { getContactPhotoUrl } from '../utils/avatarUtils';
import { getNotesSummaryPrompt } from '../utils/summaryUtils';
import { useNotification } from '../context/NotificationContext';
import ContactDetails from '../components/contact/ContactDetails';
import ContactNotes from '../components/note/AccountNotes';
import { useEventBus } from '../context/EventBusContext';
import { request } from '../services/dataService';
import { API_ENDPOINTS } from '../constants/apiEndpoints';
import { useTeams } from '../context/TeamContext';
import { SchemaProvider } from '../context/SchemaContext';
import Spinner from '../components/common/Spinner';
import { THEME_CLASSES } from '../constants/themeConstants';
import { renderTypeChip } from '../utils/typeChipUtils';

interface ContactPageProps {
  onContactSelect: (contact: Contact) => void;
}

const ContactPageContent: FC<ContactPageProps> = ({ onContactSelect }) => {
  const { showNotification } = useNotification();
  const { currentUserId } = useTeams();
  const { id, tab, noteId } = useParams<{ id: string; tab?: string; noteId?: string }>();
  const navigate = useNavigate();
  const [contact, setContact] = useState<Contact | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [activeTab, setActiveTab] = useState<'overview' | 'notes'>(
    tab === 'notes' ? 'notes' : 'overview'
  );

  const { publish } = useEventBus();
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (!id) return;
        const contactId = parseInt(id);
        const contactData = await request(API_ENDPOINTS.GET_CONTACT, { id: contactId });
        if (contactData) {
          onContactSelect(contactData);
          setContact(contactData);
        }
        setError(null);
      } catch (err) {
        setError('Failed to load contact details');
        console.error('Error fetching contact:', err);
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, [id]); // onContactSelect intentionally omitted to match Account pattern

  const handleSummarize = async () => {
    if (!contact) return;
    const notes = await request(API_ENDPOINTS.GET_CONTACT_NOTES, { id: contact.id });
    if (!notes || !notes.length) return;
    const prompt = getNotesSummaryPrompt(notes);
    publish('chat:open', prompt);
  };

  if (isLoading) return (
    <div className="fixed inset-0 flex items-center justify-center">
      <Spinner />
    </div>
  );

  if (error) return (
    <div className={`p-6 ${THEME_CLASSES.text.primary}`}>
      <div className="flex items-center justify-center h-64">
        <div className="text-red-500">{error}</div>
      </div>
    </div>
  );

  if (!contact) return (
    <div className={`p-6 ${THEME_CLASSES.text.primary}`}>
      <div className="flex items-center justify-center h-64">
        <div>Contact not found</div>
      </div>
    </div>
  );


  return (
    <div className='h-full flex flex-col'>
      <div className={`${THEME_CLASSES.background}`}>
        <div className="mx-auto">
          <div className="px-4">
            <div className="flex flex-row items-start gap-4 mb-6">
              <div className="w-20 h-20 rounded-lg border-2 ${THEME_CLASSES.border} overflow-hidden flex-shrink-0">
                <img
                  src={getContactPhotoUrl(contact)}
                  alt={`${contact.firstName} ${contact.lastName}`}
                  className="w-full h-full object-cover bg-gray-300 dark:bg-gray-600"
                  onError={(e) => {
                    const target = e.target as HTMLImageElement;
                    target.src = '';
                    target.className = 'w-full h-full bg-gray-300 dark:bg-gray-600 flex items-center justify-center';
                  }}
                />
              </div>
              <div className="flex-1 min-w-0">
                <div className="flex flex-col md:flex-row items-start md:items-center gap-2 md:gap-4">
                  <div className="flex-1 flex flex-col md:flex-row gap-2 md:gap-4 items-start md:items-center">
                    <h1 className={`text-lg font-semibold my-auto ${THEME_CLASSES.text.primary}`}>
                      {contact.firstName} {contact.lastName}
                    </h1>

                    <div className="flex items-center gap-2">
                      <div className={`text-sm ${THEME_CLASSES.text.secondary} truncate`}>
                        {contact.companyName || contact.accountName}
                      </div>
                      {renderTypeChip({ rlpCompanyId: contact.rlpCompanyId, teamId: contact.teamId })}
                    </div>
                  </div>
                  <div className="flex gap-2 md:gap-4">
                    <div className={`text-sm ${THEME_CLASSES.text.secondary} truncate`}>
                      {contact.email}
                    </div>
                    <div className={`text-sm ${THEME_CLASSES.text.secondary} truncate`}>
                      {contact.phone}
                    </div>
                  </div>
                </div>
                <div className="flex flex-wrap mt-4 -ml-24 md:ml-0 justify-between items-center">
                  <div className="flex flex-wrap gap-2 items-center">
                    <nav className="flex flex-wrap gap-2" aria-label="Tabs">
                      <button
                        onClick={() => {
                          setActiveTab('overview');
                          navigate(`/contacts/${id}/overview`);
                        }}
                        className={`rounded-full py-1 px-3 text-xs md:text-sm font-medium ${activeTab === 'overview'
                          ? `${THEME_CLASSES.button.primary} font-bold`
                          : `${THEME_CLASSES.button.secondary} ${THEME_CLASSES.hover.background}`
                          }`}
                      >
                        Overview
                      </button>
                      <button
                        onClick={() => {
                          setActiveTab('notes');
                          navigate(`/contacts/${id}/notes`);
                        }}
                        className={`rounded-full py-1 px-3 text-xs md:text-sm font-medium ${activeTab === 'notes'
                          ? `${THEME_CLASSES.button.primary} font-bold`
                          : `${THEME_CLASSES.button.secondary} ${THEME_CLASSES.hover.background}`
                          }`}
                      >
                        Contacts and Notes
                      </button>
                    </nav>
                  </div>


                  <div className="flex items-center gap-2">
                    <div className="flex gap-2">
                      <button
                        onClick={async () => {
                          try {
                            await request(API_ENDPOINTS.POST_USER_CONTACT, {
                              userId: currentUserId,
                              id: contact.id
                            });
                            showNotification(`${contact.firstName} ${contact.lastName} saved to your contact list!`, 'success');
                          } catch (error) {
                            console.error('Error saving contact:', error);
                            showNotification('Failed to save contact', 'error');
                          }
                        }}
                        className={`rounded ${THEME_CLASSES.hover.background}`}
                        title="Save to your contacts"
                      >
                        <svg xmlns="http://www.w3.org/2000/svg" className={`h-5 w-5 ${THEME_CLASSES.text.primary}`} viewBox="0 0 24 24" fill="none" stroke="currentColor">
                          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M18 9v3m0 0v3m0-3h3m-3 0h-3m-2-5a4 4 0 11-8 0 4 4 0 018 0zM3 20a6 6 0 0112 0v1H3v-1z" />
                        </svg>
                      </button>

                      <button
                        onClick={handleSummarize}
                        className={`p-2 rounded-md ${THEME_CLASSES.text.secondary} ${THEME_CLASSES.hover.background}`}
                        title="Ask AI about this contact"
                      >
                        <svg className="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M8 12h.01M12 12h.01M16 12h.01M21 12c0 4.418-4.03 8-9 8a9.863 9.863 0 01-4.255-.949L3 20l1.395-3.72C3.512 15.042 3 13.574 3 12c0-4.418 4.03-8 9-8s9 3.582 9 8z" />
                        </svg>
                      </button>

                      <button
                        onClick={() => {
                          const eventTitle = `RE: ${contact.firstName} ${contact.lastName}`;
                          const calendarUrl = `https://calendar.google.com/calendar/u/0/r/eventedit?text=${encodeURIComponent(eventTitle)}`;
                          window.open(calendarUrl, '_blank');
                        }}
                        className={`p-2 rounded-md ${THEME_CLASSES.text.secondary} ${THEME_CLASSES.hover.background}`}
                        title="Add to Google Calendar"
                      >
                        <svg className="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M8 7V3m8 4V3m-9 8h10M5 21h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v12a2 2 0 002 2z" />
                        </svg>
                      </button>

                      <button
                        onClick={() => {
                          const subject = `RE: ${contact.firstName} ${contact.lastName}`;
                          const gmailUrl = `https://mail.google.com/mail/?view=cm&fs=1&su=${encodeURIComponent(subject)}`;
                          window.open(gmailUrl, '_blank');
                        }}
                        className={`p-2 rounded-md ${THEME_CLASSES.text.secondary} ${THEME_CLASSES.hover.background}`}
                        title="Compose Gmail"
                      >
                        <svg className="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M3 8l7.89 5.26a2 2 0 002.22 0L21 8M5 19h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z" />
                        </svg>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className={`h-px w-full ${THEME_CLASSES.border} mt-4`}></div>
          </div>
        </div>
      </div>
      <div className="flex-1 pt-2 overflow-hidden">
        <div className="h-[calc(100vh-200px)]">
          {activeTab === 'overview' && (
            <ContactDetails
              contact={contact}
            />
          )}
          {activeTab === 'notes' && contact && (
            <ContactNotes
              accountId={contact.accountId}
              accountName={contact.companyName || ''}
              initialContactId={contact.id}
              initialNoteId={noteId ? parseInt(noteId) : undefined}
            />
          )}
        </div>
      </div>
    </div>
  );
};

const ContactPage: FC<ContactPageProps> = (props) => {
  return (
    <SchemaProvider>
      <ContactPageContent {...props} />
    </SchemaProvider>
  );
};

export default ContactPage;
