import { FC, useEffect, useMemo, useState } from 'react';
import { Account } from '../../models/Account';
import { THEME_CLASSES } from '../../constants/themeConstants';
import AccountCard from './AccountCard';
import { request } from '../../services/dataService';
import { API_ENDPOINTS } from '../../constants/apiEndpoints';
import { useTeams } from '../../context/TeamContext';
import Spinner from '../common/Spinner';
import { filterAccounts, getAccountDisplayFields } from '../../utils/accountUtils';

interface AccountListProps {
  searchValue: string;
  onRowCountChange?: (count: number) => void;
}

const AccountList: FC<AccountListProps> = ({ searchValue, onRowCountChange }) => {
  const { currentUserId } = useTeams();
  const [accounts, setAccounts] = useState<Account[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  // Fetch user accounts
  useEffect(() => {
    const fetchData = async () => {
      if (!currentUserId) return;

      setLoading(true);
      try {
        // GET_USER_ACCOUNTS returns complete account objects, not just IDs
        const userAccounts = await request(API_ENDPOINTS.GET_USER_ACCOUNTS, { userId: currentUserId });

        // Set accounts from the API response
        if (Array.isArray(userAccounts)) {
          setAccounts(userAccounts);
        } else {
          setAccounts([]);
        }
        setError(null);
      } catch (err) {
        const errorMessage = err instanceof Error ? err.message : 'Failed to load accounts';
        setError(errorMessage);
        setAccounts([]);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [currentUserId]);

  const keywords = useMemo(() => searchValue.toLowerCase().split(/\s+/).filter(Boolean), [searchValue]);

  const filteredAccounts = useMemo(() => {
    // Specify the fields we want to search in
    const searchFields = getAccountDisplayFields().map((field: any) => field.key);
    return filterAccounts(accounts, searchValue, searchFields);
  }, [accounts, searchValue]);

  // Update parent with row count
  useEffect(() => {
    onRowCountChange?.(filteredAccounts.length);
  }, [filteredAccounts.length, onRowCountChange]);

  // Custom delete handler that uses DELETE_USER_ACCOUNT
  const handleDeleteAccount = async (id: number) => {
    if (!currentUserId) return;

    try {
      await request(API_ENDPOINTS.DELETE_USER_ACCOUNT, {
        userId: currentUserId,
        id: id
      });

      // Remove from local state after successful deletion
      setAccounts(prevAccounts => prevAccounts.filter(account => account.id !== id));
    } catch (err) {
      console.error('Error removing account from user list:', err);
    }
  };

  if (loading) {
    return (
      <div className="flex items-center justify-center h-64">
        <Spinner />
      </div>
    );
  }

  if (error) {
    return (
      <div className="text-red-500 text-center h-64 flex items-center justify-center">
        {error}
      </div>
    );
  }

  return (
    <>
      {filteredAccounts.length === 0 ? (
        <div className={`text-center py-6 ${THEME_CLASSES.text.secondary}`}>
          {searchValue ? 'No accounts found. Please check your search criteria.' : 'No accounts found. Add accounts to your list from the "All Accounts" tab.'}
        </div>
      ) : (
        <div className={`w-full h-fit grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-6 p-6 ${THEME_CLASSES.background}`}>
          {filteredAccounts.map((account) => (
            <AccountCard
              key={account.id}
              account={account}
              keywords={keywords}
              onDelete={handleDeleteAccount}
            />
          ))}
        </div>
      )}
    </>
  );
};

export default AccountList;
