// Theme colors
export const THEME_COLORS = {
  light: {
    background: '#F9FAFB', // bg-gray-50
  },
  dark: {
    background: '#111827', // bg-gray-900
  }
} as const;

// Tailwind classes
export const THEME_CLASSES = {
  background: 'bg-gray-50 dark:bg-gray-900',
  border: 'border-gray-200 dark:border-gray-800',
  text: {
    primary: 'text-gray-900 dark:text-white',
    secondary: 'text-gray-600 dark:text-gray-300',
    pastDate: 'text-gray-400 dark:text-gray-500',
  },
  hover: {
    background: 'hover:bg-gray-100 dark:hover:bg-gray-700',
    text: 'hover:text-gray-900 dark:hover:text-white',
  },
  input: {
    background: 'bg-white dark:bg-gray-700',
    border: 'border-gray-300 dark:border-gray-600',
    text: 'text-gray-900 dark:text-gray-100 text-sm',
    placeholder: 'placeholder-gray-500 dark:placeholder-gray-400',
    base: 'rounded-md shadow-sm transition-colors file:border-0 file:bg-transparent file:text-sm file:font-medium focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-ring disabled:cursor-not-allowed disabled:opacity-50',
    field: 'flex h-9 w-full px-3 py-1',
    textarea: 'w-full px-3 py-2'
  },
  button: {
    primary: 'bg-blue-600 hover:bg-blue-700 text-white',
    secondary: 'bg-gray-50 dark:bg-gray-700 text-gray-700 dark:text-gray-200',
    danger: 'bg-red-600 hover:bg-red-700 text-white',
    hover: 'hover:bg-gray-100 dark:hover:bg-gray-700'
  },
  status: {
    error: 'bg-red-100 dark:bg-red-900 text-red-700 dark:text-red-300',
    success: 'bg-green-100 dark:bg-green-900 text-green-700 dark:text-green-300',
    default: 'bg-gray-200 dark:bg-gray-950',
    todo: 'bg-blue-100 dark:bg-blue-950',
    inProgress: 'bg-yellow-100 dark:bg-yellow-950',
    done: 'bg-green-100 dark:bg-green-950'
  },
  chip: {
    background: 'bg-blue-100 dark:bg-blue-900',
    text: 'text-blue-700 dark:text-blue-300',
    hover: 'hover:bg-blue-200 dark:hover:bg-blue-800'
  },
  card: {
    background: 'bg-white dark:bg-gray-800',
    border: 'border-gray-200 dark:border-gray-700',
    hover: {
      transform: 'transition-all duration-200 ease-in-out transform hover:-translate-y-1',
      shadow: 'hover:shadow-2xl',
      border: 'hover:border-blue-300 dark:hover:border-blue-600',
      full: 'transition-all duration-200 ease-in-out transform hover:-translate-y-1 hover:shadow-2xl hover:border-blue-300 dark:hover:border-blue-600'
    }
  },
  dropdown: {
    background: 'bg-white dark:bg-gray-800',
    item: {
      base: 'text-gray-700 dark:text-gray-200',
      hover: 'hover:bg-gray-100 dark:hover:bg-gray-700'
    }
  },
  note: {
    card: {
      background: {
        green: 'bg-green-100 dark:bg-green-900',
        purple: 'bg-purple-100 dark:bg-purple-900',
        blue: 'bg-blue-100 dark:bg-blue-900',
        orange: 'bg-orange-100 dark:bg-orange-900',
        red: 'bg-red-100 dark:bg-red-900',
        gray: 'bg-gray-100 dark:bg-gray-900'
      }
    },
    timeline: {
      dateMark: {
        today: 'bg-red-400 dark:bg-red-900 text-white dark:text-white',
        weekend: 'bg-gray-100 dark:bg-gray-800',
        weekday: 'bg-transparent',
        border: 'border-gray-200 dark:border-gray-700'
      }
    }
  }
} as const;

// Breakpoints
export const BREAKPOINTS = {
  mobile: '(max-width: 767px)',
  tablet: '(min-width: 768px) and (max-width: 1023px)',
  desktop: '(min-width: 1024px)',
} as const;

// Sidebar
export const SIDEBAR = {
  collapsedWidth: 'w-16',
  expandedWidth: 'w-52',
} as const;
