import { useState } from 'react';
import DynamicForm from './DynamicForm';
import { Schema } from '../../context/SchemaContext';

interface SchemaBasedDetailsProps {
  schemas: Schema[];
  data: any;
  onSubmit: (data: any) => Promise<void>;
  onDelete?: () => Promise<void>;
  onCancel?: () => void;
  isDeleteEnabled?: boolean;
  isDisabled?: (schema: Schema) => boolean;
}

const SchemaBasedDetails: React.FC<SchemaBasedDetailsProps> = ({
  schemas,
  data,
  onSubmit,
  onDelete,
  onCancel,
  isDeleteEnabled,
  isDisabled
}) => {
  const [collapsedSections, setCollapsedSections] = useState<Record<string, boolean>>({});

  const toggleSection = (sectionId: string) => {
    setCollapsedSections(prev => ({
      ...prev,
      [sectionId]: !prev[sectionId]
    }));
  };

  const formCards = schemas.map((schema, index) => {
    const sectionId = schema.name || `schema-${index}`;
    const isCollapsed = collapsedSections[sectionId];
    const fields = schema.schema ? JSON.parse(schema.schema) : [];
    const disabled = isDisabled ? isDisabled(schema) : false;

    return (
      <div
        key={sectionId}
        className="bg-white dark:bg-gray-800 rounded-lg shadow border dark:border-gray-700 mb-4"
      >
        <div
          className="flex items-center justify-between p-4 cursor-pointer bg-gray-50 hover:bg-gray-100 dark:bg-gray-700 dark:hover:bg-gray-600 rounded-t-lg border-b dark:border-gray-600 transition-colors duration-200"
          onClick={() => toggleSection(sectionId)}
        >
          <h3 className="text-lg font-medium text-gray-900 dark:text-gray-100 my-auto mx-auto">
            {schema.name}
          </h3>
          <button
            className="text-gray-500 hover:text-gray-700 dark:text-gray-400 dark:hover:text-gray-200 transform transition-transform duration-200"
            aria-label={isCollapsed ? "Expand section" : "Collapse section"}
          >
            {isCollapsed ? (
              <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 rotate-0" viewBox="0 0 20 20" fill="currentColor">
                <path fillRule="evenodd" d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" clipRule="evenodd" />
              </svg>
            ) : (
              <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 rotate-180" viewBox="0 0 20 20" fill="currentColor">
                <path fillRule="evenodd" d="M14.707 12.707a1 1 0 01-1.414 0L10 9.414l-3.293 3.293a1 1 0 01-1.414-1.414l4-4a1 1 0 011.414 0l4 4a1 1 0 010 1.414z" clipRule="evenodd" />
              </svg>
            )}
          </button>
        </div>

        <div className="p-5">
          {!isCollapsed && (
          <DynamicForm
            key={`form-${schema.name || index}`}
            fields={fields}
            initialData={data}
            disabled={disabled}
            onSubmit={onSubmit}
            submitLabel="Update"
            cancelLabel={!disabled ? "Cancel" : undefined}
            onCancel={!disabled ? onCancel : undefined}
            canDelete={isDeleteEnabled}
            onDelete={onDelete}
          />
          )}
        </div>
      </div>
    );
  });

  return (
    <div className="max-h-[80vh] overflow-y-auto">
      {formCards}
    </div>
  );
};

export default SchemaBasedDetails;
