import { FC, useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Account } from '../../models/Account';
import Grid from '../common/Grid';
import { request } from '../../services/dataService';
import { API_ENDPOINTS } from '../../constants/apiEndpoints';
import { type Schema } from '../../context/SchemaContext';
import { type FormField } from '../common/FormField';
import { getAccountColumnDefs, filterAccounts } from '../../utils/accountUtils';
import Spinner from '../common/Spinner';

interface AccountTableProps {
  searchQuery: string;
  schema: Schema | null;
  onRowCountChange: (count: number) => void;
}

const AccountTable: FC<AccountTableProps> = ({ searchQuery, schema, onRowCountChange }) => {
  const navigate = useNavigate();

  // Data state
  const [accounts, setAccounts] = useState<Account[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [schemaFields, setSchemaFields] = useState<FormField[]>([]);

  // Separate schema parsing and data fetching
  useEffect(() => {
    // Parse schema fields if schema exists
    if (schema) {
      try {
        const fields: FormField[] = JSON.parse(schema.schema);
        setSchemaFields(fields);
      } catch (error) {
        console.error('Error parsing schema:', error);
        setSchemaFields([]);
      }
    } else {
      setSchemaFields([]);
    }


    // Determine which teamId to use for fetching accounts
    const teamId = schema?.teamId && !schema?.rlp? schema.teamId : 0;
    
    // Fetch accounts data
    const fetchAccounts = async () => {
      setLoading(true);
      setError(null);

      try {
        const response = await request(API_ENDPOINTS.GET_TEAM_ACCOUNTS, { teamId });
        setAccounts(response || []);
      } catch (err) {
        const errorMessage = err instanceof Error ? err.message : 'Failed to load accounts';
        setError(errorMessage);
        setAccounts([]);
      } finally {
        setLoading(false);
      }
    };

    fetchAccounts();
  }, [schema]);

  const gridOptions = useMemo(() => ({
    onRowClicked: (params: { data: Account }) => {
      navigate(`/accounts/${params.data.id}/notes`);
    }
  }), [navigate]);

  // Generate column definitions
  const columnDefs = useMemo(() => {
    return getAccountColumnDefs(searchQuery, schemaFields);
  }, [searchQuery, schemaFields]);

  // Filter accounts based on search
  const filteredAccounts = useMemo(() => {
    return filterAccounts(accounts, searchQuery, null, schema?.rlp === 1);
  }, [accounts, searchQuery]);

  // Update parent with row count
  useEffect(() => {
    onRowCountChange(filteredAccounts.length);
  }, [filteredAccounts.length, onRowCountChange]);

  if (loading) {
    return (
      <div className="flex items-center justify-center h-64">
        <Spinner />
      </div>
    );
  }

  if (error) {
    return (
      <div className="text-red-500 text-center h-64 flex items-center justify-center">
        {error}
      </div>
    );
  }

  return (
    <div>
      <Grid
        data={filteredAccounts}
        columnDefs={columnDefs}
        searchQuery={searchQuery}
        gridOptions={gridOptions}
      />
    </div>
  );
};

export default AccountTable;
