/**
 * Parses the data property of an object or array of objects if it exists as a JSON string
 * If the data property is a valid JSON string, it merges the parsed data with the rest of the object
 * If parsing fails or there is no data property, returns the original object
 */
export const parseDataProperty = <T extends Record<string, any> | Record<string, any>[]>(obj: T): T => {
  if (Array.isArray(obj)) {
    return obj.map(item => parseDataProperty(item)) as T;
  }

  if (obj) {
    // Find any 'data' property regardless of case (data, Data, DATA, etc)
    const dataKey = Object.keys(obj).find(key =>
      key.toLowerCase() === 'data'
    );

    if (dataKey) {
      try {
        // Pre-process the string to handle NaN
        const processedString = typeof obj[dataKey] === 'string'
          ? obj[dataKey].replace(/:\s*NaN/g, ':"NaN"')
          : obj[dataKey];

        const parsedData = JSON.parse(processedString);
        const rest = Object.fromEntries(
          Object.entries(obj).filter(([key]) => key !== dataKey)
        );

        // Handle case where data property itself is an array
        if (Array.isArray(parsedData)) {
          return {
            ...rest,
            [dataKey]: parsedData.map(item => parseDataProperty(item))
          } as T;
        }

        // Filter out properties that have NaN values
        const processedData = Object.fromEntries(
          Object.entries(parsedData)
            .filter(([_, value]) => value !== "NaN")
        );

        return {
          ...rest,
          ...processedData
        } as T;
      } catch (error) {
        console.error('Parsing error:', error, obj[dataKey]);
        return obj;
      }
    }
  }
  return obj;
};
