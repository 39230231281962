import { useState, useEffect, useCallback, useMemo, useRef } from 'react';
import { AgGridReact } from 'ag-grid-react';
import {
  GridStateModule,
  ColDef,
  ModuleRegistry,
  ClientSideRowModelModule,
  ValidationModule,
  TextFilterModule,
  NumberFilterModule,
  DateFilterModule,
  EventApiModule,
  ColumnApiModule,
  ExternalFilterModule,
  SelectEditorModule,
  TextEditorModule,
  DateEditorModule,
  RenderApiModule,
  RowApiModule,
  ICellRendererParams,
  GridApi,
  ValueSetterParams,

  IRowNode,
  LargeTextEditorModule,
  CellStyleModule,
  CheckboxEditorModule,
  RowDragModule,
  RowSelectionModule,
  RowDataTransaction,
  ClientSideRowModelApiModule,
  NumberEditorModule
} from 'ag-grid-community';
import { THEME_CLASSES } from '../../constants/themeConstants';

// Register modules
ModuleRegistry.registerModules([
  ClientSideRowModelModule,
  GridStateModule,
  ValidationModule,
  TextFilterModule,
  NumberFilterModule,
  DateFilterModule,
  EventApiModule,
  ColumnApiModule,
  ExternalFilterModule,
  SelectEditorModule,
  TextEditorModule,
  DateEditorModule,
  RenderApiModule,
  RowApiModule,
  LargeTextEditorModule,
  CellStyleModule,
  CheckboxEditorModule,
  RowDragModule,
  RowSelectionModule,
  ClientSideRowModelApiModule,
  ValidationModule,
  NumberEditorModule
]);

interface MessageProps {
  text: string;
  isError: boolean;
}

export interface CustomRenderers<T> {
  [key: string]: (props: ICellRendererParams<T>) => JSX.Element;
}

interface GridProps<T extends Record<string, any>> {
  message?: MessageProps;
  isSaving?: boolean;
  data: T[];
  columnDefs: ColDef<T>[];
  searchQuery?: string;
  onColumnStateChanged?: (newDefs: ColDef<T>[]) => void;
  getRowId?: (data: T) => string;
  onValueChanged?: (data: T[]) => void;
  enableRowDrag?: boolean;
  onAddRow?: () => void;
  canAddRows?: boolean;
  canRemoveRows?: boolean;
  gridOptions?: any;
}

function Grid<T extends Record<string, any> & { id: number | string }>({
  data,
  columnDefs,
  searchQuery = '',
  onColumnStateChanged,
  getRowId = (data: T) => String(data.id),
  onValueChanged,
  enableRowDrag = false,
  onAddRow,
  canAddRows = false,
  canRemoveRows = false,
  gridOptions = {},
  message,
  isSaving
}: GridProps<T>) {
  const [globalFilter, setGlobalFilter] = useState<string[]>([]);
  const [hasSelectedRows, setHasSelectedRows] = useState(false);
  const gridApi = useRef<GridApi<T> | null>(null);

  const getAllRowData = useCallback(() => {
    if (!gridApi.current) return [];
    const rowCount = gridApi.current.getDisplayedRowCount();
    const rows = [];
    for (let i = 0; i < rowCount; i++) {
      const rowNode = gridApi.current.getDisplayedRowAtIndex(i);
      if (rowNode && rowNode.data) {
        rows.push(rowNode.data);
      }
    }
    return rows;
  }, []);

  const handleDataChange = useCallback(() => {
    if (onValueChanged && gridApi.current) {
      const allRows = getAllRowData();
      onValueChanged(allRows);
    }
  }, [onValueChanged, getAllRowData]);

  const defaultColDef: ColDef<T> = useMemo(() => {
    return {
      sortable: true,
      filter: true,
      resizable: true,
      editable: true,
      minWidth: 100,
      maxWidth: 1000,
      suppressSizeToFit: true,
      suppressAutoSize: true,
      menuTabs: [],
      wrapText: false,
      autoHeight: true,
      cellStyle: {
        whiteSpace: 'normal',
        lineHeight: '1.5',
        display: 'flex',
        alignItems: 'center'
      },
      hide: false,
      field: undefined,
      valueSetter: (params: ValueSetterParams<T>) => {
        const field = params.colDef.field;
        if (!field || !params.data) return false;

        const data = params.data as Record<string, any>;
        data[field] = params.newValue;
        return true;
      },
      cellEditor: 'agTextCellEditor',
      valueFormatter: (params) => {
        if (params.colDef.type === 'date' && params.value) {
          return new Date(params.value).toLocaleDateString();
        }
        return params.value;
      }
    };
  }, []);

  useEffect(() => {
    const keywords = searchQuery ? searchQuery.toLowerCase().split(' ').filter(Boolean) : [];
    setGlobalFilter(keywords);
  }, [searchQuery]);

  const isExternalFilterPresent = useCallback(() => globalFilter.length > 0, [globalFilter]);

  const doesExternalFilterPass = useCallback((node: IRowNode<T>) => {
    const data = node.data;
    if (!data || !globalFilter.length) return !globalFilter.length;

    const searchableText = Object.values(data)
      .map(value => value ? String(value).toLowerCase() : '')
      .join(' ');
    return globalFilter.every(keyword => searchableText.includes(keyword));
  }, [globalFilter]);

  const onStateUpdated = useCallback((params: any) => {
    if (onColumnStateChanged && params.state && params.state.columns) {
      onColumnStateChanged(params.state.columns);
    }
  }, [onColumnStateChanged]);

  const onGridReady = useCallback((params: { api: GridApi<T> }) => {
    gridApi.current = params.api;
    params.api.addEventListener('selectionChanged', () => {
      setHasSelectedRows(params.api.getSelectedRows().length > 0);
    });
  }, []);

  const createDefaultRow = useCallback(() => {
    const newRow: Partial<T> = {};

    const tempId = Math.min(...data.map(item => Number(item.id)), 0) - 1;
    (newRow as any).id = tempId;

    columnDefs.forEach((colDef) => {
      if (colDef.field) {
        let defaultValue: any = null;

        switch (colDef.type) {
          case 'number':
            defaultValue = 0;
            break;
          case 'date':
            defaultValue = new Date().toISOString();
            break;
          case 'boolean':
            defaultValue = false;
            break;
          case 'select':
            defaultValue = colDef.cellEditorParams?.values?.[0] || '';
            break;
          default:
            defaultValue = '';
        }

        (newRow as any)[colDef.field] = defaultValue;
      }
    });

    return newRow as T;
  }, [data, columnDefs]);

  const handleAddRow = useCallback(() => {
    if (onAddRow) {
      onAddRow();
    } else if (gridApi.current) {
      const newRow = createDefaultRow();
      const transaction: RowDataTransaction<T> = {
        add: [newRow],
        addIndex: 0
      };
      gridApi.current.applyTransaction(transaction);
      handleDataChange();
    }
  }, [onAddRow, createDefaultRow, handleDataChange]);

  const handleRemoveRow = useCallback(() => {
    if (gridApi.current) {
      const selectedRows = gridApi.current.getSelectedRows();
      if (selectedRows.length > 0) {
        const rowCount = selectedRows.length;
        const confirmMessage = rowCount === 1
          ? 'Are you sure you want to delete this row?'
          : `Are you sure you want to delete ${rowCount} rows?`;

        if (window.confirm(confirmMessage)) {
          const transaction: RowDataTransaction<T> = {
            remove: selectedRows
          };
          gridApi.current.applyTransaction(transaction);
          handleDataChange();
        }
      }
    }
  }, [handleDataChange]);

  return (
    <div style={{ height: 'calc(100vh - 100px)', width: '100%' }} className="flex flex-col">
      {(canAddRows || canRemoveRows) && (
        <div className="flex items-center justify-between mb-2 px-2">
          <div className="flex items-center flex-1">
            {isSaving && (
              <span className="text-sm text-blue-600 dark:text-blue-400">
                Saving changes...
              </span>
            )}
            {message && (
              <span className={`text-sm ml-4 ${message.isError ? THEME_CLASSES.status.error : THEME_CLASSES.status.success}`}>
                {message.text}
              </span>
            )}
            {hasSelectedRows && (
              <span className="text-sm text-gray-600 dark:text-gray-400 ml-4">
                {`${gridApi.current?.getSelectedRows().length} ${gridApi.current?.getSelectedRows().length === 1 ? 'row' : 'rows'} selected`}
              </span>
            )}
          </div>
          <div className="flex gap-2">
            {canRemoveRows && (
              <button
                onClick={handleRemoveRow}
                disabled={!hasSelectedRows}
                className={`px-3 py-1.5 text-sm font-medium rounded-lg ${THEME_CLASSES.button.danger} disabled:opacity-50 disabled:cursor-not-allowed`}
              >
                Remove Row
              </button>
            )}
            {canAddRows && (
              <button
                onClick={handleAddRow}
                className={`px-3 py-1.5 text-sm font-medium rounded-lg ${THEME_CLASSES.button.primary}`}
              >
                Add Row
              </button>
            )}
          </div>
        </div>
      )}
      <div className="flex-1 w-full ag-theme-alpine dark:ag-theme-alpine-dark">
        <AgGridReact<T>
          columnDefs={columnDefs}
          rowData={data}
          defaultColDef={defaultColDef}
          suppressColumnMoveAnimation={true}
          rowDragManaged={enableRowDrag}
          animateRows={enableRowDrag}
          onRowDragEnd={handleDataChange}
          {...(enableRowDrag && {
            rowDragEntireRow: false,
            rowDragMultiRow: false
          })}
          columnTypes={{
            text: {
              filter: 'agTextColumnFilter',
              cellEditor: 'agTextCellEditor'
            },
            number: {
              filter: 'agNumberColumnFilter',
              cellEditor: 'agTextCellEditor'
            },
            date: {
              filter: 'agDateColumnFilter',
              cellEditor: 'agDateCellEditor',
              cellEditorParams: {
                browserDatePicker: true
              },
              valueFormatter: (params) => params.value ? new Date(params.value).toLocaleDateString() : ''
            },
            select: {
              filter: 'agTextColumnFilter',
              cellEditor: 'agSelectCellEditor'
            },
            textarea: {
              filter: 'agTextColumnFilter',
              cellEditor: 'agLargeTextCellEditor',
              cellEditorPopup: true,
              cellEditorParams: {
                maxLength: 1000,
                rows: 10,
                cols: 50
              },
              autoHeight: true,
              wrapText: true,
              cellStyle: { whiteSpace: 'normal', lineHeight: '1.5' }
            }
          }}
          onGridReady={onGridReady}
          onCellValueChanged={handleDataChange}
          getRowId={(params) => getRowId(params.data)}
          className="h-full w-full"
          onStateUpdated={onStateUpdated}
          isExternalFilterPresent={isExternalFilterPresent}
          doesExternalFilterPass={doesExternalFilterPass}
          gridOptions={gridOptions}
        />
      </div>
    </div>
  );
}

export default Grid;
