import { Contact } from '../../models/Contact';
import { request } from '../../services/dataService';
import { API_ENDPOINTS } from '../../constants/apiEndpoints';
import SchemaBasedDetails from '../common/SchemaBasedDetails';
import { useNotification } from '../../context/NotificationContext';
import { useTeams } from '../../context/TeamContext';
import { useSchemas } from '../../context/SchemaContext';

interface ContactDetailsProps {
  contact: Contact;
}

const ContactDetails: React.FC<ContactDetailsProps> = ({ contact }) => {
  const { showNotification } = useNotification();
  const { currentUserId } = useTeams();
  const { schemas, isLoading: schemasLoading } = useSchemas(1); // Type 1 for contacts
  if (schemasLoading) {
    return <div>Loading...</div>;
  }

  const filteredSchemas = schemas.filter(schema => 
    schema.teamId === contact.teamId || (!contact.teamId && schema.rlp)
  );

  const handleSubmit = async (data: any) => {
    try {
      await request(API_ENDPOINTS.PATCH_CONTACT, {
        id: contact.id,
        name: data.name || data.displayName || '',
        data: JSON.stringify(data),
      });
      showNotification('Contact updated successfully', 'success');
    } catch (error) {
      console.error('Error updating contact:', error);
      showNotification('Failed to update contact', 'error');
      throw error;
    }
  };

  const handleDelete = async () => {
    if (window.confirm('Are you sure you want to delete this contact? This action cannot be undone.')) {
      try {
        await request(API_ENDPOINTS.DELETE_CONTACT, { id: contact.id });
        showNotification('Contact deleted successfully', 'success');
        window.location.href = '/contacts';
      } catch (error) {
        console.error('Error deleting contact:', error);
        showNotification('Failed to delete contact', 'error');
      }
    }
  };

  const handleCancel = () => {
    if (window.confirm('Are you sure you want to cancel? Any unsaved changes will be lost.')) {
      window.location.reload();
    }
  };

  return (
    <SchemaBasedDetails
      schemas={filteredSchemas}
      data={contact}
      onSubmit={handleSubmit}
      onDelete={handleDelete}
      onCancel={handleCancel}
      isDeleteEnabled={contact.createdBy === currentUserId}
      isDisabled={(schema) => schema.teamId === 0}
    />
  );
};

export default ContactDetails;
