import { FC, useEffect, useRef, useState, useCallback } from 'react';
import { ColDef } from 'ag-grid-community';

interface ColumnSelectorProps<T> {
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
  columnDefs: ColDef<T>[];
  onColumnDefsChanged: (newDefs: ColDef<T>[]) => void;
}

function ColumnSelector<T>({ 
  isOpen,
  setIsOpen,
  columnDefs,
  onColumnDefsChanged
}: ColumnSelectorProps<T>): JSX.Element {
  const selectorRef = useRef<HTMLDivElement>(null);
  const [draggedItem, setDraggedItem] = useState<string | null>(null);
  const [dropTarget, setDropTarget] = useState<string | null>(null);
  const [orderedColumns, setOrderedColumns] = useState<ColDef<T>[]>([]);

  // Get a consistent identifier for a column
  const getColumnId = useCallback((col: ColDef<T>): string => {
    return col.colId || col.field || '';
  }, []);

  // Calculate visible fields count
  const visibleFields = columnDefs.filter(col => !col.hide).length;

  // Reset orderedColumns when selector opens or columnDefs change
  useEffect(() => {
    if (isOpen || columnDefs?.length) {
      // Create a deep copy of columnDefs to maintain order
      const columnsCopy = JSON.parse(JSON.stringify(columnDefs));
      setOrderedColumns(columnsCopy);
    } else if (!columnDefs?.length) {
      setOrderedColumns([]);
    }
  }, [isOpen, columnDefs]);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (selectorRef.current && !selectorRef.current.contains(event.target as Node)) {
        setIsOpen(false);
      }
    };
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [setIsOpen]);

  const handleColumnVisibilityChange = useCallback((columnId: string, visible: boolean) => {
    // Find the column by either colId or field
    const column = columnDefs.find(col => getColumnId(col) === columnId);

    // Check if this is the title column
    const isTitle = column && (getColumnId(column) === 'title');

    if (isTitle) return;

    try {
      // Create a new array with updated visibility
      const updatedDefs = columnDefs.map(def => {
        if (getColumnId(def) === columnId) {
          return {
            ...def,
            hide: !visible
          };
        }
        return def;
      });

      // Update local state first for immediate feedback
      setOrderedColumns(updatedDefs);

      // Call parent handler with updated definitions
      onColumnDefsChanged(updatedDefs);
    } catch (error) {
      console.error('ColumnSelector - Error updating column visibility:', error);
    }
  }, [columnDefs, onColumnDefsChanged, getColumnId]);

  const handleDragStart = (e: React.DragEvent<HTMLDivElement>, columnId: string) => {
    // Find the column by ID
    const column = columnDefs.find(col => getColumnId(col) === columnId);

    // Check if this is the title column
    const isTitle = column && getColumnId(column) === 'title';

    if (isTitle) {
      e.preventDefault();
      return;
    }

    if (e.dataTransfer.setDragImage) {
      const elem = e.currentTarget;
      e.dataTransfer.setDragImage(elem, 20, 20);
    }

    setDraggedItem(columnId);
    e.currentTarget.classList.add('opacity-50');
    e.dataTransfer.setData('text/plain', columnId);
    e.dataTransfer.effectAllowed = 'move';
  };

  const handleDragEnd = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    setDraggedItem(null);
    setDropTarget(null);
    document.querySelectorAll('.opacity-50').forEach(el => {
      el.classList.remove('opacity-50');
    });
  };

  const handleDragOver = (e: React.DragEvent<HTMLDivElement>, columnId: string) => {
    e.preventDefault();
    e.stopPropagation();

    // Find the column
    const column = columnDefs.find(col => getColumnId(col) === columnId);

    // Check if this is the title column or the currently dragged item
    const isTitle = column && getColumnId(column) === 'title';

    if (isTitle || columnId === draggedItem) return;

    setDropTarget(columnId);
    e.dataTransfer.dropEffect = 'move';
  };

  const handleDragEnter = (e: React.DragEvent<HTMLDivElement>, columnId: string) => {
    e.preventDefault();
    e.stopPropagation();

    // Find the column
    const column = columnDefs.find(col => getColumnId(col) === columnId);

    // Check if this is the title column
    const isTitle = column && getColumnId(column) === 'title';

    if (!isTitle && columnId !== draggedItem) {
      setDropTarget(columnId);
    }
  };

  const handleDragLeave = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.stopPropagation();
    if (!e.currentTarget.contains(e.relatedTarget as Node)) {
      setDropTarget(null);
    }
  };

  const handleDrop = (e: React.DragEvent<HTMLDivElement>, targetColumnId: string) => {
    e.preventDefault();
    e.stopPropagation();

    if (!draggedItem) {
      setDraggedItem(null);
      setDropTarget(null);
      return;
    }

    // Find the column by ID
    const targetColumn = columnDefs.find(col => getColumnId(col) === targetColumnId);

    // Check if this is the title column
    const isTitle = targetColumn && getColumnId(targetColumn) === 'title';

    if (isTitle || draggedItem === targetColumnId) {
      setDraggedItem(null);
      setDropTarget(null);
      return;
    }

    // Find columns indices
    const fromIndex = orderedColumns.findIndex(col => getColumnId(col) === draggedItem);
    const toIndex = orderedColumns.findIndex(col => getColumnId(col) === targetColumnId);

    if (fromIndex === -1 || toIndex === -1) {
      console.log('ColumnSelector - Could not find columns for drag operation:', {
        draggedItem,
        targetColumnId,
        fromIndex,
        toIndex
      });
      setDraggedItem(null);
      setDropTarget(null);
      return;
    }

    try {
      // Create a new array for the reordered columns
      const newColumnDefs = [...orderedColumns];
      const [movedColumn] = newColumnDefs.splice(fromIndex, 1);
      newColumnDefs.splice(toIndex, 0, movedColumn);

      // Ensure all column metadata is preserved
      const finalColumnDefs = newColumnDefs.map(col => {
        // Find the original column definition to preserve all properties
        const originalDef = columnDefs.find(origCol => getColumnId(origCol) === getColumnId(col));
        if (!originalDef) return col;

        return {
          ...originalDef,
          ...col,
          field: originalDef.field,
          colId: originalDef.colId || originalDef.field,
          pinned: col.field === 'title' ? 'left' as const : col.pinned
        };
      });

      // Update local state for immediate feedback
      setOrderedColumns(finalColumnDefs);

      // Call parent handler with reordered definitions
      onColumnDefsChanged(finalColumnDefs);
    } catch (error) {
      console.error('ColumnSelector - Error in column reordering:', error);
    }

    // Reset drag state
    setDraggedItem(null);
    setDropTarget(null);
  };

  return (
    <div className="relative shrink-0" ref={selectorRef}>
      <button
        onClick={() => setIsOpen(!isOpen)}
        className="inline-flex items-center px-2 py-1.5 rounded-md text-xs font-medium leading-6 bg-gray-100 dark:bg-gray-700 text-gray-500 dark:text-gray-400 hover:bg-gray-200 dark:hover:bg-gray-600 transition-colors"
        title="Configure columns visibility and order"
        aria-label="Toggle column selection"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className={`h-3.5 w-3.5 transition-transform ${isOpen ? 'rotate-180' : ''}`}
          viewBox="0 0 20 20"
          fill="currentColor"
        >
          <path
            fillRule="evenodd"
            d="M5.293 7.293a1 1 0 0 1 1.414 0L10 10.586l3.293-3.293a1 1 0 0 1 1.414 1.414l-4 4a1 1 0 0 1-1.414 0l-4-4a1 1 0 0 1 0-1.414z"
            clipRule="evenodd"
          />
        </svg>
        <span className="ml-1.5">Columns ({visibleFields}/{columnDefs.length})</span>
      </button>

      {isOpen && (
        <div className="absolute z-10 mt-1 w-56 origin-top-right bg-white dark:bg-gray-800 border border-gray-200 dark:border-gray-700 rounded-md shadow-lg">
          <div className="p-2 space-y-1 divide-y divide-gray-200 dark:divide-gray-700">
            <div className="flex items-center justify-between pb-2">
              <button
                onClick={() => {
                  try {
                    // Create new column definitions with all columns visible
                    const newState = orderedColumns.map(col => {
                      const isTitle = getColumnId(col) === 'title';
                      return {
                        ...col,
                        hide: false,
                        pinned: isTitle ? 'left' as const : undefined
                      };
                    });

                    // Update the local ordered columns for immediate feedback
                    setOrderedColumns(newState);

                    // Update the grid state
                    onColumnDefsChanged(newState);
                  } catch (error) {
                    console.error('ColumnSelector - Error in Show All:', error);
                  }
                }}
                className="text-xs text-blue-600 dark:text-blue-400 hover:text-blue-800 dark:hover:text-blue-300"
              >
                Show All
              </button>
              <button
                onClick={() => {
                  try {
                    // Create new column definitions with default settings
                    const newState = orderedColumns.map((col) => {
                      const isTitle = getColumnId(col) === 'title';
                      return {
                        ...col,
                        hide: !isTitle,
                        width: isTitle ? 300 :
                          col.type === 'date' ? 150 :
                            col.type === 'number' ? 120 : 180,
                        pinned: isTitle ? 'left' as const : undefined
                      };
                    });

                    // Update the local ordered columns for immediate feedback
                    setOrderedColumns(newState);

                    // Update the grid state
                    onColumnDefsChanged(newState);
                  } catch (error) {
                    console.error('ColumnSelector - Error in Reset:', error);
                  }
                }}
                className="text-xs text-blue-600 dark:text-blue-400 hover:text-blue-800 dark:hover:text-blue-300"
              >
                Reset
              </button>
            </div>

            <div className="pt-2 space-y-1 max-h-64 overflow-y-auto">
              {orderedColumns.map((column, index) => {
                const isVisible = !column.hide;
                const columnId = getColumnId(column);

                // Check if this is the title column
                const isTitle = columnId === 'title';
                const isDragDisabled = isTitle;

                return (
                  <div
                    key={columnId || `column-${index}`}
                    draggable={!isDragDisabled}
                    onDragStart={(e) => handleDragStart(e, columnId)}
                    onDragEnd={handleDragEnd}
                    onDragOver={(e) => handleDragOver(e, columnId)}
                    onDragEnter={(e) => handleDragEnter(e, columnId)}
                    onDragLeave={handleDragLeave}
                    onDrop={(e) => handleDrop(e, columnId)}
                    className={`flex items-center px-2 py-1 rounded select-none ${!isDragDisabled ? 'cursor-move hover:bg-gray-100 dark:hover:bg-gray-700' : ''
                      } ${dropTarget === columnId ? 'bg-blue-50 dark:bg-blue-900 border border-blue-200 dark:border-blue-700' : ''
                      } ${draggedItem === columnId ? 'opacity-50' : ''}`}
                  >
                    <div
                      className="drag-handle cursor-grab active:cursor-grabbing"
                      title={isDragDisabled ? "This column cannot be moved" : "Drag to reorder"}
                    >
                      <svg
                        className={`w-4 h-4 mr-2 ${isDragDisabled
                            ? 'text-gray-300 dark:text-gray-600'
                            : 'text-gray-500 dark:text-gray-400'
                          }`}
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 20 20"
                        fill="currentColor"
                      >
                        <path d="M7 2a2 2 0 1 0 .001 4.001A2 2 0 0 0 7 2zm0 6a2 2 0 1 0 .001 4.001A2 2 0 0 0 7 8zm0 6a2 2 0 1 0 .001 4.001A2 2 0 0 0 7 14zM13 2a2 2 0 1 0 .001 4.001A2 2 0 0 0 13 2zm0 6a2 2 0 1 0 .001 4.001A2 2 0 0 0 13 8zm0 6a2 2 0 1 0 .001 4.001A2 2 0 0 0 13 14z" />
                      </svg>
                    </div>
                    <label className="flex items-center flex-1">
                      <input
                        type="checkbox"
                        checked={isVisible}
                        disabled={isTitle}
                        onChange={(e) => handleColumnVisibilityChange(columnId, e.target.checked)}
                        className="mr-2"
                      />
                      <span className="text-sm text-gray-700 dark:text-gray-200">{column.headerName || columnId}</span>
                    </label>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default ColumnSelector;
