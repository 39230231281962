import { FC, useEffect, useRef } from 'react';
import { Pipeline } from '../../models/Pipeline';
import { THEME_CLASSES } from '../../constants/themeConstants';

interface StageSelectorProps {
  pipeline: Pipeline;
  visibleStages: string[];
  setVisibleStages: (stages: string[]) => void;
  isOpen: boolean;
  setIsStageSelectionOpen: (isOpen: boolean) => void;
}

const StageSelector: FC<StageSelectorProps> = ({
  pipeline,
  visibleStages,
  setVisibleStages,
  isOpen,
  setIsStageSelectionOpen
}) => {
  const selectorRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (selectorRef.current && !selectorRef.current.contains(event.target as Node)) {
        setIsStageSelectionOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [setIsStageSelectionOpen]);

  return (
    <div className="relative shrink-0" ref={selectorRef}>
      <button
        onClick={() => setIsStageSelectionOpen(!isOpen)}
        className="inline-flex items-center px-2 py-1.5 rounded-md text-xs font-medium leading-6 bg-gray-100 dark:bg-gray-700 text-gray-500 dark:text-gray-400 hover:bg-gray-200 dark:hover:bg-gray-600 transition-colors"
        title="Toggle stage selection"
        aria-label="Toggle stage selection"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className={`h-3.5 w-3.5 transition-transform ${isOpen ? 'rotate-180' : ''}`}
          viewBox="0 0 20 20"
          fill="currentColor"
        >
          <path
            fillRule="evenodd"
            d="M5.293 7.293a1 1 0 0 1 1.414 0L10 10.586l3.293-3.293a1 1 0 0 1 1.414 1.414l-4 4a1 1 0 0 1-1.414 0l-4-4a1 1 0 0 1 0-1.414z"
            clipRule="evenodd"
          />
        </svg>
        <span className="ml-1.5">Stages ({visibleStages.length}/{pipeline.stages?.length || 0})</span>
      </button>
      {isOpen && (
        <div className="absolute z-10 mt-1 w-56 origin-top-right bg-white dark:bg-gray-800 border border-gray-200 dark:border-gray-700 rounded-md shadow-lg">
          <div className="p-2 space-y-1">
            {pipeline.stages?.map((stage) => (
              <label key={stage.id} className="flex items-center px-2 py-1 rounded hover:bg-gray-100 dark:hover:bg-gray-700">
                <input
                  type="checkbox"
                  checked={visibleStages.includes(stage.id.toString())}
                  onChange={(e) => {
                    if (e.target.checked) {
                      setVisibleStages([...visibleStages, stage.id.toString()]);
                    } else {
                      setVisibleStages(visibleStages.filter(id => id !== stage.id.toString()));
                    }
                  }}
                  className="mr-2"
                />
                <span className="text-sm text-gray-700 dark:text-gray-200">{stage.name}</span>
              </label>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

export type { StageSelectorProps };
export default StageSelector;