import { Account } from '../models/Account';
import { ColDef, SortDirection } from 'ag-grid-community';
import { createHighlightCellRenderer, createNameCellRenderer } from './gridCellRenderers';
import { type FormField } from '../components/common/FormField';
import { filterBySearchTerms } from './searchUtils';

export interface AccountField {
  key: keyof Account;
  label: string;
  hideEmpty?: boolean;
}

export const getAccountDisplayFields = (): AccountField[] => [
  { key: 'streetAddress', label: 'Street', hideEmpty: true },
  { key: 'city', label: 'City', hideEmpty: true },
  { key: 'province', label: 'Province', hideEmpty: true },
  { key: 'postalCode', label: 'PostalCode', hideEmpty: true },
  { key: 'phone', label: 'Phone', hideEmpty: true },
  { key: 'noteCount', label: 'Notes' },
];


export const getAccountColumnDefs = (searchQuery: string, schemaFields?: FormField[]): ColDef<Account>[] => {
  // Base columns that are always present
  const baseColumns = [
    {
      field: 'name',
      headerName: 'Name',
      sortable: true,
      width: 400,
      pinned: true,
      cellRenderer: createNameCellRenderer(searchQuery)
    },
    {
      field: 'noteCount',
      headerName: 'Note Count',
      sortable: true,
      sort: 'desc' as SortDirection,
      type: 'number'
    }
  ];

  if (!schemaFields || !schemaFields.length) return baseColumns;

  // Add schema-based columns
  const additionalColumns = schemaFields.map(field => ({
    field: field.name,
    headerName: field.label,
    sortable: true,
    cellRenderer: createHighlightCellRenderer(searchQuery)
  }));

  return [...baseColumns, ...additionalColumns];
};

export const filterAccounts = (
  accounts: Account[],
  searchQuery: string,
  searchFields?: string[]
) => {
  return filterBySearchTerms(accounts, searchQuery, searchFields as Array<keyof Account>);
};
