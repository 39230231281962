import contactRoutes from "./contacts";
import pipelineRoutes from "./pipelines";
import noteRoutes from "./notes";
import accountRoutes from "./accounts";
import leadRoutes from "./leads";
import newsRoutes from "./news";
import usersRoutes from "./users";
import teamsRoutes from "./teams";
import schemaRoutes from "./schema";

import { DataRoutes } from "./types";

const routes: DataRoutes = {
  ...contactRoutes,
  ...pipelineRoutes,
  ...noteRoutes,
  ...accountRoutes,
  ...leadRoutes,
  ...newsRoutes,
  ...usersRoutes,
  ...teamsRoutes,
  ...schemaRoutes
};

export default routes;
