import { FC } from 'react';
import { Opportunity } from '../../models/Opportunity';
import { THEME_CLASSES } from '../../constants/themeConstants';
import { FormField } from '../common/FormField';
import { highlightText } from '../../utils/highlightUtils';

interface OpportunityCardProps {
  opportunity: Opportunity;
  onDragStart: (id: number) => void;
  onDragOver: (e: React.DragEvent<HTMLDivElement>, stageId: number) => void;
  onDrop: (stageId: number) => void;
  setEditingOpportunity: (opportunity: Opportunity) => void;
  draggingId: number | null;
  fields: FormField[];
  keywords?: string[];
}

const OpportunityCard: FC<OpportunityCardProps> = ({
  opportunity,
  onDragStart,
  onDragOver,
  onDrop,
  setEditingOpportunity,
  draggingId,
  fields,
  keywords = []
}) => {
  return (
    <div
      draggable
      onDragStart={() => opportunity.id && onDragStart(opportunity.id)}
      onDragOver={(e) => opportunity.stageId && onDragOver(e, opportunity.stageId)}
      onDrop={() => opportunity.stageId && onDrop(opportunity.stageId)}
      className={`p-3 rounded-lg shadow-lg border-2 ${THEME_CLASSES.note.card.background.gray} border-green-500 dark:border-green-600 ${THEME_CLASSES.card.hover.transform} ${THEME_CLASSES.card.hover.shadow} ${THEME_CLASSES.card.hover.border} cursor-move relative ${draggingId === opportunity.id ? 'opacity-0' : ''}`}
    >
      {/* Action buttons */}
      <div className="absolute top-1 right-1 flex gap-1">
        {/* Calendar integration */}
        <button
          onClick={(e) => {
            e.stopPropagation();
            const eventTitle = `RE: ${opportunity.title}`;
            const calendarUrl = `https://calendar.google.com/calendar/u/0/r/eventedit?text=${encodeURIComponent(eventTitle)}`;
            window.open(calendarUrl, '_blank');
          }}
          className="p-1 text-gray-500 hover:text-blue-500"
          title="Add to Google Calendar"
        >
          <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4" viewBox="0 0 24 24" fill="none" stroke="currentColor">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M8 7V3m8 4V3m-9 8h10M5 21h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v12a2 2 0 002 2z" />
          </svg>
        </button>

        {/* Email integration */}
        <button
          onClick={(e) => {
            e.stopPropagation();
            const subject = `RE: ${opportunity.title}`;
            const gmailUrl = `https://mail.google.com/mail/?view=cm&fs=1&su=${encodeURIComponent(subject)}`;
            window.open(gmailUrl, '_blank');
          }}
          className="p-1 text-gray-500 hover:text-blue-500"
          title="Compose Gmail"
        >
          <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4" viewBox="0 0 24 24" fill="none" stroke="currentColor">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M3 8l7.89 5.26a2 2 0 002.22 0L21 8M5 19h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z" />
          </svg>
        </button>

        {/* Edit opportunity */}
        <button
          onClick={(e) => {
            e.stopPropagation();
            setEditingOpportunity(opportunity);
          }}
          className="p-1 text-gray-500 hover:text-green-500"
          title="Edit Opportunity"
        >
          <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4" viewBox="0 0 20 20" fill="currentColor">
            <path d="M13.586 3.586a2 2 0 112.828 2.828l-.793.793-2.828-2.828.793-.793zM11.379 5.793L3 14.172V17h2.828l8.38-8.379-2.83-2.828z" />
          </svg>
        </button>
        {/* Notes button */}
        <button
          title="View Notes"
          onClick={(e) => {
            e.stopPropagation();
            if (opportunity.contactId && opportunity.contactId > 0) {
              window.location.href = `/contacts/${opportunity.contactId}/notes`;
            } else if (opportunity.accountId && opportunity.accountId > 0) {
              window.location.href = `/accounts/${opportunity.accountId}/notes`;
            }
          }}
          className="p-1 text-gray-500 hover:text-blue-500"
        >
          <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4" viewBox="0 0 20 20" fill="currentColor">
            <path fillRule="evenodd" d="M4 4a2 2 0 012-2h4.586A2 2 0 0112 2.586L15.414 6A2 2 0 0116 7.414V16a2 2 0 01-2 2H6a2 2 0 01-2-2V4zm2 6a1 1 0 011-1h6a1 1 0 110 2H7a1 1 0 01-1-1zm1 3a1 1 0 100 2h6a1 1 0 100-2H7z" clipRule="evenodd" />
          </svg>
        </button>
      </div>

      <div className='mt-4'>
        {(() => {
          // Get all non-empty fields to display
          const fieldsToShow = fields
            .filter(field => {
              const value = opportunity[field.name];
              return value !== undefined && value !== '';
            });

          return (
            <>
              {/* Opportunity title */}
              <p className="text-xs font-medium text-gray-900 dark:text-white">
                {highlightText(opportunity.title || '', keywords)}
              </p>
              {/* Field values grid */}
              <div className="mt-1 grid grid-cols-2 gap-x-2 gap-y-1">
                {/* Render each field with label and formatted value */}
                {fieldsToShow.map(field => {
                  if (field.name === 'title' || field.name === 'value') return null;
                  const value = opportunity[field.name];
                  const displayValue = (() => {
                    if (value === undefined || value === '') return '';
                    if (field.type === 'number') {
                      if (field.name === 'probability') return highlightText(`${value}%`, keywords);
                      if (field.name === 'value') return highlightText(`$${Number(value).toLocaleString()}`, keywords);
                      return highlightText(Number(value).toLocaleString(), keywords);
                    }
                    if (field.type === 'date') {
                      return highlightText(new Date(value).toLocaleDateString('en-US', {
                        year: 'numeric',
                        month: 'short',
                        day: 'numeric'
                      }), keywords);
                    }
                    if (field.type === 'select' && field.options) {
                      const option = field.options.find(opt => opt.value === value);
                      return highlightText(option ? option.label : value.toString(), keywords);
                    }
                    return highlightText(value.toString(), keywords);
                  })();

                  return (
                    <div key={field.name} className="contents">
                      <p className="text-[0.7rem] text-gray-500 dark:text-gray-400 text-left">
                        {field.label || field.name.replace(/([A-Z])/g, ' $1').replace(/^./, str => str.toUpperCase())}:
                      </p>
                      <p className="text-[0.7rem] text-gray-500 dark:text-gray-400 text-right">
                        {displayValue}
                      </p>
                    </div>
                  );
                })}
              </div>
            </>
          );
        })()}
      </div>
    </div>
  );
};

export default OpportunityCard;
