import { FC, useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { request } from '../services/dataService';
import { API_ENDPOINTS } from '../constants/apiEndpoints';
import { THEME_CLASSES } from '../constants/themeConstants';
import { renderTypeChip } from '../utils/typeChipUtils';
import { useEventBus } from '../context/EventBusContext';
import { useNotification } from '../context/NotificationContext';
import { useTeams } from '../context/TeamContext';
import { SchemaProvider } from '../context/SchemaContext';
import { Account } from '../models/Account';
import { getNotesSummaryPrompt } from '../utils/summaryUtils';
import { buildGoogleCalendarUrl, buildGmailUrl } from '../utils/googleUtils';
import AccountDetails from '../components/account/AccountDetails';
import ContactNotes from '../components/note/AccountNotes';
import Spinner from '../components/common/Spinner';

const AccountPage: FC = () => {
  const { id, tab, noteId } = useParams();
  const navigate = useNavigate();
  const [account, setAccount] = useState<Account | null>(null);
  const [activeTab, setActiveTab] = useState(() =>
    tab === 'notes' ? 'notes' : 'overview'
  );
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  const { publish } = useEventBus();
  const { currentUserId } = useTeams();
  const { showNotification } = useNotification();

  const handleSummarize = async () => {
    if (!account) return;
    const notes = await request(API_ENDPOINTS.GET_ACCOUNT_NOTES, { id: account.id });
    if (!notes || !notes.length) return;
    const prompt = getNotesSummaryPrompt(notes);
    publish('chat:open', prompt);
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (!id) return;
        const accountId = parseInt(id);
        const accountData = await request(API_ENDPOINTS.GET_ACCOUNT, { id: accountId });
        setAccount(accountData);

        setError(null);
      } catch (err) {
        setError('Failed to load account details');
        console.error('Error fetching account:', err);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [id]);

  const handleTabChange = (newTab: string) => {
    setActiveTab(newTab);
    navigate(`/accounts/${id}/${newTab}`);
  };

  if (loading) {
    return (
      <div className={`p-6 ${THEME_CLASSES.text.primary}`}>
        <div className="flex items-center justify-center h-64">
          <Spinner />
        </div>
      </div>
    );
  }

  if (error) {
    return (
      <div className={`p-6 ${THEME_CLASSES.text.primary}`}>
        <div className="flex items-center justify-center h-64">
          <div className="text-red-500">{error}</div>
        </div>
      </div>
    );
  }

  if (!account) {
    return (
      <div className={`p-6 ${THEME_CLASSES.text.primary}`}>
        <div className="flex items-center justify-center h-64">
          <div>Account not found</div>
        </div>
      </div>
    );
  }


  return (
    <div className="h-full flex flex-col p-6">
      {/* Header section with account name */}
      <h1 className={`text-xl font-semibold ${THEME_CLASSES.text.primary} flex items-center gap-2`}>
        {account.name}
        {renderTypeChip({ rlpCompanyId: account.rlpCompanyId, teamId: account.teamId })}
      </h1>

      {/* Navigation and actions row */}
      <div className="flex items-center justify-between mb-6">
        <nav className="flex space-x-4 items-center" aria-label="Tabs">
          <button
            onClick={() => handleTabChange('overview')}
            className={`rounded-full py-1 px-4 text-sm font-medium ${activeTab === 'overview'
              ? `${THEME_CLASSES.button.primary} font-bold`
              : `${THEME_CLASSES.button.secondary} ${THEME_CLASSES.hover.background}`
              }`}
          >
            Overview
          </button>
          <button
            onClick={() => handleTabChange('notes')}
            className={`rounded-full py-1 px-4 text-sm font-medium ${activeTab === 'notes'
              ? `${THEME_CLASSES.button.primary} font-bold`
              : `${THEME_CLASSES.button.secondary} ${THEME_CLASSES.hover.background}`
              }`}
          >
            Contacts and Notes
          </button>
        </nav>
        {/* Action buttons */}
        <div className="flex items-center gap-2">
          <div className="flex space-x-2">
            <button
              onClick={async () => {
                try {
                  await request(API_ENDPOINTS.POST_USER_ACCOUNT, {
                    userId: currentUserId,
                    id: account.id
                  });
                  showNotification(`${account.name} saved to your account list!`, 'success');
                } catch (error) {
                  console.error('Error saving account:', error);
                  showNotification('Failed to save account', 'error');
                }
              }}
              className={`p-2 rounded-md ${THEME_CLASSES.text.secondary} ${THEME_CLASSES.hover.background}`}
              title="Save to your accounts"
            >
              <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M18 9v3m0 0v3m0-3h3m-3 0h-3m-2-5a4 4 0 11-8 0 4 4 0 018 0zM3 20a6 6 0 0112 0v1H3v-1z" />
              </svg>
            </button>
          </div>

          <button
            onClick={handleSummarize}
            className={`p-2 rounded-md ${THEME_CLASSES.text.secondary} ${THEME_CLASSES.hover.background}`}
            title="Ask AI about this account"
          >
            <svg className="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M8 12h.01M12 12h.01M16 12h.01M21 12c0 4.418-4.03 8-9 8a9.863 9.863 0 01-4.255-.949L3 20l1.395-3.72C3.512 15.042 3 13.574 3 12c0-4.418 4.03-8 9-8s9 3.582 9 8z" />
            </svg>
          </button>

          <button
            onClick={() => {
              const eventTitle = `RE: ${account.name}`;
              const calendarUrl = buildGoogleCalendarUrl(eventTitle, '');
              window.open(calendarUrl, '_blank');
            }}
            className={`p-2 rounded-md ${THEME_CLASSES.text.secondary} ${THEME_CLASSES.hover.background}`}
            title="Add to Google Calendar"
          >
            <svg className="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M8 7V3m8 4V3m-9 8h10M5 21h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v12a2 2 0 002 2z" />
            </svg>
          </button>

          <button
            onClick={() => {
              const subject = `RE: ${account.name}`;
              const gmailUrl = buildGmailUrl(subject, '');
              window.open(gmailUrl, '_blank');
            }}
            className={`p-2 rounded-md ${THEME_CLASSES.text.secondary} ${THEME_CLASSES.hover.background}`}
            title="Compose Gmail"
          >
            <svg className="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M3 8l7.89 5.26a2 2 0 002.22 0L21 8M5 19h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z" />
            </svg>
          </button>
        </div>
      </div>

      {/* Content */}
      <div className="flex-1">
        {activeTab === 'overview' && (
          <div className="grid grid-cols-1 gap-6">
            <SchemaProvider>
              <AccountDetails
                account={account}
              />
            </SchemaProvider>
          </div>
        )}

        {activeTab === 'notes' && (
          <div className="h-[calc(100vh-160px)]">
            <ContactNotes
              key={`contact-notes-${account.id}`}
              accountId={parseInt(id!)}
              accountName={account.name}
              initialNoteId={noteId ? parseInt(noteId) : undefined}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default AccountPage;
