import React, { FC, useState, useRef, useEffect } from 'react';
import { FixedSizeList as List } from 'react-window';
import { THEME_CLASSES } from '../../constants/themeConstants';
import { highlightText } from '../../utils/highlightUtils';

export interface ListItem {
  id: number;
  displayName: string;
  icon?: JSX.Element;
  noteCount: number;
  isSpecialItem?: boolean; // For items like "All Notes" or "Account Notes"
  isAccountLevel?: boolean; // To maintain compatibility with existing code
  onClick?: () => void;
}

interface VirtualizedListProps {
  items: ListItem[];
  selectedId: number | null;
  onSelect: (id: number) => void;
  onItemClick?: (item: ListItem) => void;
  searchQuery?: string | string[];
  containerClassName?: string;
}

interface RowData {
  items: ListItem[];
  selectedId: number | null;
  onSelect: (id: number) => void;
  onItemClick?: (item: ListItem) => void;
  searchQuery?: string | string[];
}

const VirtualizedList: FC<VirtualizedListProps> = ({
  items,
  selectedId,
  onSelect,
  onItemClick,
  searchQuery,
  containerClassName = "h-full"
}) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const [containerHeight, setContainerHeight] = useState(0);

  useEffect(() => {
    if (containerRef.current) {
      const resizeObserver = new ResizeObserver((entries) => {
        for (const entry of entries) {
          setContainerHeight(entry.contentRect.height);
        }
      });

      resizeObserver.observe(containerRef.current);
      return () => resizeObserver.disconnect();
    }
  }, []);

  const Row: FC<{
    index: number;
    style: React.CSSProperties;
    data: RowData;
  }> = ({ index, style, data }) => {
    const { items, selectedId, onSelect, onItemClick } = data;
    const item = items[index];

    return (
      <div style={style} className="px-2">
        <div
          onClick={() => {
            onSelect(item.id);
            if (onItemClick) {
              onItemClick(item);
            }
          }}
          className={`group flex items-center h-10 px-3 rounded-lg transition-colors duration-150 cursor-pointer
            ${selectedId === item.id
              ? 'bg-blue-600 dark:bg-blue-700 text-white dark:text-white'
              : `${THEME_CLASSES.text.secondary} ${THEME_CLASSES.hover.background} ${THEME_CLASSES.hover.text} ${item.noteCount === 0 ? 'opacity-50' : ''}`}`}
        >
          <div className="flex items-center justify-between w-full">
            <div className="flex items-center gap-2">
              <div 
                className={`w-5 h-5 flex items-center justify-center cursor-pointer 
                  ${selectedId === item.id ? 'text-white dark:text-white' : 'text-gray-400 dark:text-gray-500'}`}
                onClick={(e) => {
                  if (item.onClick) {
                    e.stopPropagation();
                    item.onClick();
                  }
                }}
              >
                {item.icon}
              </div>
            </div>
            <span className="text-sm font-medium ml-2">
              {data.searchQuery
                ? highlightText(
                    item.displayName,
                    Array.isArray(data.searchQuery)
                      ? data.searchQuery
                      : data.searchQuery.split(/\s+/).filter((term: string) => term.length > 0)
                  )
                : item.displayName}
            </span>
            <span className={`text-xs ${selectedId === item.id ? 'text-white/90 dark:text-white/90' : THEME_CLASSES.text.secondary} ml-auto`}>
              {item.noteCount}
            </span>
          </div>
        </div>
      </div>
    );
  };

  const itemSize = 48; // 40px height + 8px margin

  return (
    <div ref={containerRef} className={containerClassName}>
      {containerHeight > 0 && (
        <List
          height={containerHeight}
          width="100%"
          itemCount={items.length}
          itemSize={itemSize}
          itemData={{
            items,
            selectedId,
            onSelect,
            onItemClick,
            searchQuery
          }}
        >
          {Row}
        </List>
      )}
    </div>
  );
};

export default VirtualizedList;
