import { FC, useState } from 'react';
import { FormField } from './FormField';
import { FieldOptions as OPTIONS } from '../../models/Schema';
import { THEME_CLASSES } from '../../constants/themeConstants';
import { useNotification } from '../../context/NotificationContext';

interface DynamicFormProps {
  fields: ReadonlyArray<FormField>;
  initialData?: Record<string, any>;
  onSubmit: (data: Record<string, any>) => Promise<void>;
  onCancel?: (wasUpdated: boolean) => void;
  onDelete?: () => Promise<void>;
  submitLabel?: string;
  cancelLabel?: string;
  disabled?: boolean;
  canDelete?: boolean;
}

const DynamicForm: FC<DynamicFormProps> = ({
  fields,
  initialData = {},
  onSubmit,
  onCancel,
  onDelete,
  submitLabel = 'Submit',
  cancelLabel = 'Cancel',
  disabled = false,
  canDelete = false
}) => {
  const [error, setError] = useState<string | null>(null);
  const [loading, setLoading] = useState(false);
  const [fieldErrors, setFieldErrors] = useState<Record<string, string>>({});
  const { showNotification } = useNotification();

  const getFieldValue = (field: FormField): string => {
    const value = initialData[field.name];
    if (value === undefined || value === null) return '';

    if (field.type === 'date' && value) {
      return new Date(value).toISOString().split('T')[0];
    }

    return String(value);
  };

  const getBooleanValue = (field: FormField): boolean => {
    const value = initialData[field.name];
    return value === true || value === 'true';
  };

  const isFieldRequired = (field: FormField): boolean => {
    const requiredValue = String(field.required).toLowerCase();
    return requiredValue === 'true' || requiredValue === 'yes';
  };

  const validateForm = (formData: FormData): boolean => {
    const errors: Record<string, string> = {};

    fields.forEach(field => {
      const value = formData.get(field.name) as string;
      if (isFieldRequired(field) && (!value || value.trim() === '')) {
        errors[field.name] = 'This field is required';
      } else if (field.minLength && value.length < field.minLength) {
        errors[field.name] = `Minimum length is ${field.minLength} characters`;
      } else if (field.maxLength && value.length > field.maxLength) {
        errors[field.name] = `Maximum length is ${field.maxLength} characters`;
      } else if (field.type === 'number') {
        const numValue = Number(value);
        if (field.min !== undefined && numValue < field.min) {
          errors[field.name] = `Minimum value is ${field.min}`;
        }
        if (field.max !== undefined && numValue > field.max) {
          errors[field.name] = `Maximum value is ${field.max}`;
        }
      }
    });

    setFieldErrors(errors);
    return Object.keys(errors).length === 0;
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setError(null);
    const formData = new FormData(e.target as HTMLFormElement);

    if (!validateForm(formData)) {
      return;
    }

    setLoading(true);

    const formattedData = Object.fromEntries(
      fields.map(field => [
        field.name,
        field.type === 'date'
          ? (formData.get(field.name) as string || '').trim()
            ? new Date(formData.get(field.name) as string).toISOString()
            : null
          : field.type === 'number'
            ? Number(formData.get(field.name))
            : field.type === 'boolean'
              ? formData.get(field.name) === 'on'
              : formData.get(field.name)
      ])
    );
    try {
      await onSubmit(formattedData);
      showNotification('Form submitted successfully', 'success');
    } catch (err) {
      setError('Failed to submit form: ' + (err as Error).message);
      console.error('Error submitting form:', err);
    } finally {
      setLoading(false);
    }
  };

  const renderField = (field: FormField) => {
    const commonClasses = `w-full border ${THEME_CLASSES.input.border} ${THEME_CLASSES.input.background} ${THEME_CLASSES.input.text} ${THEME_CLASSES.input.placeholder} rounded-md px-3 py-1 text-xs ${fieldErrors[field.name] ? 'border-red-500' : ''
      }`;

    if (field.type === 'boolean') {
      const boolValue = getBooleanValue(field);
      return (
        <input
          type="checkbox"
          name={field.name}
          defaultChecked={boolValue}
          className={`h-4 w-4 rounded border-gray-300 ${THEME_CLASSES.input.border} ${THEME_CLASSES.input.background} disabled:opacity-50`}
          disabled={disabled}
        />
      );
    }

    if (field.type === 'textarea') {
      return (
        <textarea
          name={field.name}
          defaultValue={getFieldValue(field)}
          className={commonClasses}
          required={isFieldRequired(field)}
          placeholder={field.placeholder}
          rows={4}
          minLength={field.minLength}
          maxLength={field.maxLength}
          disabled={disabled}
        />
      );
    }

    if (field.type === 'select') {
      const options = field.optionName ? OPTIONS[field.optionName as keyof typeof OPTIONS] : field.options;

      return (
        <select
          name={field.name}
          defaultValue={getFieldValue(field)}
          className={commonClasses}
          required={isFieldRequired(field)}
          disabled={disabled}
        >
          {field.placeholder && (
            <option value="" disabled>
              {field.placeholder}
            </option>
          )}
          {options?.map((option) => (
            <option key={option.value} value={option.value}>
              {option.label}
            </option>
          ))}
        </select>
      );
    }

    return (
      <input
        name={field.name}
        type={field.type}
        defaultValue={getFieldValue(field)}
        className={commonClasses}
        required={isFieldRequired(field)}
        placeholder={field.placeholder}
        min={field.min}
        max={field.max}
        minLength={field.minLength}
        maxLength={field.maxLength}
        disabled={disabled}
      />
    );
  };

  return (
    <form onSubmit={handleSubmit} noValidate className="flex flex-col h-full">
      <div className="grid grid-cols-2 gap-4 overflow-y-auto max-h-[calc(100vh-300px)] p-4">
        {fields.map((field) => (
          <div key={field.name} className="space-y-1">
            <label className="block text-sm font-medium text-gray-700 dark:text-gray-300">
              {field.label || field.name.replace(/([A-Z])/g, ' $1').replace(/^./, str => str.toUpperCase())}
              {isFieldRequired(field) && <span className="text-red-500 ml-1">*</span>}
            </label>
            {renderField(field)}
            {fieldErrors[field.name] && (
              <p className="text-red-500 text-xs mt-1">{fieldErrors[field.name]}</p>
            )}
          </div>
        ))}
      </div>

      <div className="flex justify-between items-center mt-4 sticky bottom-0 p-4 border-t dark:border-gray-700 bg-white dark:bg-gray-800">
        <div>
          {error && (
            <p className="text-sm text-red-500">{error}</p>
          )}
        </div>
        <div className="flex space-x-2">
          {onCancel && (
            <button
              type="button"
              onClick={() => onCancel?.(false)}
              className={`px-4 py-2 text-sm font-medium rounded-md ${THEME_CLASSES.button.secondary}`}
            >
              {cancelLabel}
            </button>
          )}
          {!disabled && (
            <button
              type="submit"
              className={`px-4 py-2 text-sm font-medium rounded-md ${THEME_CLASSES.button.primary}`}
            >
              {loading ? (
                <div className="animate-spin rounded-full h-4 w-4 border-b-2 border-white" />
              ) : submitLabel}
            </button>
          )}
          {canDelete && !disabled && (
            <button
              type="button"
              onClick={onDelete}
              className={`p-2 text-sm font-medium rounded-md ${THEME_CLASSES.button.secondary}`}
              aria-label="Delete"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-4 w-4"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16"
                />
              </svg>
            </button>
          )}
        </div>
      </div>
    </form>
  );
};

export default DynamicForm;
