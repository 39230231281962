import { z } from "zod";
import { DataRoutes } from "./types";

const leadRoutes: DataRoutes = {
  // Get list of all leads with basic information
  'get /api/data/leads': {
    query: `SELECT [ID] AS id,
      [Description] AS description,
      [origination] AS origination
    FROM [dbo].[Leads]`
  },

  // Get details of a specific lead by ID
  'get /api/data/leads/:id': {
    schema: z.object({
      id: z.number().int()
    }),
    queryOne: `SELECT [ID] AS id,
      [Description] AS description,
      [origination] AS origination
    FROM [dbo].[Leads]
    WHERE ID = @id`
  },

  // Update a specific lead by ID
  'patch /api/data/leads/:id': {
    schema: z.object({
      id: z.number().int(),
      description: z.string().optional(),
      origination: z.string().optional()
    }),
    query: `UPDATE [dbo].[Leads]
            SET [Description] = COALESCE(@description, [Description]),
                [origination] = COALESCE(@origination, [origination])
            WHERE ID = @id`
  }
};

export default leadRoutes;
