import React, { FC, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useEventBus } from '../../context/EventBusContext';
import { toast } from 'react-toastify';
import { getNotesSummaryPrompt } from '../../utils/summaryUtils';
import { Contact } from '../../models/Contact';
import { Note, NOTE_TYPES } from '../../models/Note';
import NoteEditor from './NoteEditor';
import NoteCard from './NoteCard';
import { request } from '../../services/dataService';
import { API_ENDPOINTS } from '../../constants/apiEndpoints';
import { THEME_CLASSES } from '../../constants/themeConstants';
import { useTeams } from '../../context/TeamContext';
import VirtualizedList, { ListItem } from '../common/VirtualizedList';

interface ContactNotesProps {
  accountId: number;
  accountName: string;
  initialContactId?: number;
  initialNoteId?: number;
}

type ContactWithCount = Contact & {
  noteCount: number;
};

const ContactNotes: FC<ContactNotesProps> = ({
  accountId,
  accountName,
  initialContactId,
  initialNoteId
}) => {
  const [loading, setLoading] = React.useState(true);
  const [data, setData] = React.useState<(ListItem | ContactWithCount)[]>([]);
  const { currentUserId } = useTeams();
  const { publish } = useEventBus();
  const [allNotes, setAllNotes] = useState<Note[]>([]);
  const [selectedContactId, setSelectedContactId] = useState<number>(initialContactId ?? -1);
  const [selectedNoteId, setSelectedNoteId] = useState<number | null>(initialNoteId ?? null);
  const [searchQuery, setSearchQuery] = useState('');
  const [noteContent, setNoteContent] = useState('');
  const [noteType, setNoteType] = useState<typeof NOTE_TYPES[number]>('general');
  const [isEditing, setIsEditing] = useState(false);
  const [contactSearchQuery, setContactSearchQuery] = useState('');
  const [teamIds, setTeamIds] = useState<number[]>([]);
  const navigate = useNavigate();

  // Move fetchData outside useEffect
  const fetchData = async () => {
    setLoading(true);
    try {
      const [contacts, notes] = await Promise.all([
        request(API_ENDPOINTS.GET_ACCOUNT_CONTACTS, { id: accountId, initialContactId }),
        request(API_ENDPOINTS.GET_ACCOUNT_NOTES, { id: accountId })
      ]);

      // Calculate note counts
      const noteCounts = new Map<number, number>();
      notes.forEach((note: Note) => {
        const contactId = note.contactId || 0;
        noteCounts.set(contactId, (noteCounts.get(contactId) || 0) + 1);
      });

      // Add account level pseudo-contacts at the top
      const allNotesItem: ListItem = {
        id: -1,
        displayName: '(All notes)',
        isAccountLevel: true,
        noteCount: notes.length
      };

      const accountNotesItem: ListItem = {
        id: 0,
        displayName: '(Account notes)',
        isAccountLevel: true,
        noteCount: noteCounts.get(0) || 0
      };

      // Add note counts to contacts
      const contactsWithCounts: ContactWithCount[] = contacts.map((contact: Contact) => ({
        ...contact,
        noteCount: noteCounts.get(contact.id) || 0
      }));

      setData([allNotesItem, accountNotesItem, ...contactsWithCounts]);
      // Sort notes by updatedAt before setting them
      const sortedNotes = notes.sort((a: Note, b: Note) =>
        (new Date(b.updatedAt ?? b.createdAt).getTime()) -
        (new Date(a.updatedAt ?? a.createdAt).getTime())
      );
      setAllNotes(sortedNotes);
      // If there's no currently selected note or it was deleted, select the first note
      if ((!selectedNoteId || !sortedNotes.find((note: Note) => note.id === selectedNoteId)) && notes.length > 0) {
        setSelectedNoteId(notes[0].id);
      }
    } catch (error) {
      console.error('Error fetching data:', error);
    } finally {
      setLoading(false);
    }
  };

  // Use fetchData in useEffect
  React.useEffect(() => {
    fetchData();
  }, [accountId]);

  // Add effect to handle initialNoteId
  React.useEffect(() => {
    if (initialNoteId) {
      const note = allNotes.find(note => note.id === initialNoteId);
      if (note) {
        setSelectedNoteId(initialNoteId);
        setNoteContent(note.content);
        setIsEditing(false);
      }
    }
  }, [initialNoteId, allNotes]);

  // Filter notes based on selected contact and search query
  const filteredNotes = allNotes.filter((note: Note) => {
    const searchTerms = searchQuery.toLowerCase().split(/\s+/).filter(term => term.length > 0);
  const matchesSearch = searchTerms.length === 0 || searchTerms.every(term =>
    (note.content?.toLowerCase().includes(term) ?? false) ||
    (note.author?.toLowerCase().includes(term) ?? false)
  );
    const matchesContact = selectedContactId === -1 ||
      (selectedContactId === 0 && note.contactId === 0) ||
      (selectedContactId && note.contactId === selectedContactId);
    return matchesSearch && matchesContact;
  });

  // Filter contacts based on search query
  const filteredData = data.filter(item => {
    if (item.isAccountLevel) return true; // Always show account level items

    // Split search query into terms and check if all terms are included
    const searchTerms = contactSearchQuery.toLowerCase().split(/\s+/).filter(term => term.length > 0);
    if (searchTerms.length === 0) return true;

    const displayName = (item.displayName || '').toLowerCase();
    return searchTerms.every(term => displayName.includes(term));
  });

  // Modify handleSaveNote to call fetchData after successful save
  const handleSaveNote = async () => {
    if (!noteContent.trim()) return;

    try {
      const noteData: Note = {
        title: noteContent?.split('\n')?.[0]?.slice(0, 250) ?? '', // Use first line as title
        content: noteContent,
        type: noteType,
        teamId: teamIds[0] || 0,
        teamIds: teamIds.join(','),
        objectType: selectedContactId > 0 ? 'contact' : 'account',
        objectIds: selectedContactId > 0 ? selectedContactId.toString() : accountId.toString(),
        contactId: selectedContactId > 0 ? selectedContactId : 0,
        createdBy: currentUserId || 0,
        createdAt: new Date(),
        updatedBy: currentUserId || 0,
        updatedAt: new Date(),
        id: selectedNoteId || 0
      };

      if (!selectedNoteId) {
        // Save the ID of the newly created note
        const newNote: Note = await request(API_ENDPOINTS.POST_NOTE, noteData);
        setSelectedNoteId(newNote.id);
      } else {
        const { id: _, ...noteDataWithoutId } = noteData;
        await request(API_ENDPOINTS.PUT_NOTE,
          { id: selectedNoteId, ...noteDataWithoutId }
        );
      }
      setIsEditing(false);
      // Refresh data after successful save
      const fetchComplete = fetchData();
      toast.success(`Note ${selectedNoteId ? 'updated' : 'created'} successfully`)
      await fetchComplete;
    } catch (error) {
      console.error('Failed to save note:', error);
    }
  };

  const selectedNote = allNotes.find((note: Note) => note.id === selectedNoteId);

  // Get search terms for highlighting
  const searchTerms = contactSearchQuery.toLowerCase().split(/\s+/).filter(term => term.length > 0);

  return (
    <div className="bg-white dark:bg-gray-800 rounded-xl shadow-lg border dark:border-gray-700 flex w-full h-full overflow-hidden">
      {/* Left Column - Contacts */}
      <div className="w-1/4 border-r border-gray-200 dark:border-gray-700 flex flex-col h-full">
        <div className="p-4 border-b border-gray-200 dark:border-gray-700 h-[4rem] shrink-0 flex items-center">
          <div className="w-full">
            <div className="relative">
              <div className="absolute inset-y-0 left-2 flex items-center pointer-events-none">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" className="w-4 h-4 text-gray-400">
                  <path fillRule="evenodd" d="M9 3.5a5.5 5.5 0 100 11 5.5 5.5 0 000-11zM2 9a7 7 0 1112.452 4.391l3.328 3.329a.75.75 0 11-1.06 1.06l-3.329-3.328A7 7 0 012 9z" clipRule="evenodd" />
                </svg>
              </div>
              <input
                type="text"
                placeholder="Search contacts..."
                value={contactSearchQuery}
                onChange={(e) => setContactSearchQuery(e.target.value)}
                className="w-full h-8 pl-8 pr-8 text-xs border rounded-md dark:bg-gray-800 dark:border-gray-700 dark:text-gray-300 focus:outline-none focus:ring-2 focus:ring-blue-500"
              />
              {contactSearchQuery && (
                <button
                  onClick={() => setContactSearchQuery('')}
                  className="absolute inset-y-0 right-2 flex items-center hover:text-gray-600 dark:hover:text-gray-300"
                >
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" className="w-4 h-4 text-gray-400">
                    <path d="M6.28 5.22a.75.75 0 00-1.06 1.06L8.94 10l-3.72 3.72a.75.75 0 101.06 1.06L10 11.06l3.72 3.72a.75.75 0 101.06-1.06L11.06 10l3.72-3.72a.75.75 0 00-1.06-1.06L10 8.94 6.28 5.22z" />
                  </svg>
                </button>
              )}
            </div>
          </div>
        </div>
        <div className="flex-1 overflow-hidden">
          {loading ? (
            <div className="flex items-center justify-center min-h-full">
              <div className={`animate-pulse ${THEME_CLASSES.text.secondary}`}>Loading...</div>
            </div>
          ) : filteredData.length === 0 ? (
            <div className={`px-4 py-3 text-center text-sm ${THEME_CLASSES.text.secondary}`}>
              No contacts found
            </div>
          ) : (
            <VirtualizedList
              items={filteredData.map(item => ({
                id: item.id,
                displayName: item.displayName,
                noteCount: item.noteCount,
                isSpecialItem: item.isAccountLevel,
                icon: item.id === -1 ? (
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="w-5 h-5">
                    <path d="M5.566 4.657A4.505 4.505 0 016.75 4.5h10.5c.41 0 .806.055 1.183.157A3 3 0 0015.75 3h-7.5a3 3 0 00-2.684 1.657zM2.25 12a3 3 0 013-3h13.5a3 3 0 013 3v6a3 3 0 01-3 3H5.25a3 3 0 01-3-3v-6zM5.25 7.5c-.41 0-.806.055-1.184.157A3 3 0 016.75 6h10.5a3 3 0 012.684 1.657A4.505 4.505 0 0018.75 7.5H5.25z" />
                  </svg>
                ) : item.id === 0 ? (
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="w-5 h-5">
                    <path fillRule="evenodd" d="M4.125 3C3.089 3 2.25 3.84 2.25 4.875V18a3 3 0 003 3h15a3 3 0 01-3-3V4.875C17.25 3.839 16.41 3 15.375 3H4.125zM12 9.75a.75.75 0 000 1.5h1.5a.75.75 0 000-1.5H12zm-.75-2.25a.75.75 0 01.75-.75h1.5a.75.75 0 010 1.5H12a.75.75 0 01-.75-.75zM6 12.75a.75.75 0 000 1.5h7.5a.75.75 0 000-1.5H6zm-.75 3.75a.75.75 0 01.75-.75h7.5a.75.75 0 010 1.5H6a.75.75 0 01-.75-.75zM6 6.75a.75.75 0 00-.75.75v3c0 .414.336.75.75.75h3a.75.75 0 00.75-.75v-3A.75.75 0 009 6.75H6z" clipRule="evenodd" />
                  </svg>
                ) : (
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="w-5 h-5">
                    <path fillRule="evenodd" d="M7.5 6a4.5 4.5 0 119 0 4.5 4.5 0 01-9 0zM3.751 20.105a8.25 8.25 0 0116.498 0 .75.75 0 01-.437.695A18.683 18.683 0 0112 22.5c-2.786 0-5.433-.608-7.812-1.7a.75.75 0 01-.437-.695z" clipRule="evenodd" />
                  </svg>
                ),
                onClick: !item.isAccountLevel ? () => navigate(`/contacts/${item.id}`) : undefined
              }))}
              selectedId={selectedContactId}
              onSelect={(id) => {
                setSelectedContactId(id);
                setSelectedNoteId(null);
              }}
              searchQuery={searchTerms}
            />
          )}
        </div>
      </div>

      {/* Middle Column - Notes List */}
      <div className="w-[35%] border-r border-gray-200 dark:border-gray-700 flex flex-col">
        <div className="p-4 border-b border-gray-200 dark:border-gray-700 h-[4rem] shrink-0 flex items-center">
          <div className="flex gap-2 items-center w-full">
            <div className="flex-1 relative">
              <div className="absolute inset-y-0 left-2 flex items-center pointer-events-none">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" className="w-4 h-4 text-gray-400">
                  <path fillRule="evenodd" d="M9 3.5a5.5 5.5 0 100 11 5.5 5.5 0 000-11zM2 9a7 7 0 1112.452 4.391l3.328 3.329a.75.75 0 11-1.06 1.06l-3.329-3.328A7 7 0 012 9z" clipRule="evenodd" />
                </svg>
              </div>
              <input
                type="text"
                placeholder="Search notes..."
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
                className="flex-1 w-full h-8 pl-8 pr-8 text-xs border rounded-md dark:bg-gray-800 dark:border-gray-700 dark:text-gray-300 focus:outline-none focus:ring-2 focus:ring-blue-500"
              />
              {searchQuery && (
                <button
                  onClick={() => setSearchQuery('')}
                  className="absolute inset-y-0 right-2 flex items-center hover:text-gray-600 dark:hover:text-gray-300"
                >
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" className="w-4 h-4 text-gray-400">
                    <path d="M6.28 5.22a.75.75 0 00-1.06 1.06L8.94 10l-3.72 3.72a.75.75 0 101.06 1.06L10 11.06l3.72 3.72a.75.75 0 101.06-1.06L11.06 10l3.72-3.72a.75.75 0 00-1.06-1.06L10 8.94 6.28 5.22z" />
                  </svg>
                </button>
              )}
            </div>
            <div className="flex gap-2">
              <button
                onClick={() => {
                  setSelectedNoteId(null);
                  setNoteContent('');
                  setTeamIds([]);
                  // When clicking New Note in Account notes or contact view,
                  // create note for that context. In All notes view, default to account note.
                  if (selectedContactId === -1) {
                    setSelectedContactId(0); // Default to account note in All notes view
                  }
                  setIsEditing(true);
                }}
                className="px-3 h-8 text-xs font-medium text-white bg-blue-600 rounded-md hover:bg-blue-700"
              >
                New Note
              </button>
              <button
                onClick={() => {
                  if (filteredNotes.length === 0) return;
                  const prompt = getNotesSummaryPrompt(filteredNotes);
                  publish('chat:open', prompt);
                }}
                className={`p-2 rounded-md ${THEME_CLASSES.text.secondary} ${THEME_CLASSES.hover.background}`}
                title="Ask AI about notes"
              >
                <svg className="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M8 12h.01M12 12h.01M16 12h.01M21 12c0 4.418-4.03 8-9 8a9.863 9.863 0 01-4.255-.949L3 20l1.395-3.72C3.512 15.042 3 13.574 3 12c0-4.418 4.03-8 9-8s9 3.582 9 8z" />
                </svg>
              </button>
            </div>
          </div>
        </div>

        <div className="flex-1 overflow-y-auto">
          {filteredNotes.map(note => (
            <NoteCard
              key={`note-${note.id}`}
              note={note}
              isSelected={selectedNoteId === note.id}
              onClick={() => {
                setSelectedNoteId(note.id);
                setNoteContent(note.content);
                // When in All notes view and selecting a note, update the contact selection
                if (selectedContactId === -1) {
                  setSelectedContactId(note.contactId || 0);
                }
                setIsEditing(false);
              }}
              searchQuery={searchQuery}
              onDeleteClick={async (note) => {
                try {
                  await request(API_ENDPOINTS.DELETE_NOTE, { id: note.id });
                  await fetchData();
                  toast.success('Note deleted successfully');
                } catch (error) {
                  console.error('Error deleting note:', error);
                  toast.error('Failed to delete note');
                }
              }}
            />
          ))}
        </div>
      </div>

      {/* Right Column - Note Details */}
      <div className="w-[40%] flex flex-col h-full overflow-hidden">
        <div className="flex-1 overflow-y-auto">
          {(selectedNote && !isEditing) || isEditing ? (
            <NoteEditor
              note={isEditing && !selectedNoteId ? undefined : selectedNote}
              content={noteContent}
              type={noteType}
              teamIds={teamIds}
              accountName={isEditing ? (selectedContactId === 0 ? accountName : undefined) : (selectedNote?.contactId === 0 ? accountName : undefined)}
              contactName={isEditing ? (selectedContactId > 0 ? data.find(item => item.id === selectedContactId)?.displayName : undefined) : (selectedNote?.contactId && selectedNote.contactId > 0 ? data.find(item => item.id === selectedNote.contactId)?.displayName : undefined)}
              parent="AccountNotes"
              onContentChange={setNoteContent}
              onTypeChange={setNoteType}
              onTeamChange={setTeamIds}
              submitButtonText={selectedNoteId ? 'Save Changes' : 'Create Note'}
              onSubmit={handleSaveNote}
              onCancel={() => {
                setIsEditing(false);
                if (selectedNoteId && selectedNote) {
                  setNoteContent(selectedNote.content);
                  setTeamIds(selectedNote.teamIds?.split(',').map(Number) || []);
                } else {
                  setSelectedNoteId(null);
                  setNoteContent('');
                  setTeamIds([]);
                }
              }}
              currentUserId={currentUserId || undefined}
            />
          ) : (
            <div className={`h-full flex items-center justify-center text-xs ${THEME_CLASSES.text.secondary}`}>
              {selectedContactId ? 'Select a note to view details' : 'Select a contact to view notes'}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default ContactNotes;
