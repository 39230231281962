import { FC, ReactNode } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { THEME_CLASSES, SIDEBAR } from '../constants/themeConstants';
import { getUserData } from '../services/authService';
import { useTeams } from '../context/TeamContext';

interface NavigationItem {
  to: string;
  icon: ReactNode;
  label: string;
}

interface SidebarProps {
  isCollapsed: boolean;
  onCollapse: (collapsed: boolean) => void;
  onNavigate?: () => void;
}

const Sidebar: FC<SidebarProps> = ({ isCollapsed, onCollapse, onNavigate }) => {
  const location = useLocation();
  const { teams } = useTeams();

  const isUserAdmin = teams.some(team => team.teamName === 'User Admin');
  const isSysAdmin = teams.some(team => team.teamName === 'System Admin');

  const isActive = (path: string): boolean => {
    if (path === '/accounts') {
      return location.pathname.startsWith('/accounts');
    }
    if (path === '/contacts') {
      return location.pathname.startsWith('/contacts');
    }
    if (path === '/pipelines') {
      return location.pathname.startsWith('/pipelines');
    }
    if (path === '/help') {
      return location.pathname.startsWith('/help');
    }
    if (path === '/settings') {
      return location.pathname.startsWith('/settings');
    }
    return location.pathname === path;
  };

  const navigationItems: NavigationItem[] = [
    {
      to: '/',
      icon: (
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={2}
          d="M3 12l2-2m0 0l7-7 7 7M5 10v10a1 1 0 001 1h3m10-11l2 2m-2-2v10a1 1 0 01-1 1h-3m-6 0a1 1 0 001-1v-4a1 1 0 011-1h2a1 1 0 011 1v4a1 1 0 001 1m-6 0h6"
        />
      ),
      label: 'Dashboard',
    },
    {
      to: '/accounts',
      icon: (
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={2}
          d="M17 9V7a2 2 0 00-2-2H5a2 2 0 00-2 2v6a2 2 0 002 2h2m2 4h10a2 2 0 002-2v-6a2 2 0 00-2-2H9a2 2 0 00-2 2v6a2 2 0 002 2zm7-5a2 2 0 11-4 0 2 2 0 014 0z"
        />
      ),
      label: 'Accounts',
    },
    {
      to: '/contacts',
      icon: (
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={2}
          d="M15.75 6a3.75 3.75 0 11-7.5 0 3.75 3.75 0 017.5 0zM4.501 20.118a7.5 7.5 0 0114.998 0A17.933 17.933 0 0112 21.75c-2.676 0-5.216-.584-7.499-1.632z"
        />
      ),
      label: 'Contacts',
    },
    {
      to: '/pipelines',
      icon: (
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={2}
          d="M3 4h18v2.586a1 1 0 01-.293.707l-6.414 6.414a1 1 0 00-.293.707V17l-4 4v-6.586a1 1 0 00-.293-.707L3.293 7.293A1 1 0 013 6.586V4z"
        />
      ),
      label: 'Pipelines',
    },
    ...(isSysAdmin ? [{
      to: '/settings',
      icon: (
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={2}
          d="M10.325 4.317c.426-1.756 2.924-1.756 3.35 0a1.724 1.724 0 002.573 1.066c1.543-.94 3.31.826 2.37 2.37a1.724 1.724 0 001.065 2.572c1.756.426 1.756 2.924 0 3.35a1.724 1.724 0 00-1.066 2.573c.94 1.543-.826 3.31-2.37 2.37a1.724 1.724 0 00-2.572 1.065c-.426 1.756-2.924 1.756-3.35 0a1.724 1.724 0 00-2.573-1.066c-1.543.94-3.31-.826-2.37-2.37a1.724 1.724 0 00-1.065-2.572c-1.756-.426-1.756-2.924 0-3.35a1.724 1.724 0 001.066-2.573c-.94-1.543.826-3.31 2.37-2.37.996.608 2.296.07 2.572-1.065z"
        />
      ),
      label: 'Settings',
    }] : []),
    ...(isUserAdmin ? [{
      to: '/admin',
      icon: (
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={2}
          d="M5.121 17.804A13.937 13.937 0 0112 16c2.5 0 4.847.655 6.879 1.804M15 10a3 3 0 11-6 0 3 3 0 016 0zm6 2a9 9 0 11-18 0 9 9 0 0118 0z"
        />
      ),
      label: 'Admin',
    }] : []),
    {
      to: '/chat',
      icon: (
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={2}
          d="M8 12h.01M12 12h.01M16 12h.01M21 12c0 4.418-4.03 8-9 8a9.863 9.863 0 01-4.255-.949L3 20l1.395-3.72C3.512 15.042 3 13.574 3 12c0-4.418 4.03-8 9-8s9 3.582 9 8z"
        />
      ),
      label: 'Chat',
    },
    {
      to: '/help',
      icon: (
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={2}
          d="M8.228 9c.549-1.165 2.03-2 3.772-2 2.21 0 4 1.343 4 3 0 1.4-1.278 2.575-3.006 2.907-.542.104-.994.54-.994 1.093m0 3h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
        />
      ),
      label: 'Help',
    },
  ];

  return (
    <aside
      className={`h-screen border-r ${THEME_CLASSES.border} ${THEME_CLASSES.background} transition-all duration-300 ${isCollapsed ? SIDEBAR.collapsedWidth : SIDEBAR.expandedWidth
        }`}
    >
      <div className="flex flex-col h-full">
        <nav className="flex-1 px-2 mt-4">
          <div className="flex flex-col space-y-1">
            {navigationItems.map(({ to, icon, label }) => (
              <Link
                key={to}
                to={to}
                onClick={onNavigate}
                className={`flex items-center h-10 px-3 rounded-lg transition-colors duration-150 ${isActive(to)
                  ? 'bg-blue-600 text-white'
                  : THEME_CLASSES.text.secondary +
                  ' ' +
                  THEME_CLASSES.hover.background +
                  ' ' +
                  THEME_CLASSES.hover.text
                  }`}
              >
                <div className="w-6 h-6 flex items-center justify-center flex-shrink-0">
                  <svg className="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                    {icon}
                  </svg>
                </div>
                <span
                  className={`ml-3 text-sm font-medium whitespace-nowrap transition-opacity duration-150 ${isCollapsed ? 'opacity-0 w-0 overflow-hidden' : 'opacity-100'
                    }`}
                >
                  {label}
                </span>
              </Link>
            ))}
          </div>
        </nav>

        <div className="px-2 py-4 mt-auto">
          <button
            onClick={() => onCollapse(!isCollapsed)}
            className={`flex items-center p-2 rounded-lg ${THEME_CLASSES.text.secondary} ${THEME_CLASSES.hover.text} ${THEME_CLASSES.hover.background}`}
            title={isCollapsed ? "Expand sidebar" : "Collapse sidebar"}
            aria-label={isCollapsed ? "Expand sidebar" : "Collapse sidebar"}
          >
            <svg className="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24">
              {isCollapsed ? (
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 5l7 7-7 7" />
              ) : (
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M15 19l-7-7 7-7" />
              )}
            </svg>
          </button>
        </div>
      </div>
    </aside>
  );
};

export default Sidebar;
