import { FC, useState, useEffect, useMemo, KeyboardEvent as ReactKeyboardEvent } from 'react';
import { THEME_CLASSES } from '../../constants/themeConstants';
import { request } from '../../services/dataService';
import { API_ENDPOINTS } from '../../constants/apiEndpoints';
import DynamicForm from '../common/DynamicForm';
import { useTeams } from '../../context/TeamContext';
import { useSchemas } from '../../context/SchemaContext';
import { Account } from '../../models/Account';
import { renderTypeChip } from '../../utils/typeChipUtils';

interface NewContactProps {
  isOpen: boolean;
  onClose: () => void;
}

const NewContact: FC<NewContactProps> = ({ isOpen, onClose }) => {
  const { currentUserId } = useTeams();
  const [accounts, setAccounts] = useState<Account[] | null>(null);
  const [accountSearch, setAccountSearch] = useState('');
  const [selectedAccount, setSelectedAccount] = useState<Account | null>(null);
  const { schemas, isLoading: isLoadingSchemas } = useSchemas(1);
  const [highlightedIndex, setHighlightedIndex] = useState<number>(-1);

  // Handle escape key to close popup
  useEffect(() => {
    const handleEscape = (e: globalThis.KeyboardEvent) => {
      if (e.key === 'Escape') {
        onClose();
      }
    };

    window.addEventListener('keydown', handleEscape);
    return () => window.removeEventListener('keydown', handleEscape);
  }, [onClose]);

  // Load accounts only when popup first opens
  useEffect(() => {
    const loadAccounts = async () => {
      if (!isOpen || accounts !== null) return;

      try {
        const data = await request(API_ENDPOINTS.GET_ACCOUNTS);
        setAccounts(data);
      } catch (error) {
        console.error('Error loading accounts:', error);
      }
    };

    void loadAccounts();
  }, [isOpen, accounts]);

  // Filter accounts based on search
  const filteredAccounts = useMemo(() => {
    if (!accounts || !accountSearch) return [];
    const searchLower = accountSearch.toLowerCase();
    return accounts.filter(account =>
      !account.rlpCompanyId && (
        account.name?.toLowerCase().includes(searchLower) ||
        account.city?.toLowerCase().includes(searchLower) ||
        account.province?.toLowerCase().includes(searchLower))
    ).slice(0, 5); // Limit to 5 results
  }, [accounts, accountSearch]);

  // Update highlighted index when filtered accounts change
  useEffect(() => {
    setHighlightedIndex(filteredAccounts.length > 0 ? 0 : -1);
  }, [filteredAccounts]);

  const formSchema = useMemo(() => {
    if (!selectedAccount) return null;
    const matchingSchema = schemas.find(s =>
      s.entityType === 1 && s.teamId === selectedAccount.teamId
    );
    return matchingSchema ? JSON.parse(matchingSchema.schema) : null;
  }, [selectedAccount, schemas]);

  if (!isOpen) return null;

  const handleSubmit = async (formData: Record<string, any>) => {
    if (!currentUserId || !selectedAccount) return;

    try {
      const contactData = {
        ...formData,
        teamId: selectedAccount.teamId
      };

      await request(API_ENDPOINTS.POST_CONTACT, {
        name: formData.name || formData.displayName || '',
        data: JSON.stringify(contactData),
        accountId: selectedAccount.id
      });
      onClose();
      window.location.reload(); // Reload page to show new contact
    } catch (error) {
      console.error('Error creating contact:', error);
      throw error;
    }
  };

  const selectAccount = (account: Account) => {
    setSelectedAccount(account);
    setAccountSearch('');
    setHighlightedIndex(-1);
  };

  const handleKeyDown = (e: ReactKeyboardEvent<HTMLInputElement>) => {
    if (filteredAccounts.length === 0) return;

    switch (e.key) {
      case 'ArrowDown':
        e.preventDefault();
        setHighlightedIndex(prev =>
          prev < filteredAccounts.length - 1 ? prev + 1 : 0
        );
        break;
      case 'ArrowUp':
        e.preventDefault();
        setHighlightedIndex(prev =>
          prev > 0 ? prev - 1 : filteredAccounts.length - 1
        );
        break;
      case 'Enter':
        e.preventDefault();
        if (highlightedIndex >= 0 && highlightedIndex < filteredAccounts.length) {
          selectAccount(filteredAccounts[highlightedIndex]);
        }
        break;
      case 'Escape':
        e.preventDefault();
        setAccountSearch('');
        break;
    }
  };

  return (
    <dialog open={isOpen} className="fixed inset-0 z-50 bg-transparent">
      <div className="fixed inset-0 bg-black/50" />
      <div className={`fixed left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 p-8 pb-2 rounded-lg w-[800px] max-h-[90vh] overflow-y-auto ${THEME_CLASSES.card.background}`}>
        <div className={`flex justify-between items-center mb-2 ${THEME_CLASSES.note.card.background.gray} -m-8 rounded-t-lg`}>
          <h3 className="text-lg font-semibold text-gray-900 dark:text-white pl-4 pt-4">
            New Contact
          </h3>
          <button
            type="button"
            onClick={onClose}
            className="text-gray-500 dark:text-gray-400 hover:text-gray-700 dark:hover:text-gray-300 hover:bg-gray-100 dark:hover:bg-gray-600 rounded-full mr-4"
            aria-label="Close"
          >
            ×
          </button>
        </div>

        <div className="mb-6">
          {!selectedAccount ? (
            <div className="flex items-center gap-3">
              <label className={`text-sm my-auto font-medium whitespace-nowrap ${THEME_CLASSES.text.secondary}`}>
                Search Account:
              </label>
              <div className="relative flex-1">
                <input
                  type="text"
                  value={accountSearch}
                  onChange={(e) => setAccountSearch(e.target.value)}
                  onKeyDown={handleKeyDown}
                  placeholder="Search by account name, city, or province..."
                  className={`w-full p-2 border rounded-lg ${THEME_CLASSES.input.background} ${THEME_CLASSES.input.border} ${THEME_CLASSES.input.text} ${THEME_CLASSES.input.placeholder}`}
                />
                {filteredAccounts.length > 0 && (
                  <div className={`absolute z-10 w-full mt-1 rounded-lg shadow-lg border ${THEME_CLASSES.dropdown.background} ${THEME_CLASSES.card.border}`}>
                    {filteredAccounts.map((account, index) => (
                      <button
                        key={account.id}
                        onClick={() => selectAccount(account)}
                        className={`w-full text-left px-4 py-2 ${THEME_CLASSES.dropdown.item.base} ${index === highlightedIndex
                          ? THEME_CLASSES.dropdown.item.hover.split('hover:').join('')
                          : THEME_CLASSES.dropdown.item.hover
                          }`}
                      >
                        <div className="flex items-center gap-2">
                          {renderTypeChip({
                            rlpCompanyId: account.rlpCompanyId,
                            teamId: account.teamId
                          })}
                          <span className="text-sm font-medium">{account.name}</span>
                        </div>
                        <div className={`text-xs ${THEME_CLASSES.text.secondary}`}>
                          {account.city}, {account.province}
                        </div>
                      </button>
                    ))}
                  </div>
                )}
              </div>
            </div>
          ) : selectedAccount && (
            <div className={`mt-2 flex justify-between items-center p-2 rounded-lg ${THEME_CLASSES.note.card.background.gray}`}>
              <div>
                <div className="flex items-center gap-2">
                  {renderTypeChip({
                    rlpCompanyId: selectedAccount.rlpCompanyId,
                    teamId: selectedAccount.teamId
                  })}
                  <span className={`font-medium ${THEME_CLASSES.text.primary}`}>{selectedAccount.name}</span>
                </div>
                <div className={`text-sm ${THEME_CLASSES.text.secondary}`}>
                  {selectedAccount.city}, {selectedAccount.province}
                </div>
              </div>
              <button
                onClick={() => setSelectedAccount(null)}
                className="text-gray-500 hover:text-gray-700 dark:text-gray-400 dark:hover:text-gray-300"
              >
                ×
              </button>
            </div>
          )}
        </div>

        <div className="min-h-[300px]">
          {selectedAccount && formSchema ? (
            <DynamicForm
              fields={formSchema}
              onSubmit={handleSubmit}
              onCancel={onClose}
              submitLabel="Create Contact"
            />
          ) : (
            <div className={`flex items-center justify-center h-full ${THEME_CLASSES.text.secondary}`}>
              {isLoadingSchemas ? (
                'Loading schema...'
              ) : (
                'Please select an account first'
              )}
            </div>
          )}
        </div>
      </div>
    </dialog>
  );
};

export default NewContact;
