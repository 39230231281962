import { FC } from 'react';
import { Note, NOTE_TYPES } from '../../models/Note';
import { THEME_CLASSES } from '../../constants/themeConstants';
import { highlightText } from '../../utils/highlightUtils';
import { openNoteInGmail, openNoteInCalendar } from '../../utils/googleUtils';

interface NoteCardProps {
  note: Note;
  isSelected: boolean;
  onClick: () => void;
  searchQuery: string;
  onDeleteClick?: (note: Note) => void;
}

const NoteCard: FC<NoteCardProps> = ({ note, isSelected, onClick, searchQuery, onDeleteClick }) => {
  return (
    <div
      onClick={onClick}
      className={`p-4 cursor-pointer rounded-lg mb-2 mx-2 ${THEME_CLASSES.card.border}
        ${isSelected ? 'bg-blue-50/80 dark:bg-blue-900/30' : THEME_CLASSES.hover.background}`}
    >
      <div className="flex items-center justify-between mb-1">
        <div className={`text-sm font-medium ${THEME_CLASSES.text.primary}`}>
          {note.author ? highlightText(note.author, searchQuery ? searchQuery.split(/\s+/).filter(k => k.length > 0) : []) : 'Unknown'}
          <span className="ml-2 text-xs text-gray-400 dark:text-gray-500">#{note.id}</span>
        </div>
        <span className="text-xs text-gray-500 dark:text-gray-400 whitespace-nowrap ml-2">
          {new Date(note.updatedAt ?? note.createdAt).toLocaleString(undefined, {
            year: 'numeric',
            month: 'numeric',
            day: 'numeric'
          })}
        </span>
      </div>
      <div className={`text-xs font-medium ${THEME_CLASSES.text.primary} truncate mb-2`}>
        {highlightText(note.content?.split('\n')[0] ?? '', searchQuery ? searchQuery.split(/\s+/).filter(k => k.length > 0) : [])}
      </div>
      <div className={`text-xs ${THEME_CLASSES.text.secondary} line-clamp-2 whitespace-pre-line mb-2`}>
        {highlightText(note.content?.split('\n').slice(1).join('\n') ?? '', searchQuery ? searchQuery.split(/\s+/).filter(k => k.length > 0) : [])}
      </div>
      <div className="flex items-center justify-between">
        <div className="flex gap-1">
          {note.type && NOTE_TYPES.includes(note.type) && (
            <span className={`px-2 py-0.5 text-xs rounded-full ${note.type === 'meeting' ? 'bg-purple-100 dark:bg-purple-900/30 text-purple-700 dark:text-purple-300' :
              note.type === 'phone call' ? 'bg-blue-100 dark:bg-blue-900/30 text-blue-700 dark:text-blue-300' :
                note.type === 'email' ? 'bg-green-100 dark:bg-green-900/30 text-green-700 dark:text-green-300' :
                  note.type === 'in person' ? 'bg-red-100 dark:bg-red-900/30 text-red-700 dark:text-red-300' :
                    note.type === 'voice mail' ? 'bg-yellow-100 dark:bg-yellow-900/30 text-yellow-700 dark:text-yellow-300' :
                      'bg-gray-100 dark:bg-gray-700 text-gray-600 dark:text-gray-300'
              }`}>
              {note.type}
            </span>
          )}
        </div>
        <div className="flex gap-2">
          {/* Gmail */}
          <button
            onClick={(e) => {
              e.stopPropagation();
              openNoteInGmail(note, ''); // Open with empty recipient
            }}
            className={`p-2 rounded-md ${THEME_CLASSES.text.secondary} ${THEME_CLASSES.hover.background}`}
            title="Compose Gmail"
          >
            <svg className="w-4 h-4" fill="none" stroke="currentColor" viewBox="0 0 24 24">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M3 8l7.89 5.26a2 2 0 002.22 0L21 8M5 19h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z" />
            </svg>
          </button>
          {/* Calendar */}
          <button
            onClick={(e) => {
              e.stopPropagation();
              // Default start time to now rounded to nearest hour
              const startDate = new Date();
              startDate.setMinutes(0, 0, 0);
              startDate.setHours(startDate.getHours() + 1);

              openNoteInCalendar(note, startDate);
            }}
            className={`p-2 rounded-md ${THEME_CLASSES.text.secondary} ${THEME_CLASSES.hover.background}`}
            title="Add to Google Calendar"
          >
            <svg className="w-4 h-4" fill="none" stroke="currentColor" viewBox="0 0 24 24">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M8 7V3m8 4V3m-9 8h10M5 21h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v12a2 2 0 002 2z" />
            </svg>
          </button>
          {/* Delete */}
          <button
            onClick={(e) => {
              e.stopPropagation();
              if (window.confirm('Are you sure you want to delete this note?')) {
                onDeleteClick?.(note);
              }
            }}
            className={`p-2 rounded-md ${THEME_CLASSES.text.secondary} ${THEME_CLASSES.hover.background}`}
            title="Delete note"
          >
            <svg className="w-4 h-4" fill="none" stroke="currentColor" viewBox="0 0 24 24">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16" />
            </svg>
          </button>
        </div>
      </div>
    </div>
  );
};

export default NoteCard;
