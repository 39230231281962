import { ColDef, ValueSetterParams, ValueGetterParams } from 'ag-grid-community';
import { FormField } from '../components/common/FormField';

export const createColumnDefs = <T extends Record<string, any>>(
  fields: FormField[]
): ColDef<T>[] => {
  return fields.map(field => {
    const isTitle = field.name === 'title';
    const colId = field.name;

    const colDef: ColDef<T> = {
      colId,
      headerName: field.label || field.name,
      editable: !isTitle,
      sortable: true,
      filter: true,
      resizable: true,
      width: isTitle ? 300 : field.type === 'date' ? 150 : field.type === 'number' ? 120 : 180,
      suppressSizeToFit: true,
      pinned: isTitle ? 'left' : undefined,
      lockPinned: isTitle,
      valueGetter: (params: ValueGetterParams<T>) => {
        return params.data ? (params.data as any)[colId] : undefined;
      },
      valueSetter: (params: ValueSetterParams<T>) => {
        if (!params.data) return false;
        (params.data as any)[colId] = params.newValue;
        return true;
      },
      type: field.type === 'select' ? 'select' :
        field.type === 'date' ? 'date' :
          field.type === 'number' ? 'number' :
            field.type === 'textarea' ? 'textarea' : 'text'
    };

    if (field.type === 'select' && field.options) {
      colDef.cellEditorParams = {
        values: field.options.map(opt => opt.value)
      };
    }

    return colDef;
  });
};

export const defaultColDef: ColDef = {
  sortable: true,
  filter: true,
  resizable: true,
  editable: true,
  minWidth: 100,
  maxWidth: 1000,
  suppressSizeToFit: true,
  suppressAutoSize: true,
  menuTabs: [],
  wrapText: false,
  autoHeight: false,
  hide: false
};
