import { FC, useMemo, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Contact } from '../../models/Contact';
import { type FormField } from '../../components/common/FormField';
import Grid from '../common/Grid';
import { ColDef } from 'ag-grid-community';
import { request } from '../../services/dataService';
import { API_ENDPOINTS } from '../../constants/apiEndpoints';
import { useSchemas, type Schema } from '../../context/SchemaContext';
import { filterContacts, getContactColumnDefs } from '../../utils/contactUtils';
import Spinner from '../common/Spinner';

interface ContactTableProps {
  searchQuery: string;
  schema: Schema | null;
  onRowCountChange: (count: number) => void;
}

const ContactTable: FC<ContactTableProps> = ({
  searchQuery,
  schema,
  onRowCountChange
}) => {
  const navigate = useNavigate();
  
  const [contacts, setContacts] = useState<Contact[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [schemaFields, setSchemaFields] = useState<FormField[]>([]);

  // Parse schema fields
  useEffect(() => {
    if (schema) {
      try {
        const fields: FormField[] = JSON.parse(schema.schema);
        setSchemaFields(fields);
      } catch (error) {
        console.error('Error parsing schema:', error);
        setSchemaFields([]);
      }
    } else {
      setSchemaFields([]);
    }

    // Determine which teamId to use for fetching contacts
    const teamId = schema?.teamId && !schema?.rlp ? schema.teamId : 0;

    // Fetch contacts data
    const fetchContacts = async () => {
      setLoading(true);
      setError(null);

      try {
        const response = await request(API_ENDPOINTS.GET_TEAM_CONTACTS, { teamId });
        setContacts(response || []);
      } catch (err) {
        const errorMessage = err instanceof Error ? err.message : 'Failed to load contacts';
        setError(errorMessage);
        setContacts([]);
      } finally {
        setLoading(false);
      }
    };

    fetchContacts();
  }, [schema]);

  // Generate column definitions
  const columnDefs = useMemo(() => {
    return getContactColumnDefs(searchQuery, schemaFields);
  }, [searchQuery, schemaFields]);

  // Filter contacts based on search
  const filteredContacts = useMemo(() => {
    return filterContacts(contacts, searchQuery);
  }, [contacts, searchQuery]);

  // Update parent with row count
  useEffect(() => {
    onRowCountChange(filteredContacts.length);
  }, [filteredContacts.length, onRowCountChange]);

  const gridOptions = useMemo(() => ({
    onRowClicked: (params: { data: Contact }) => {
      navigate(`/contacts/${params.data.id}`);
    }
  }), [navigate]);

  if (loading) {
    return (
      <div className="flex items-center justify-center h-64">
        <Spinner />
      </div>
    );
  }

  if (error) {
    return (
      <div className="text-red-500 text-center h-64 flex items-center justify-center">
        {error}
      </div>
    );
  }

  return (
    <div>
      <Grid
        data={filteredContacts}
        columnDefs={columnDefs}
        searchQuery={searchQuery}
        gridOptions={gridOptions}
      />
    </div>
  );
};

export default ContactTable;
