import { createContext, useContext, useEffect, useState, ReactNode, useMemo } from 'react';
import { request } from '../services/dataService';
import { API_ENDPOINTS } from '../constants/apiEndpoints';
import { useTeams } from './TeamContext';

export interface Schema {
  id: number;
  name: string;
  teamId: number;
  entityType: number;
  schema: string;
  rlp: number;
}

interface SchemaContextType {
  schemas: Schema[];
  isLoading: boolean;
  error: Error | null;
}

const SchemaContext = createContext<SchemaContextType | undefined>(undefined);

export function SchemaProvider({ children }: { children: ReactNode }) {
  const [schemas, setSchemas] = useState<Schema[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState<Error | null>(null);

  useEffect(() => {
    const loadSchemas = async () => {
      try {
        setIsLoading(true);
        const schemaData = await request(API_ENDPOINTS.GET_SCHEMA);
        setSchemas(schemaData);
      } catch (err) {
        console.error('Error loading schemas:', err);
        setError(err instanceof Error ? err : new Error('Failed to load schemas'));
      } finally {
        setIsLoading(false);
      }
    };

    loadSchemas();
  }, []);

  return (
    <SchemaContext.Provider value={{
      schemas,
      isLoading,
      error
    }}>
      {children}
    </SchemaContext.Provider>
  );
}

/**
 * Hook to get schemas filtered by entity type.
 * Always filters by the user's teams by default.
 * 
 * @param entityType filter for entity type (e.g. 2 for accounts, 1 for contacts)
 * @returns Filtered schemas and loading state
 */
export function useSchemas(entityType?: number) {
  const context = useContext(SchemaContext);
  if (context === undefined) {
    throw new Error('useSchemas must be used within a SchemaProvider');
  }

  const { teams } = useTeams();

  // Get schemas for user's teams with filters applied
  const userSchemas = useMemo(() => {
    if (context.isLoading || !teams?.length) return [];

    // Get team IDs the user belongs to
    const userTeamIds = teams.map(team => team.teamId);

    // Filter schemas for user's teams
    let result = context.schemas.filter(schema =>
      schema.teamId === 0 || userTeamIds.includes(schema.teamId));

    // Apply entity type filter if specified
    result = result.filter(schema => schema.entityType === entityType);

    return result;
  }, [context.schemas, context.isLoading, teams, entityType]);

  return {
    schemas: userSchemas,
    isLoading: context.isLoading,
    error: context.error
  };
}
