// filepath: /Users/esun/Documents/Projects/rlpCRM/src/components/common/SchemaSelector.tsx
import { FC, useMemo, useState, useRef, useEffect } from 'react';
import { useSchemas, Schema } from '../../context/SchemaContext';
import { THEME_CLASSES } from '../../constants/themeConstants';

interface SchemaSelectorProps {
  onSchemaChange: (schema: Schema | null) => void;
  entityType?: number;
  label?: string;
  storageKey?: string;
}

const SchemaSelector: FC<SchemaSelectorProps> = ({
  onSchemaChange,
  entityType,
  label,
  storageKey,
}) => {
  const { schemas, isLoading } = useSchemas(entityType);
  const [isOpen, setIsOpen] = useState(false);
  const [selectedSchema, setSelectedSchema] = useState<Schema | null>(null);
  const dropdownRef = useRef<HTMLDivElement>(null);

  // Get unique teams and their schemas
  const teamSchemas = useMemo(() => {
    const teamsMap = new Map<number, Schema>();
    schemas.forEach(schema => {
      // Only add team if it has a schema for the specified entityType
      if (schema.entityType === entityType && !teamsMap.has(schema.teamId)) {
        teamsMap.set(schema.teamId, schema);
      }
    });
    return Array.from(teamsMap.values());
  }, [schemas, entityType]);

  // Initialize selection from localStorage or first schema
  useEffect(() => {
    if (teamSchemas.length > 0) {
      let schema: Schema | null = null;

      if (storageKey) {
        const stored = localStorage.getItem(storageKey);
        if (stored) {
          const foundSchema = teamSchemas.find(s => s.id === Number(stored));
          if (foundSchema) {
            schema = foundSchema;
          }
        }
      }

      if (!schema) {
        schema = teamSchemas[0];
      }

      setSelectedSchema(schema);
      // Only call onSchemaChange if we have a schema
      if (schema) {
        onSchemaChange(schema);
        if (storageKey) {
          localStorage.setItem(storageKey, schema.id.toString());
        }
      }
    }
  }, [storageKey, teamSchemas, onSchemaChange]);

  // Handle click outside
  useEffect(() => {
    function handleClickOutside(event: MouseEvent) {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
        setIsOpen(false);
      }
    }
    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, []);

  if (isLoading) {
    return <div className="text-sm text-gray-500">Loading teams...</div>;
  }

  if (!teamSchemas.length) {
    return <div className="text-sm text-gray-500">No teams available</div>;
  }

  return (
    <div className="flex items-center space-x-2 relative" ref={dropdownRef}>
      {label && (<span className={`text-sm ${THEME_CLASSES.text.secondary}`}>
        {label}
      </span>)}
      <div className="relative">
        <button
          onClick={() => setIsOpen(!isOpen)}
          className={`flex items-center justify-between px-3 py-1 text-xs font-medium border rounded-md 
            ${THEME_CLASSES.input.border} ${THEME_CLASSES.input.background} ${THEME_CLASSES.input.text}
            min-w-[150px] w-full`}
          aria-haspopup="listbox"
          aria-expanded={isOpen}
        >
          <span>{selectedSchema?.name || "Select"}</span>
          <svg
            className={`h-4 w-4 ml-1 ${THEME_CLASSES.text.secondary} transition-transform ${isOpen ? 'rotate-180' : ''}`}
            viewBox="0 0 20 20"
            fill="currentColor"
          >
            <path
              fillRule="evenodd"
              d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
              clipRule="evenodd"
            />
          </svg>
        </button>

        {isOpen && (
          <div
            className={`absolute z-10 mt-1 w-full rounded-md shadow-lg bg-white dark:bg-gray-800 border ${THEME_CLASSES.border}`}
            role="listbox"
          >
            <ul className="py-1 max-h-60 overflow-auto">
              {teamSchemas.map((schema) => (
                <li
                  key={schema.teamId}
                  className={`px-3 py-2 text-xs cursor-pointer ${selectedSchema?.id === schema.id
                    ? `${THEME_CLASSES.button.primary}`
                    : `${THEME_CLASSES.hover.background}`}`}
                  onClick={() => {
                    setSelectedSchema(schema);
                    onSchemaChange(schema);
                    if (storageKey) {
                      localStorage.setItem(storageKey, schema.id.toString());
                    }
                    setIsOpen(false);
                  }}
                >
                  {schema.name}
                </li>
              ))}
            </ul>
          </div>
        )}
      </div>
    </div>
  );
};

export default SchemaSelector;