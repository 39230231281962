import { FC } from 'react';
import { useNavigate } from 'react-router-dom';
import { Account } from '../../models/Account';
import { THEME_CLASSES } from '../../constants/themeConstants';
import { highlightText } from '../../utils/highlightUtils';
import { getAccountDisplayFields } from '../../utils/accountUtils';
import { renderTypeChip } from '../../utils/typeChipUtils';

interface AccountCardProps {
  account: Account;
  keywords: string[];
  onDelete: (id: number) => Promise<void>;
}

const AccountCard: FC<AccountCardProps> = ({ account, keywords, onDelete }) => {
  const navigate = useNavigate();

  const handleDelete = async (e: React.MouseEvent) => {
    e.stopPropagation();
    try {
      await onDelete(account.id);
    } catch (error) {
      console.error('Failed to delete account:', error);
    }
  };

  const handleNotesClick = (e: React.MouseEvent) => {
    e.stopPropagation();
    navigate(`/accounts/${account.id}/notes`);
  };

  return (
    <div
      className={`${THEME_CLASSES.card.background} rounded-xl shadow-lg p-6 ${THEME_CLASSES.card.border} ${THEME_CLASSES.card.hover.full} cursor-pointer`}
      onClick={() => navigate(`/accounts/${account.id}`)}
    >
      <div className="flex flex-col space-y-2">
        <div className="flex justify-between items-start gap-2">
          <h3 className={`text-sm font-semibold ${THEME_CLASSES.text.primary}`}>
            {highlightText(account.name, keywords)}
          </h3>
          <div className="flex items-center gap-2">
            {renderTypeChip({ rlpCompanyId: account.rlpCompanyId, teamId: account.teamId })}
            <button
              onClick={handleNotesClick}
              className="p-1 hover:bg-gray-100 dark:hover:bg-gray-700 rounded-full transition-colors"
              title="View notes"
            >
              <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4 text-gray-500 dark:text-gray-400" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth="2">
                <path strokeLinecap="round" strokeLinejoin="round" d="M9 5H7a2 2 0 00-2 2v12a2 2 0 002 2h10a2 2 0 002-2V7a2 2 0 00-2-2h-2M9 5a2 2 0 002 2h2a2 2 0 002-2M9 5a2 2 0 012-2h2a2 2 0 012 2m-3 7h3m-3 4h3m-6-4h.01M9 16h.01" />
              </svg>
            </button>
            <button
              onClick={handleDelete}
              className="p-1 hover:bg-gray-100 dark:hover:bg-gray-700 rounded-full transition-colors"
              title="Remove account"
            >
              <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4 text-gray-500 dark:text-gray-400" viewBox="0 0 20 20" fill="currentColor">
                <path fillRule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clipRule="evenodd" />
              </svg>
            </button>
          </div>
        </div>

        <div className="text-xs space-y-2">
          {getAccountDisplayFields().map(field => {
            const value = account[field.key];
            if (field.hideEmpty && !value) {
              return null;
            }

            const displayValue = value ?? '-';
            const needsHighlight = typeof value === 'string';

            return (
              <div key={field.key} className="flex justify-between items-start gap-4">
                <span className={`${THEME_CLASSES.text.secondary} shrink-0`}>{field.label}:</span>
                <span className={`${THEME_CLASSES.text.secondary} text-right ${field.key === 'streetAddress' || field.key === 'mailingAddress' ? 'break-words' : ''}`}>
                  {needsHighlight ? highlightText(displayValue.toString(), keywords) : displayValue}
                </span>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default AccountCard;
