interface UserData {
  email: string;
  name: string;
  picture: string;
  accessToken: string;
  refreshToken?: string;
  expiresAt?: number; // Timestamp when token expires
  locale: string;
  emailVerified: boolean;
  isAuthorized?: boolean;
}

let userData: UserData | null = null;

// Queue to track pending requests during token refresh
let refreshInProgress: Promise<void> | null = null;
let queuedRequests: (() => void)[] = [];

// Process queued requests after token refresh
function processQueuedRequests() {
  const requests = [...queuedRequests];
  queuedRequests = [];
  requests.forEach(resolve => resolve());
}

// Parse cookies from document.cookie
function getCookie(name: string): string | null {
  const match = document.cookie.match(new RegExp('(^| )' + name + '=([^;]+)'));
  return match ? decodeURIComponent(match[2]) : null;
}

// Set cookie with options
function setCookie(name: string, value: string, options: Record<string, any> = {}): void {
  let cookie = `${name}=${encodeURIComponent(value)}`;

  if (options.maxAge) {
    cookie += `; max-age=${options.maxAge}`;
  }
  // Only set domain if explicitly provided - avoid automatic domain settings
  if (options.domain) {
    cookie += `; domain=${options.domain}`;
  }
  if (options.path) {
    cookie += `; path=${options.path}`;
  }
  if (options.expires) {
    cookie += `; expires=${options.expires}`;
  }
  if (options.secure) {
    cookie += '; secure';
  }
  if (options.sameSite) {
    cookie += `; samesite=${options.sameSite}`;
  }

  document.cookie = cookie;
}

// Delete a cookie with matching attributes
function deleteCookie(name: string): void {
  // Include all necessary attributes that match how cookies were set
  setCookie(name, '', {
    maxAge: -1,
    path: '/',
    expires: new Date(0).toUTCString(),
    secure: true,
    sameSite: 'Lax'
    // Removed domain attribute to match how cookies were originally set
  });
}

function isTokenExpired(): boolean {
  initializeAuth(); // Make sure we have latest userData

  // Return true if no userData or no expiresAt timestamp
  if (!userData || !userData.expiresAt) {
    return true;
  }

  // Check if token expires in the next 5 minutes
  return Date.now() + 5 * 60 * 1000 >= userData.expiresAt;
}

function handlePostLoginNavigation() {
  const shouldAutoNavigate = localStorage.getItem('shouldAutoNavigate');
  if (shouldAutoNavigate === 'true') {
    localStorage.removeItem('shouldAutoNavigate'); // Clear the flag
    // Use the last attempted URL or default to dashboard
    const lastAttemptedUrl = localStorage.getItem('lastAttemptedUrl') || '/dashboard';
    localStorage.removeItem('lastAttemptedUrl');
    window.location.href = lastAttemptedUrl;
  }
}

function initializeAuth(): void {
  // Check for existing auth data in cookies
  const userDataCookie = getCookie('userData');

  if (userDataCookie) {
    try {
      userData = JSON.parse(userDataCookie);
      // Check for auto-navigation after successful auth
      handlePostLoginNavigation();
    } catch (error) {
      userData = null;
    }
  }
}

function isAuthenticated(): boolean {
  initializeAuth(); // Always check for latest auth data
  // A user is authenticated if userData exists and email and emailVerified
  // We DO NOT check accessToken here as that's only needed for API calls
  // This allows unauthorized users to still be considered "authenticated" and see the access denied page
  return userData !== null &&
    !!userData.email &&
    !!userData.emailVerified;
}

function getUserData(): UserData | null {
  initializeAuth(); // Always check for latest auth data
  return userData;
}

function isAuthorized(): boolean {
  initializeAuth(); // Always check for latest auth data
  return userData !== null && userData.isAuthorized !== false;
}

async function login(): Promise<void> {
  window.location.href = '/api/auth';
}

// Function to dispatch auth refresh event
function dispatchAuthRefreshEvent(): void {
  const event = new CustomEvent('AUTH_REFRESH');
  window.dispatchEvent(event);
}

// Handle service worker messages for token updates
if ('serviceWorker' in navigator) {
  navigator.serviceWorker.addEventListener('message', (event) => {
    if (event.data?.type === 'AUTH_REFRESH' && event.data?.payload) {
      const { accessToken, expiresIn } = event.data.payload;

      // Get latest auth state before updating
      initializeAuth();

      // Early return if user data is invalid
      if (!userData || !accessToken || userData.isAuthorized === false) {
        return;
      }

      // Update user data - at this point we know userData is not null
      const updatedUserData: UserData = {
        ...userData,
        accessToken,
        expiresAt: Date.now() + (expiresIn * 1000)
      };
      
      // Set the updated data
      userData = updatedUserData;

      // Update cookie with new token data
      setCookie('userData', JSON.stringify(updatedUserData), {
        path: '/',
        maxAge: 30 * 24 * 60 * 60, // 30 days
        secure: true,
        sameSite: 'Lax'
      });

      // Dispatch an event to notify components about the auth refresh
      dispatchAuthRefreshEvent();
    }
  });
}

// Initialize auth state when module is loaded
initializeAuth();

function logout(): void {
  // Clear auth-related cookies using our helper function
  // This will respect the exact same path/security settings as when they were set
  deleteCookie('userData');
  deleteCookie('refreshToken'); // Attempt to clear HTTP-only cookie

  // Clear memory
  userData = null;

  // Then call backend to ensure HTTP-only cookies are cleared
  fetch('/api/auth/logout', {
    method: 'POST',
    credentials: 'same-origin',
    headers: {
      'Cache-Control': 'no-cache, no-store'
    }
  })
    .then(response => {
      // Log headers for debugging
      console.log('Logout response status:', response.status);
      // Try to read any debug headers
      const environment = response.headers.get('X-Environment');
      const cookiesCount = response.headers.get('X-Cookies-Count');
      if (environment || cookiesCount) {
        console.log('Logout debug info:', { environment, cookiesCount });
      }
      return response;
    })
    .catch((error) => {
      console.error('Logout API error:', error);
      // Continue with redirect even if API call fails
    })
    .finally(() => {
      // Try localStorage cleanup too, since we're doing a full logout
      try {
        localStorage.removeItem('accounts-columns');
        localStorage.removeItem('accounts-search');
        localStorage.removeItem('accounts-filter');
        localStorage.removeItem('lastAttemptedUrl');
        localStorage.removeItem('shouldAutoNavigate');
      } catch (e) {
        // Ignore errors with localStorage
      }

      // Always redirect to home page after clearing cookies
      window.location.href = '/';
    });
}

async function refreshAccessToken(): Promise<void> {
  // Don't try to refresh tokens for unauthorized users
  if (userData?.isAuthorized === false) {
    throw new Error('User not authorized');
  }

  const MAX_RETRIES = 3;
  const INITIAL_RETRY_DELAY = 1000; // 1 second
  let retryCount = 0;
  let lastError: Error | null = null;

  while (retryCount <= MAX_RETRIES) {
    try {
      const response = await fetch('/api/auth/refresh', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        credentials: 'same-origin', // Important to include credentials for cookies
      });

      if (!response.ok) {
        throw new Error(`Token refresh failed with status: ${response.status}`);
      }

      const { accessToken, expiresIn } = await response.json();

      // Check for valid userData and access token
      if (!userData || !accessToken || userData.isAuthorized === false) {
        throw new Error('User data is no longer valid');
      }

      // Update user data - at this point we know userData is not null
      const updatedUserData: UserData = {
        ...userData,
        accessToken,
        expiresAt: Date.now() + (expiresIn * 1000)
      };
      
      // Set the updated data
      userData = updatedUserData;

      // Update cookie with new token data
      setCookie('userData', JSON.stringify(updatedUserData), {
        path: '/',
        maxAge: 30 * 24 * 60 * 60, // 30 days
        secure: true,
        sameSite: 'Lax'
      });

      // Dispatch an event to notify components about the auth refresh
      dispatchAuthRefreshEvent();

      // Success, exit the retry loop
      return;

    } catch (error) {
      lastError = error instanceof Error ? error : new Error('Unknown error during token refresh');

      // If this was our last retry attempt, break out of the loop
      if (retryCount === MAX_RETRIES) {
        break;
      }

      // Exponential backoff with jitter
      const delay = INITIAL_RETRY_DELAY * Math.pow(2, retryCount) + Math.random() * 1000;
      console.warn(`Token refresh attempt ${retryCount + 1} failed, retrying in ${Math.round(delay)}ms`, lastError);

      // Wait before next retry
      await new Promise(resolve => setTimeout(resolve, delay));
      retryCount++;
    }
  }

  // All retries failed or user data became invalid
  console.error(`Token refresh failed after ${MAX_RETRIES} attempts`, lastError);
  logout(); // Clear invalid auth state
  throw lastError;
}

// Wrap API calls with auth handling
async function withAuth<T>(apiCall: () => Promise<T>): Promise<T> {
  // If a refresh is already in progress, queue this request
  if (refreshInProgress) {
    await new Promise<void>(resolve => queuedRequests.push(resolve));
  }

  try {
    return await apiCall();
  } catch (error) {
    if (error instanceof Error && error.message.includes('401')) {
      // Only initiate a refresh if one isn't already in progress
      if (!refreshInProgress) {
        refreshInProgress = refreshAccessToken()
          .finally(() => {
            refreshInProgress = null;
            processQueuedRequests();
          });
        await refreshInProgress;
      } else {
        // Wait for the existing refresh to complete
        await refreshInProgress;
      }
      // Retry the API call after refresh
      return apiCall();
    }
    throw error;
  }
}

async function getAuthHeaders(): Promise<HeadersInit> {
  if (!userData) {
    window.location.href = '/api/auth';
    throw new Error('Unauthorized');
  }

  // Don't try to refresh tokens for unauthorized users
  if (userData.isAuthorized === false) {
    throw new Error('User not authorized');
  }

  // Store userData in a local variable that we've already null checked
  const user = userData;
  
  return withAuth(async () => {
    if (!user) {
      throw new Error('Unauthorized - user data not found');
    }
    
    // Only refresh if token is expired or about to expire
    if (isTokenExpired()) {
      await refreshAccessToken();
      // Get fresh user data after refresh
      if (!userData) {
        throw new Error('Unauthorized - user data lost after refresh');
      }
      return {
        'X-Google-Auth': `Bearer ${userData.accessToken}`
      };
    }

    return {
      'X-Google-Auth': `Bearer ${user.accessToken}`
    };
  });
}

async function handleResponse(response: Response) {
  if (response.status === 401 || (response.status === 404 && !isAuthenticated())) {
    // Set navigation flag before redirecting to auth
    localStorage.setItem('shouldAutoNavigate', 'true'); // Set to auto navigate after auth
    window.location.href = '/api/auth';
    localStorage.setItem('lastAttemptedUrl', window.location.pathname);
    throw new Error(response.status === 401 ? 'Unauthorized' : 'Not Found - Redirecting to auth');
  }
  if (!response.ok) {
    throw new Error(`Request failed with status ${response.status}`);
  }

  try {
    const text = await response.text();
    // Return null for empty responses
    if (!text) return null;
    // Try to parse as JSON
    return JSON.parse(text);
  } catch (error) {
    // If parsing fails, return null
    return null;
  }
}

export type { UserData };
export {
  isAuthenticated,
  isAuthorized,
  getUserData,
  login,
  logout,
  getAuthHeaders,
  handleResponse,
  deleteCookie,
  withAuth,
};
