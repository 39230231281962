import { z } from "zod";
import { DataRoutes } from "./types";

const accountRoutes: DataRoutes = {

  // Quick search accounts
  'get /api/data/accounts/search': {
    schema: z.object({
      q: z.string()
    }),
    query: `SELECT TOP 10
      a.id,
      a.rlpCompanyId,
      ISNULL(a.name, [CompanyName]) as name

    FROM [dbo].accounts a
    LEFT JOIN [dbo].[RLPCompanies] b ON a.rlpCompanyId = b.CompanyID
    WHERE  a.rlpCompanyId is NOT NULL OR a.teamId in (SELECT teamId FROM CurrentUserAndTeams())
    AND (a.name LIKE '%' + @q + '%' OR [CompanyName] LIKE '%' + @q + '%')`,
  },

  // Get list of all accounts
  'get /api/data/accounts': {
    query: `SELECT
      a.id,
      a.name,
      dbo.MergeJsonObjects(a.data, (SELECT *
        FROM RLPCompanies
        WHERE RLPCompanies.CompanyId = a.rlpCompanyId
        FOR JSON PATH, WITHOUT_ARRAY_WRAPPER)) as data,
      a.rlpCompanyId,
      a.teamId,
      count(b.noteId) as noteCount
    FROM [dbo].accounts a
    LEFT JOIN NoteAccounts b ON a.id = b.accountId
    WHERE  a.rlpCompanyId is NOT NULL OR a.teamId in (SELECT teamId FROM CurrentUserAndTeams())
    GROUP BY a.id, a.name, a.data, a.rlpCompanyId, a.teamId`,
  },

  // Get details of a specific account
  'get /api/data/accounts/:id': {
    schema: z.object({
      id: z.number().int(),
    }),
    queryOne: `SELECT
      a.id,
      a.name,
      a.rlpCompanyId,
      a.teamId,
      a.createdBy,
      dbo.MergeJsonObjects(a.data, (SELECT *
        FROM RLPCompanies
        WHERE RLPCompanies.CompanyId = a.rlpCompanyId
        FOR JSON PATH, WITHOUT_ARRAY_WRAPPER)) as data
    FROM [dbo].accounts a
    WHERE (a.teamId is NULL OR a.teamId in (SELECT teamId FROM CurrentUserAndTeams()))
    AND id = @id`,
  },

  // Create a new account with provided details
  'post /api/data/accounts': {
    schema: z.object({
      name: z.string(),
      data: z.string(),
      teamId: z.number()
    }),
    query: `INSERT INTO [dbo].[Accounts] (name, data, teamId, createdBy)
    VALUES (@name, @data, @teamId, dbo.fnGetContextUser())`
  },
  // Update specified fields of an existing account
  'patch /api/data/accounts/:id': {
    schema: z.object({
      id: z.number().int(),
      name: z.string().optional(),
      data: z.string().optional()
    }),
    query: `UPDATE [dbo].[Accounts]
            SET 
                data = dbo.MergeJsonObjects(data, @data),
                updatedBy = dbo.fnGetContextUser(),
                updatedAt = GETDATE()
            WHERE id = @id`
  },

  // Get user accounts
  'get /api/data/users/:userId/accounts': {
    schema: z.object({
      userId: z.number().int(),
    }),
    query: `SELECT
      a.id,
      a.name,
      dbo.MergeJsonObjects(a.data, (SELECT *
        FROM RLPCompanies
        WHERE RLPCompanies.CompanyId = a.rlpCompanyId
        FOR JSON PATH, WITHOUT_ARRAY_WRAPPER)) as data,
      a.rlpCompanyId,
      a.teamId,
      count(b.noteId) as noteCount
    FROM [dbo].accounts a
    LEFT JOIN NoteAccounts b ON a.id = b.accountId
    JOIN [UserAccounts] ua ON a.id = ua.accountId
    WHERE (a.teamId is NULL OR a.teamId in (SELECT teamId FROM CurrentUserAndTeams()))
    AND userId = @userId
    GROUP BY a.id, a.name, a.data, a.rlpCompanyId, a.teamId
    `
  },

  // Get user accounts
  'get /api/data/team/:teamId/accounts': {
    schema: z.object({
      teamId: z.number().int(),
    }),
    query: `SELECT
      a.id,
      a.name,
      CASE 
        WHEN @teamId = 0 THEN dbo.MergeJsonObjects(a.data, (SELECT *
          FROM RLPCompanies
          WHERE RLPCompanies.CompanyId = a.rlpCompanyId
          FOR JSON PATH, WITHOUT_ARRAY_WRAPPER))
        ELSE a.data
      END as data,
      a.rlpCompanyId,
      a.teamId,
      count(b.noteId) as noteCount
    FROM [dbo].accounts a
    LEFT JOIN NoteAccounts b ON a.id = b.accountId
    WHERE (a.teamId IS NULL OR a.teamId in (SELECT teamId FROM CurrentUserAndTeams()))
    AND (@teamId = 0 AND a.teamId IS NULL OR @teamId > 0 AND a.teamId = @teamId)
    GROUP BY a.id, a.name, a.data, a.rlpCompanyId, a.teamId
    `
  },


  // Delete an account by its ID
  'delete /api/data/accounts/:id': {
    schema: z.object({
      id: z.number().int(),
    }),
    query: `DELETE FROM [dbo].[Accounts] WHERE id = @id
    AND createdBy = dbo.fnGetContextUser()
    `
  },

  // Get pipeline and opportunities for an account
  'get /api/data/accounts/:id/pipelines': {
    queryOne: `
    SELECT
      P.*
    FROM [dbo].[Pipelines] P
    JOIN [dbo].[Accounts] A ON P.teamId = A.teamId
    WHERE A.Id = @id
    `
  }
};

export default accountRoutes;
