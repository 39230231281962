import React from 'react';
import { THEME_CLASSES } from '../constants/themeConstants';

export interface RenderTypeChipProps {
  rlpCompanyId: number;
  teamId: number;
  rlpPersonId?: number;
}

interface TypeInfo {
  type: string;
  className: string;
}

function getEntityTypeInfo({ rlpCompanyId, teamId, rlpPersonId }: RenderTypeChipProps): TypeInfo {
  if (!!rlpCompanyId || !!rlpPersonId) {
    return {
      type: 'RLP',
      className: 'bg-red-100 dark:bg-red-900'
    };
  }
  if (teamId === 7) {
    return {
      type: 'Commercial',
      className: 'bg-green-100 dark:bg-green-900'
    };
  }
  if (teamId === 6) {
    return {
      type: 'Prospect',
      className: 'bg-blue-100 dark:bg-blue-900'
    };
  }
  return {
    type: '',
    className: 'bg-blue-100 dark:bg-blue-900'
  };
}

export const renderTypeChip: React.FC<RenderTypeChipProps> = ({ rlpCompanyId, teamId, rlpPersonId }) => {
  const { type, className } = getEntityTypeInfo({ rlpCompanyId, teamId, rlpPersonId });
  return (
    <span className={`${THEME_CLASSES.text.secondary} text-xs px-2 py-0.5 rounded-full ${className}`}>
      {type}
    </span>
  );
};