import { useNavigate } from 'react-router-dom';
import { useTeams } from '../../context/TeamContext';
import { request } from '../../services/dataService';
import { API_ENDPOINTS } from '../../constants/apiEndpoints';
import EntityTypeahead, { EntityTypeaheadResult } from '../common/EntityTypeahead';
import React from 'react';

interface QuickSearchProps {
  placeholder?: string;
  className?: string;
}

const QuickSearch: React.FC<QuickSearchProps> = ({
  placeholder = 'Search contacts and accounts...',
  className = ''
}) => {
  const navigate = useNavigate();
  const { currentUserId } = useTeams();
  const handleSelect = async (result: EntityTypeaheadResult) => {
    try {
      // Handle Account selection
      if (result.type === 'Account' && result.id) {
        // await request(API_ENDPOINTS.POST_USER_ACCOUNT, { userId: currentUserId, id: result.id });
        navigate(`/accounts/${result.id}`, { replace: true });
        return;
      }

      // Handle Contact selection
      if (result.type === 'Contact') {
        // Navigate to existing contact
        if (result.id) {
          // await request(API_ENDPOINTS.POST_USER_CONTACT, { userId: currentUserId, id: result.id });
          navigate(`/contacts/${result.id}`, { replace: true });
          return;
        }

        // Create and navigate to new contact
        if (result.personId) {
          const newContact = {id: (result.personId + 99000000)};//await request(API_ENDPOINTS.PUT_CONTACT, { personId: result.personId });
          if (newContact?.id) {
            // await request(API_ENDPOINTS.POST_USER_CONTACT, { userId: currentUserId, id: newContact.id });
            navigate(`/contacts/${newContact.id}`, { replace: true });
          }
          return;
        }
      }
    } catch (err) {
      console.error('Error handling selection:', err);
    }
  };

  return (
    <EntityTypeahead
      onSelect={handleSelect}
      placeholder={placeholder}
      className={className}
    />
  );
};

export default QuickSearch;
