import { Account } from '../../models/Account';
import { request } from '../../services/dataService';
import { API_ENDPOINTS } from '../../constants/apiEndpoints';
import SchemaBasedDetails from '../common/SchemaBasedDetails';
import { useNotification } from '../../context/NotificationContext';
import { useTeams } from '../../context/TeamContext';
import { useSchemas } from '../../context/SchemaContext';

interface AccountDetailsProps {
  account: Account;
}

const AccountDetails: React.FC<AccountDetailsProps> = ({ account }) => {
  const { showNotification } = useNotification();
  const { currentUserId } = useTeams();
  const { schemas } = useSchemas(2); // Type 2 for accounts

  const filteredSchemas = schemas.filter(schema => 
    schema.teamId === account.teamId || (!account.teamId && schema.rlp)
  );

  const handleSubmit = async (data: any) => {
    try {
      await request(API_ENDPOINTS.PATCH_ACCOUNT, {
        id: account.id,
        name: data.name || '',
        data: JSON.stringify(data),
      });
      showNotification('Account updated successfully', 'success');
    } catch (error) {
      console.error('Error updating account:', error);
      showNotification('Failed to update account', 'error');
      throw error;
    }
  };

  const handleDelete = async () => {
    if (window.confirm('Are you sure you want to delete this account? This action cannot be undone.')) {
      try {
        await request(API_ENDPOINTS.DELETE_ACCOUNT, { id: account.id });
        showNotification('Account deleted successfully', 'success');
        window.location.href = '/accounts';
      } catch (error) {
        console.error('Error deleting account:', error);
        showNotification('Failed to delete account', 'error');
      }
    }
  };

  const handleCancel = () => {
    if (window.confirm('Are you sure you want to cancel? Any unsaved changes will be lost.')) {
      window.location.reload();
    }
  };

  return (
    <SchemaBasedDetails
      schemas={filteredSchemas}
      data={account}
      onSubmit={handleSubmit}
      onDelete={handleDelete}
      onCancel={handleCancel}
      isDeleteEnabled={account.createdBy === currentUserId}
      isDisabled={(schema) => schema.teamId === 0}
    />
  );
};

export default AccountDetails;
