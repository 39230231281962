import { highlightText } from './highlightUtils';
import React from 'react';
import { renderTypeChip, RenderTypeChipProps } from './typeChipUtils';

export const createHighlightCellRenderer = (searchQuery: string) => {
  return (params: { value: any }) => {
    const value = params.value?.toString() || '';
    const keywords = searchQuery.split(' ').filter(Boolean);
    return highlightText(value, keywords);
  };
};

export const createNameCellRenderer = (searchQuery: string) => {
  return (params: { value: any; data: { rlpCompanyId?: string | null; teamId?: string | null } }) => {
    const value = params.value?.toString() || '';
    const keywords = searchQuery.split(' ').filter(Boolean);
    const rlpCompanyId = params.data.rlpCompanyId ? Number(params.data.rlpCompanyId) : 0;
    const teamId = params.data.teamId ? Number(params.data.teamId) : 0;

    return (
      <div className="flex items-center gap-2">
        {renderTypeChip({ rlpCompanyId, teamId })}
        <span>{highlightText(value, keywords)}</span>
      </div>
    );
  };
};

export const createAddressCellRenderer = (searchQuery: string) => {
  return (params: { value: any }) => {
    const address = params.value;
    if (!address || typeof address !== 'string') return '';
    const cleanAddress = address.replace(/\s+/g, ' ').trim().replace(/[,\s]+$/, '');
    const keywords = searchQuery.split(' ').filter(Boolean);
    return highlightText(cleanAddress, keywords);
  };
};
