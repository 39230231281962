import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { THEME_CLASSES } from '../constants/themeConstants';
import { deleteCookie } from '../services/authService';

export default function AccessDenied() {
  const [email, setEmail] = useState('');
  const location = useLocation();

  useEffect(() => {
    // Extract email from URL parameters
    const params = new URLSearchParams(location.search);
    const emailParam = params.get('email');
    if (emailParam) {
      setEmail(emailParam);
    }

    // Clear auth state
    const clearAuthState = async () => {
      // Clear cookies using the same function as in authService
      deleteCookie('userData');
      deleteCookie('refreshToken');

      // Clear HTTP-only cookie through API
      try {
        await fetch('/api/auth/logout', {
          method: 'POST',
          credentials: 'same-origin'
        });
      } catch (err) {
        console.error('Error clearing auth cookies:', err);
      }

      // Remove any localStorage items for backwards compatibility
      localStorage.removeItem('userData');
      localStorage.removeItem('codeVerifier');
    };

    clearAuthState();
  }, [location.search]);

  return (
    <div className={`flex flex-col items-center justify-center min-h-screen p-8 ${THEME_CLASSES.background}`}>
      <div className={`bg-white dark:bg-gray-800 p-8 rounded-lg shadow-lg max-w-md w-full text-center border ${THEME_CLASSES.border}`}>
        <div className="text-red-500 mb-4">
          <svg xmlns="http://www.w3.org/2000/svg" className="h-16 w-16 mx-auto" fill="none" viewBox="0 0 24 24" stroke="currentColor">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z" />
          </svg>
        </div>
        <h1 className="text-2xl font-bold mb-4 text-gray-800 dark:text-white">Access Denied</h1>
        <p className="text-gray-600 dark:text-gray-300 mb-6">
          Your account ({email || 'Unknown email'}) does not have permission to access this system.
        </p>
        <p className="text-gray-600 dark:text-gray-300 mb-6">
          Please contact your administrator to request access.
        </p>
        <div className="mt-8">
          <a
            href="/"
            className="px-4 py-2 bg-blue-500 hover:bg-blue-600 text-white rounded-md transition-colors duration-300"
          >
            Return to Login
          </a>
        </div>
      </div>
    </div>
  );
}