export interface Note {
  id: number;
  title: string;
  content: string;
  type: string;
  teamId: number;
  teamIds: string;
  createdBy: number;
  createdAt: Date;
  updatedBy: number;
  updatedAt: Date;
  contactId?: number;
  opportunityId?: number;
  accountId?: number;
  contactid?: number;
  objectIds: string; // Added new property for storing related object IDs
  author?: string;
  objectType: 'account' | 'contact' | 'lead' | 'opportunity';
  accountName?: string; // Name of associated account
  contactName?: string; // Name of associated contact
}

export const NOTE_STATUSES = ['', 'To Do', 'In Progress', 'Done'];

export const NOTE_PRIORITIES = ['', 'Low', 'Medium', 'High'];

export const NOTE_TYPES = ['general', 'phone call', 'email', 'in person', 'voice mail', 'meeting'];
export const NOTE_TYPE_COLORS = {
  '': 'bg-gray-600 text-white',
  'email': 'bg-blue-600 text-white',
  'call': 'bg-purple-600 text-white',
  'meeting': 'bg-green-600 text-white',
  'in person': 'bg-teal-600 text-white',
  'voice mail': 'bg-indigo-600 text-white'
};

export const NOTE_TYPE_ICONS = {
  '': `<svg className="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24">
    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 12h6m-6 4h6m2 5H7a2 2 0 01-2-2V5a2 2 0 012-2h5.586a1 1 0 01.707.293l5.414 5.414a1 1 0 01.293.707V19a2 2 0 01-2 2z" />
  </svg>`,
  'email': `<svg className="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24">
    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M3 8l7.89 5.26a2 2 0 002.22 0L21 8M5 19h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z" />
  </svg>`,
  'call': `<svg className="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24">
    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M3 5a2 2 0 012-2h3.28a1 1 0 01.948.684l1.498 4.493a1 1 0 01-.502 1.21l-2.257 1.13a11.042 11.042 0 005.516 5.516l1.13-2.257a1 1 0 011.21-.502l4.493 1.498a1 1 0 01.684.949V19a2 2 0 01-2 2h-1C9.716 21 3 14.284 3 6V5z" />
  </svg>`,
  'meeting': `<svg className="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24">
    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M8 7V3m8 4V3m-9 8h10M5 21h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v12a2 2 0 002 2z" />
  </svg>`,
  'in person': `<svg className="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24">
    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M17 20h5v-2a3 3 0 00-5.356-1.857M17 20H7m10 0v-2c0-.656-.126-1.283-.356-1.857M7 20H2v-2a3 3 0 015.356-1.857M7 20v-2c0-.656.126-1.283.356-1.857m0 0a5.002 5.002 0 019.288 0M15 7a3 3 0 11-6 0 3 3 0 016 0zm6 3a2 2 0 11-4 0 2 2 0 014 0zM7 10a2 2 0 11-4 0 2 2 0 014 0z" />
  </svg>`,
  'voice mail': `<svg className="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24">
    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M19 11a7 7 0 01-7 7m0 0a7 7 0 01-7-7m7 7v4m0 0H8m4 0h4m-4-8a3 3 0 01-3-3V5a3 3 0 116 0v6a3 3 0 01-3 3z" />
  </svg>`
};

export const NOTE_VISIBILITY_OPTIONS = [
  { value: 'me', label: 'Me Only', icon: '👤' },
  { value: 'team', label: 'My Team', icon: '👥' },
  { value: 'organization', label: 'Organization', icon: '🌎' }
];

export interface NoteComponentProps {
  type: 'contact' | 'account' | 'opportunity';
  id: number;
}
