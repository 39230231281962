import { type FormField } from '../components/common/FormField';

const sexOptions = [
  { label: 'Male', value: 'Male' },
  { label: 'Female', value: 'Female' },
  { label: 'Other', value: 'Other' },
  { label: 'Prefer not to say', value: 'Prefer not to say' }
];

const roleOptions = [
  { label: 'Broker', value: 'Broker' },
  { label: 'Agent', value: 'Agent' },
  { label: 'Not Licensed', value: 'Not Licensed' }
];

const languageOptions = [
  { label: 'English', value: 'English' },
  { label: 'French', value: 'French' }
];

const provinceOptions = [
  { label: 'Alberta', value: 'Alberta' },
  { label: 'British Columbia', value: 'British Columbia' },
  { label: 'Manitoba', value: 'Manitoba' },
  { label: 'New Brunswick', value: 'New Brunswick' },
  { label: 'Newfoundland and Labrador', value: 'Newfoundland and Labrador' },
  { label: 'Northwest Territories', value: 'Northwest Territories' },
  { label: 'Nova Scotia', value: 'Nova Scotia' },
  { label: 'Nunavut', value: 'Nunavut' },
  { label: 'Ontario', value: 'Ontario' },
  { label: 'Prince Edward Island', value: 'Prince Edward Island' },
  { label: 'Quebec', value: 'Quebec' },
  { label: 'Saskatchewan', value: 'Saskatchewan' },
  { label: 'Yukon', value: 'Yukon' }
];

const yesNoOptions = [
  { label: 'Yes', value: 'Yes' },
  { label: 'No', value: 'No' }
];

export const CRMAccountSchema: FormField[] = [
  { name: 'dba', label: 'Doing Business As', type: 'text', required: true },
  { name: 'legalName', label: 'Legal Name', type: 'text', required: true },
  { name: 'brand', label: 'Brand', type: 'text', required: true },
  { name: 'contractDate', label: 'Contract Date', type: 'date', required: true },
  { name: 'phoneNumber', label: 'Phone Number', type: 'tel', required: true },
  { name: 'website', label: 'Website', type: 'url', required: false },
  { name: 'socialSites', label: 'Social Sites', type: 'text', required: false, placeholder: 'Enter social media sites separated by commas' },
  { name: 'board', label: 'Board', type: 'text', required: true },
  { name: 'targetDateOfConversion', label: 'Target Date of Conversion', type: 'date', required: true },
  { name: 'isActive', label: 'Active', type: 'select', required: true, options: yesNoOptions },
  { name: 'streetAddress', label: 'Street Address', type: 'text', required: true },
  { name: 'city', label: 'City', type: 'text', required: true },
  { name: 'province', label: 'Province', type: 'select', required: true, options: provinceOptions },
  { name: 'mailingAddress', label: 'Mailing Address', type: 'text', required: true },
  { name: 'numberOfAgents', label: 'Number of Agents', type: 'number', required: true },
];

export const CRMContactSchema: FormField[] = [
  { name: 'firstName', label: 'First Name', type: 'text', required: true },
  { name: 'lastName', label: 'Last Name', type: 'text', required: true },
  { name: 'displayName', label: 'Display Name', type: 'text', required: true },
  { name: 'preferredLanguage', label: 'Preferred Language', type: 'select', options: languageOptions, required: true },
  { name: 'birthday', label: 'Birthday', type: 'date', required: false },
  { name: 'sex', label: 'Sex', type: 'select', options: sexOptions, required: true },
  { name: 'title', label: 'Title', type: 'text', required: false },
  { name: 'owner', label: 'Owner', type: 'select', required: true, options: yesNoOptions },
  { name: 'sharesPercentage', label: 'Shares Percentage', type: 'number', required: true },
  { name: 'role', label: 'Role', type: 'select', options: roleOptions, required: true },
  { name: 'preferredEmail', label: 'Preferred Email', type: 'email', required: true },
  { name: 'alternateEmail', label: 'Alternate Email', type: 'email', required: false },
  { name: 'cellPhoneNumber', label: 'Cell Phone', type: 'tel', required: false },
  { name: 'officePhoneNumber', label: 'Office Phone', type: 'tel', required: false },
  { name: 'otherPhoneNumber', label: 'Other Phone', type: 'tel', required: false },
  { name: 'website', label: 'Website', type: 'url', required: false },
  { name: 'socialMediaLinks', label: 'Social Media Links', type: 'text', required: false },
  { name: 'additionalInformation', label: 'Additional Information', type: 'textarea', required: false },
];

export const FieldOptions = {
  sexOptions,
  roleOptions,
  languageOptions,
  provinceOptions
}


export interface Schema {
  name: string;
  schema: string;
  fields: FormField[];
  entityType: number;
}
