import { FC, useState, useEffect, useCallback, useMemo, useRef } from 'react';
import { AgGridReact } from 'ag-grid-react';
import { highlightText } from '../../utils/highlightUtils';
import { THEME_CLASSES } from '../../constants/themeConstants';
import { request } from '../../services/dataService';
import { API_ENDPOINTS } from '../../constants/apiEndpoints';
import {
  GridStateModule,
  ColDef,
  ModuleRegistry,
  ClientSideRowModelModule,
  ValidationModule,
  TextFilterModule,
  NumberFilterModule,
  DateFilterModule,
  EventApiModule,
  ColumnApiModule,
  ExternalFilterModule,
  SelectEditorModule,
  TextEditorModule,
  DateEditorModule,
  RenderApiModule,
  RowApiModule,
  ICellRendererParams,
  GridApi,
  LargeTextEditorModule,
  RowAutoHeightModule
} from 'ag-grid-community';
import { Opportunity } from '../../models/Opportunity';

// Register modules
ModuleRegistry.registerModules([
  ClientSideRowModelModule,
  GridStateModule,
  ValidationModule,
  TextFilterModule,
  NumberFilterModule,
  DateFilterModule,
  EventApiModule,
  ColumnApiModule,
  ExternalFilterModule,
  SelectEditorModule,
  TextEditorModule,
  DateEditorModule,
  RenderApiModule,
  RowApiModule,
  LargeTextEditorModule,
  RowAutoHeightModule
]);

// Interface for props
interface OpportunityGridProps {
  opportunities: Opportunity[];
  onEditOpportunity: (opportunity: Opportunity) => void;
  searchQuery: string;
  columnDefs: ColDef<Opportunity>[];
  onColumnStateChanged?: (newDefs: ColDef<Opportunity>[]) => void;
}

// Main grid component
const OpportunityGrid: FC<OpportunityGridProps> = ({
  opportunities,
  onEditOpportunity,
  searchQuery,
  columnDefs,
  onColumnStateChanged,
}) => {
  const [globalFilter, setGlobalFilter] = useState<string[]>([]);
  const gridApi = useRef<GridApi | null>(null);

  const defaultColDef: ColDef<Opportunity> = useMemo(() => {
    return {
      sortable: true,
      filter: true,
      resizable: true,
      editable: true,
      minWidth: 100,
      maxWidth: 1000,
      suppressSizeToFit: true,
      suppressAutoSize: true,
      menuTabs: [],
      wrapText: true,
      autoHeight: true,
      hide: false,
      field: undefined,
      valueSetter: (params) => {
        const field = params.colDef.field;
        if (!field) return false;
        params.data[field] = params.newValue;
        return true;
      },
      cellEditor: 'agTextCellEditor',
      valueFormatter: (params) => {
        if (params.colDef.type === 'date' && params.value) {
          return new Date(params.value).toLocaleDateString();
        }
        return params.value;
      }
    };
  }, []);

  useEffect(() => {
    const keywords = searchQuery ? searchQuery.toLowerCase().split(' ').filter(Boolean) : [];
    setGlobalFilter(keywords);
  }, [searchQuery]);

  const isExternalFilterPresent = useCallback(() => globalFilter.length > 0, [globalFilter]);

  const doesExternalFilterPass = useCallback((node: any) => {
    if (!globalFilter.length) return true;

    // Priority filtering on title
    const title = node.data.title ? node.data.title.toLowerCase() : '';
    const titleMatch = globalFilter.every(keyword => title.includes(keyword));
    if (titleMatch) return true;

    // Fall back to global search across all fields
    const searchableText = Object.values(node.data)
      .map(value => value ? String(value).toLowerCase() : '')
      .join(' ');
    return globalFilter.every(keyword => searchableText.includes(keyword));
  }, [globalFilter]);

  const updateColumnState = useCallback(() => {
    if (!gridApi.current || !onColumnStateChanged) return;
    
    // Get the current column state including width, position, etc.
    const columnState = gridApi.current.getColumnState();
    const currentColumnDefs = gridApi.current.getColumnDefs() as ColDef<Opportunity>[];
    
    if (columnState && currentColumnDefs) {
      // Merge column state with column definitions
      const updatedDefs = currentColumnDefs.map(colDef => {
        const state = columnState.find(s => s.colId === (colDef.field || colDef.colId));
        if (state) {
          return {
            ...colDef,
            width: state.width,
            hide: state.hide,
            pinned: state.pinned,
            sort: state.sort,
            sortIndex: state.sortIndex
          } as ColDef<Opportunity>;
        }
        return colDef;
      });
      
      onColumnStateChanged(updatedDefs);
    }
  }, [onColumnStateChanged]);

  const onColumnMoved = useCallback(() => {
    updateColumnState();
  }, [updateColumnState]);

  const onColumnResized = useCallback(() => {
    updateColumnState();
  }, [updateColumnState]);

  const onColumnVisible = useCallback(() => {
    updateColumnState();
  }, [updateColumnState]);

  const onGridReady = useCallback((params: { api: GridApi }) => {
    gridApi.current = params.api;
  }, []);

  // Refresh grid when columns change
  useEffect(() => {
    if (gridApi.current) {
      // Allow the column definitions to be applied
      setTimeout(() => {
        gridApi.current?.refreshCells({ force: true });
      }, 0);
    }
  }, [columnDefs]);

  // Custom title cell renderer component
  const TitleCellRenderer = useCallback((props: ICellRendererParams<Opportunity, any>) => {
    const data = props.data as Opportunity;
    if (!data) return null;

    const title = data.title || '';
    const content = globalFilter.length > 0 ?
      highlightText(title, globalFilter) :
      title;

    return (
      <div className="flex items-center gap-2">
        <div className="flex-shrink-0 flex items-center gap-1">
          <button
            onClick={(e) => {
              e.stopPropagation();
              const eventTitle = `RE: ${title}`;
              const calendarUrl = `https://calendar.google.com/calendar/u/0/r/eventedit?text=${encodeURIComponent(eventTitle)}`;
              window.open(calendarUrl, '_blank');
            }}
            className={`p-1 ${THEME_CLASSES.hover.background} rounded`}
            title="Add to Google Calendar"
          >
            <svg xmlns="http://www.w3.org/2000/svg" className={`h-4 w-4 ${THEME_CLASSES.text.secondary}`} viewBox="0 0 24 24" fill="none" stroke="currentColor">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M8 7V3m8 4V3m-9 8h10M5 21h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v12a2 2 0 002 2z" />
            </svg>
          </button>
          <button
            onClick={(e) => {
              e.stopPropagation();
              const subject = `RE: ${title}`;
              const gmailUrl = `https://mail.google.com/mail/?view=cm&fs=1&su=${encodeURIComponent(subject)}`;
              window.open(gmailUrl, '_blank');
            }}
            className={`p-1 ${THEME_CLASSES.hover.background} rounded`}
            title="Compose Gmail"
          >
            <svg xmlns="http://www.w3.org/2000/svg" className={`h-4 w-4 ${THEME_CLASSES.text.secondary}`} viewBox="0 0 24 24" fill="none" stroke="currentColor">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M3 8l7.89 5.26a2 2 0 002.22 0L21 8M5 19h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z" />
            </svg>
          </button>
          <button
            onClick={(e) => {
              e.stopPropagation();
              onEditOpportunity(data);
            }}
            className={`p-1 ${THEME_CLASSES.hover.background} rounded`}
            title="Edit Opportunity"
          >
            <svg xmlns="http://www.w3.org/2000/svg" className={`h-4 w-4 ${THEME_CLASSES.text.secondary}`} fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M11 5H6a2 2 0 00-2 2v11a2 2 0 002 2h11a2 2 0 002-2v-5m-1.414-9.414a2 2 0 112.828 2.828L11.828 15H9v-2.828l8.586-8.586z" />
            </svg>
          </button>
          <button
            onClick={(e) => {
              e.stopPropagation();
              if (data.contactId && data.contactId > 0) {
                window.location.href = `/contacts/${data.contactId}/notes`;
              } else if (data.accountId && data.accountId > 0) {
                window.location.href = `/accounts/${data.accountId}/notes`;
              }
            }}
            className={`p-1 ${THEME_CLASSES.hover.background} rounded`}
            title="View Notes"
          >
            <svg xmlns="http://www.w3.org/2000/svg" className={`h-4 w-4 ${THEME_CLASSES.text.secondary}`} viewBox="0 0 20 20" fill="currentColor">
              <path fillRule="evenodd" d="M4 4a2 2 0 012-2h4.586A2 2 0 0112 2.586L15.414 6A2 2 0 0116 7.414V16a2 2 0 01-2 2H6a2 2 0 01-2-2V4zm2 6a1 1 0 011-1h6a1 1 0 110 2H7a1 1 0 01-1-1zm1 3a1 1 0 100 2h6a1 1 0 100-2H7z" clipRule="evenodd" />
            </svg>
          </button>
        </div>
        <span className="overflow-hidden text-ellipsis whitespace-nowrap cursor-pointer hover:underline">
          {content}
        </span>
      </div>
    );
  }, [globalFilter, onEditOpportunity]);

  // Find title column and apply custom cell renderer
  const enhancedColumnDefs = useMemo(() => {
    return columnDefs.map(col => {
      if (col.colId === 'title' || col.field === 'title') {
        return {
          ...col,
          valueGetter: (params: { data?: Opportunity }) => params.data?.title,
          cellRenderer: TitleCellRenderer,
          cellRendererParams: {
            onEditOpportunity,
            searchQuery,
            globalFilter
          }
        };
      }
      return col;
    });
  }, [columnDefs, TitleCellRenderer, searchQuery, globalFilter, onEditOpportunity]);

  return (
    <div style={{ height: 'calc(100vh - 200px)', width: '100%' }}>
      <div className="h-full w-full ag-theme-alpine dark:ag-theme-alpine-dark">
        <AgGridReact<Opportunity>
          columnDefs={enhancedColumnDefs}
          rowData={opportunities}
          defaultColDef={defaultColDef}
          suppressColumnMoveAnimation={true}
          columnTypes={{
            text: {
              filter: 'agTextColumnFilter',
              cellEditor: 'agTextCellEditor'
            },
            textarea: {
              filter: 'agTextColumnFilter',
              cellEditor: 'agLargeTextCellEditor',
              cellEditorPopup: true,
              cellEditorParams: {
                maxLength: 2000,
                rows: 10,
                cols: 50
              }
            },
            number: {
              filter: 'agNumberColumnFilter',
              cellEditor: 'agTextCellEditor'
            },
            date: {
              filter: 'agDateColumnFilter',
              cellEditor: 'agDateCellEditor',
              cellEditorParams: {
                browserDatePicker: true
              },
              valueFormatter: (params) => params.value ? new Date(params.value).toLocaleDateString() : ''
            },
            select: {
              filter: 'agTextColumnFilter',
              cellEditor: 'agSelectCellEditor'
            }
          }}
          onGridReady={onGridReady}
          onCellValueChanged={async (event) => {
            if (!event.colDef.colId) return;
            const updatedOpportunity = { ...event.data };
            updatedOpportunity[event.colDef.colId] = event.newValue;

            // Update the row in the grid
            const rowNode = event.api.getRowNode(String(updatedOpportunity.id));
            if (rowNode) {
              rowNode.setData(updatedOpportunity);
            }

            // Update the database
            try {
              await request(API_ENDPOINTS.PATCH_OPPORTUNITY_DATA, {
                id: updatedOpportunity.id,
                data: JSON.stringify(updatedOpportunity)
              });
            } catch (err) {
              console.error('Failed to update opportunity:', err);
              // Revert the grid change on error
              if (rowNode) {
                rowNode.setData(event.data);
              }
            }
          }}
          getRowId={(params) => String(params.data.id)}
          className="h-full w-full"
          onColumnMoved={onColumnMoved}
          onColumnResized={onColumnResized}
          onColumnVisible={onColumnVisible}
          isExternalFilterPresent={isExternalFilterPresent}
          doesExternalFilterPass={doesExternalFilterPass}
        />
      </div>
    </div>
  );
};

export default OpportunityGrid;
