import React, { createContext, useContext, useState, useEffect } from 'react';
import { request } from '../services/dataService';
import { API_ENDPOINTS } from '../constants/apiEndpoints';
import { isAuthenticated, isAuthorized as checkAuthorized } from '../services/authService';

interface Team {
  userId: number;
  email: string;
  teamId: number;
  teamName: string;
}

interface TeamContextType {
  teams: Team[];
  isLoading: boolean;
  currentUserId: number | null;
  hasTeams: boolean;  // Property to indicate if user has teams
}

const TeamContext = createContext<TeamContextType>({
  teams: [],
  isLoading: true,
  currentUserId: null,
  hasTeams: false
});

export const TeamProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [teams, setTeams] = useState<Team[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [currentUserId, setCurrentUserId] = useState<number | null>(null);
  const [hasTeams, setHasTeams] = useState<boolean>(false);
  // Add a state to track auth refreshes
  const [authRefreshCount, setAuthRefreshCount] = useState(0);

  // Function to load teams data
  const loadTeamsAndUser = async () => {
    // Check if user is authenticated and authorized before attempting to fetch teams
    const authenticated = isAuthenticated();
    const authorized = checkAuthorized();

    // If not authenticated or not authorized, don't try to load teams
    if (!authenticated || !authorized) {
      setIsLoading(false);
      return;
    }

    try {
      setIsLoading(true);
      // User is authenticated and authorized, fetch teams
      const userTeams = await request(API_ENDPOINTS.GET_AUTH_WHO);

      if (!userTeams || userTeams.length === 0) {
        console.log('No teams found or user not associated with any teams');
        setTeams([]);
        // Don't set userId to 0, leave as null
        setHasTeams(false);
      } else {
        setTeams(userTeams);
        setCurrentUserId(userTeams[0].userId);
        setHasTeams(true);
      }
    } catch (error) {
      const errorMessage = error instanceof Error ? error.message : 'Unknown error';
      console.error('Failed to load teams:', errorMessage);

      setTeams([]);
      setHasTeams(false);
    } finally {
      setIsLoading(false);
    }
  };

  // Listen for auth refresh events
  useEffect(() => {
    const handleAuthRefresh = () => {
      console.log('Auth refresh detected, reloading team context');
      setAuthRefreshCount(prev => prev + 1);
    };

    // Add event listener for auth refresh
    window.addEventListener('AUTH_REFRESH', handleAuthRefresh);

    // Clean up listener
    return () => {
      window.removeEventListener('AUTH_REFRESH', handleAuthRefresh);
    };
  }, []);

  // Load teams initially and when auth refreshes
  useEffect(() => {
    loadTeamsAndUser();
  }, [authRefreshCount]);

  return (
    <TeamContext.Provider value={{ teams, isLoading, currentUserId, hasTeams }}>
      {children}
    </TeamContext.Provider>
  );
};

export const useTeams = () => useContext(TeamContext);
