import { FC } from 'react';
import { useNavigate } from 'react-router-dom';
import { Contact } from '../../models/Contact';
import { THEME_CLASSES } from '../../constants/themeConstants';
import { getContactPhotoUrl } from '../../utils/avatarUtils';
import { useEventBus } from '../../context/EventBusContext';
import { getNotesSummaryPrompt } from '../../utils/summaryUtils';
import { highlightText } from '../../utils/highlightUtils';
import { request } from '../../services/dataService';
import { API_ENDPOINTS } from '../../constants/apiEndpoints';
import { useTeams } from '../../context/TeamContext';
import { getContactDisplayFields } from '../../utils/contactUtils';
import { renderTypeChip } from '../../utils/typeChipUtils';

interface ContactCardProps {
  contact: Contact;
  keywords: string[];
  onDelete: (id: number) => Promise<void>;
}

const ContactCard: FC<ContactCardProps> = ({ contact, keywords, onDelete }) => {
  const navigate = useNavigate();
  const { publish } = useEventBus();
  const { currentUserId } = useTeams();

  const handleDelete = async (e: React.MouseEvent) => {
    e.stopPropagation();
    try {
      await onDelete(contact.id);
    } catch (error) {
      console.error('Failed to delete contact:', error);
    }
  };

  const handleNotesClick = (e: React.MouseEvent) => {
    e.stopPropagation();
    navigate(`/contacts/${contact.id}/notes`);
  };

  const handleSummarize = async (e: React.MouseEvent) => {
    e.stopPropagation();
    const notes = await request(API_ENDPOINTS.GET_CONTACT_NOTES, { userId: currentUserId, id: contact.id });
    if (!notes || !notes.length) return;
    const prompt = getNotesSummaryPrompt(notes);
    publish('chat:open', prompt);
  };

  const getStatusColor = (status: string) => {
    switch (status) {
      case 'Active':
        return 'bg-white dark:bg-gray-800';
      case 'Inactive':
        return 'bg-red-100 dark:bg-red-900';
      default:
        return 'bg-gray-100 dark:bg-gray-700';
    }
  };

  return (
    <div
      className={`p-4 rounded-lg ${getStatusColor(contact.employeeStatus)} border ${THEME_CLASSES.border} ${THEME_CLASSES.card.hover.full} relative min-h-[200px] flex flex-col`}
    >
      <div className="flex justify-end gap-2 items-center absolute top-2 right-2">
        <button
          onClick={handleNotesClick}
          className={`p-1 rounded-full hover:${THEME_CLASSES.hover.background}`}
          title="View notes"
        >
          <svg xmlns="http://www.w3.org/2000/svg" className={`h-4 w-4 ${THEME_CLASSES.text.secondary}`} fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth="2">
            <path strokeLinecap="round" strokeLinejoin="round" d="M9 5H7a2 2 0 00-2 2v12a2 2 0 002 2h10a2 2 0 002-2V7a2 2 0 00-2-2h-2M9 5a2 2 0 002 2h2a2 2 0 002-2M9 5a2 2 0 012-2h2a2 2 0 012 2m-3 7h3m-3 4h3m-6-4h.01M9 16h.01" />
          </svg>
        </button>
        <button
          onClick={handleDelete}
          className={`p-1 rounded-full hover:${THEME_CLASSES.hover.background}`}
          title="Remove contact"
        >
          <svg xmlns="http://www.w3.org/2000/svg" className={`h-4 w-4 ${THEME_CLASSES.text.secondary}`} viewBox="0 0 20 20" fill="currentColor">
            <path fillRule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clipRule="evenodd" />
          </svg>
        </button>
      </div>

      <div
        className="flex items-stretch gap-4 cursor-pointer flex-grow"
        onClick={() => navigate(`/contacts/${contact.id}`)}
      >
        <div className="flex-shrink-0 self-stretch">
          <div className={`w-24 h-full rounded-lg border-2 ${THEME_CLASSES.border} overflow-hidden`}>
            <img
              src={getContactPhotoUrl(contact)}
              alt={contact.displayName}
              className="w-full h-full object-cover bg-gray-300 dark:bg-gray-600"
              onError={(e) => {
                const target = e.target as HTMLImageElement;
                target.src = '';
                target.className = 'w-full h-full bg-gray-300 dark:bg-gray-600 flex items-center justify-center';
              }}
            />
            <div className="w-full h-full bg-gray-300 dark:bg-gray-600 flex items-center justify-center hidden">
              <span className={`text-lg font-semibold ${THEME_CLASSES.text.primary}`}>
                {contact.displayName?.[0] || '?'}
              </span>
            </div>
          </div>
        </div>
        <div className="flex-1 min-w-0 space-y-1">
          <h3 className={`text-base font-semibold ${THEME_CLASSES.text.primary} break-words`}>
            {keywords.length ? highlightText(contact.displayName, keywords) : contact.displayName}
          </h3>

          <div className="text-xs space-y-2">
            {getContactDisplayFields().map(field => {
              const value = contact[field.key];
              if (field.hideEmpty && !value) {
                return null;
              }

              const displayValue = value ?? '-';

              return (
                <div key={field.key} className="flex justify-between items-start gap-4">
                  <span className={`${THEME_CLASSES.text.secondary} shrink-0`}>{field.label}:</span>
                  <span className={`${THEME_CLASSES.text.secondary} text-right`}>
                    {keywords.length && displayValue !== '-'
                      ? highlightText(displayValue.toString(), keywords)
                      : displayValue}
                  </span>
                </div>
              );
            })}
          </div>
        </div>
      </div>

      {/* Type chip and actions fixed to bottom of card */}
      <div className="flex items-center gap-2  pt-1 justify-end">
        {renderTypeChip({
          rlpCompanyId: 0,
          teamId: contact.teamId,
          rlpPersonId: contact.rlpPersonId ? Number(contact.rlpPersonId) : undefined
        })}
        <button
          onClick={handleSummarize}
          className={`p-2 rounded-md ${THEME_CLASSES.text.secondary} ${THEME_CLASSES.hover.background}`}
          title="Ask AI about this contact"
        >
          <svg className="w-4 h-4" fill="none" stroke="currentColor" viewBox="0 0 24 24">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M8 12h.01M12 12h.01M16 12h.01M21 12c0 4.418-4.03 8-9 8a9.863 9.863 0 01-4.255-.949L3 20l1.395-3.72C3.512 15.042 3 13.574 3 12c0-4.418 4.03-8 9-8s9 3.582 9 8z" />
          </svg>
        </button>
      </div>
    </div>
  );
};

export default ContactCard;
