import { FC, useEffect, useState } from 'react';
import { Contact } from '../../models/Contact';
import { THEME_CLASSES } from '../../constants/themeConstants';
import ContactCard from './ContactCard';
import { request } from '../../services/dataService';
import { API_ENDPOINTS } from '../../constants/apiEndpoints';
import Spinner from '../common/Spinner';
import { filterContacts, getContactDisplayFields } from '../../utils/contactUtils';
import { useTeams } from '../../context/TeamContext';

interface ContactListProps {
  searchValue: string;
  onRowCountChange?: (count: number) => void;
}

const ContactList: FC<ContactListProps> = ({
  searchValue,
  onRowCountChange
}) => {
  const { currentUserId, isLoading, hasTeams } = useTeams();
  const [contacts, setContacts] = useState<Contact[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const keywords = searchValue.toLowerCase().split(/\s+/).filter(Boolean);

  useEffect(() => {
    if (isLoading || !hasTeams || !currentUserId) return;

    let isMounted = true;
    const loadContacts = async () => {
      setLoading(true);
      setError(null);
      try {
        const contacts = await request(API_ENDPOINTS.GET_USER_CONTACTS, { userId: currentUserId });
        if (!isMounted) return;
        setContacts(contacts || []);
      } catch (err) {
        if (!isMounted) return;
        const errorMessage = err instanceof Error ? err.message : 'Failed to load contacts';
        console.error('Error fetching contacts:', err);
        setError(errorMessage);
        setContacts([]);
      } finally {
        if (isMounted) {
          setLoading(false);
        }
      }
    };

    void loadContacts();
    return () => {
      isMounted = false;
    };
  }, [currentUserId, isLoading, hasTeams]);

  const searchFields = getContactDisplayFields().map((field: any) => field.key);
  const filteredContacts = filterContacts(contacts, searchValue, searchFields);

  // Row count updates
  useEffect(() => {
    if (onRowCountChange) {
      onRowCountChange(filteredContacts.length);
    }
  }, [filteredContacts.length, onRowCountChange]);

  // Custom handler to remove contacts from user's contact list
  const handleDeleteContact = async (id: number) => {
    if (!currentUserId) return;

    try {
      await request(API_ENDPOINTS.DELETE_USER_CONTACT, {
        userId: currentUserId,
        id: id
      });

      // Update local state after successful removal
      setContacts(prevContacts => prevContacts.filter(contact => contact.id !== id));
    } catch (err) {
      console.error('Error removing contact from user list:', err);
    }
  };

  if (isLoading) {
    return (
      <div className="flex items-center justify-center h-64">
        <Spinner />
        <span className="ml-3">Loading user data...</span>
      </div>
    );
  }

  if (!hasTeams) {
    return (
      <div className="flex flex-col items-center justify-center h-64 text-center">
        <div className="text-red-500 mb-4">
          <svg xmlns="http://www.w3.org/2000/svg" className="h-16 w-16 mx-auto" fill="none" viewBox="0 0 24 24" stroke="currentColor">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z" />
          </svg>
        </div>
        <h2 className="text-xl font-semibold mb-2">No Team Assignment</h2>
        <p className="mb-4">Your account doesn't have any team assignments.</p>
        <p>Please contact your administrator to get assigned to a team.</p>
      </div>
    );
  }

  if (error) {
    return (
      <div className="text-red-500 text-center h-64 flex items-center justify-center">
        {error}
      </div>
    );
  }

  if (loading) {
    return (
      <div className="flex items-center justify-center h-64">
        <Spinner />
      </div>
    );
  }

  return (
    <>
      {filteredContacts.length === 0 && searchValue && (
        <div className={`text-center py-6 ${THEME_CLASSES.text.secondary}`}>
          No contacts found. Please check your search criteria.
        </div>
      )}
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-6 mt-4">
        {filteredContacts.map((contact) => (
          <div key={contact.id}>
            <ContactCard
              contact={contact}
              keywords={keywords}
              onDelete={handleDeleteContact}
            />
          </div>
        ))}
      </div>
    </>
  );
};

export default ContactList;
