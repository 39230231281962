import { FC, useState, useEffect, useMemo } from 'react';
import { useTeams } from '../../context/TeamContext';
import { THEME_CLASSES } from '../../constants/themeConstants';
import { request } from '../../services/dataService';
import { API_ENDPOINTS } from '../../constants/apiEndpoints';
import DynamicForm from '../common/DynamicForm';
import { useSchemas } from '../../context/SchemaContext';

interface NewAccountProps {
  isOpen: boolean;
  onClose: () => void;
}

const NewAccount: FC<NewAccountProps> = ({ isOpen, onClose }) => {
  const { teams, currentUserId } = useTeams();
  const [selectedTeam, setSelectedTeam] = useState<number | null>(null);
  const { schemas, isLoading: isLoadingSchemas } = useSchemas(2);

  // Consolidated schema filtering and team selection logic
  const formSchema = useMemo(() => {
    const validSchemas = schemas.filter(s => !s.rlp);
    
    // Auto-select team if only one valid schema exists
    if (validSchemas.length === 1 && !selectedTeam) {
      setSelectedTeam(validSchemas[0].teamId);
    }

    // Return schema if team is selected
    if (!selectedTeam) return null;
    const matchingSchema = validSchemas.find(s => s.teamId === selectedTeam);
    return matchingSchema ? JSON.parse(matchingSchema.schema) : null;
  }, [selectedTeam, schemas]);

  // Handle escape key
  useEffect(() => {
    const handleEscape = (event: KeyboardEvent) => {
      if (event.key === 'Escape') {
        onClose();
      }
    };

    window.addEventListener('keydown', handleEscape);
    return () => window.removeEventListener('keydown', handleEscape);
  }, [onClose]);

  if (!isOpen) return null;

  const handleSubmit = async (formData: Record<string, any>) => {
    if (!currentUserId || !selectedTeam) return;

    try {
      const accountData = {
        ...formData,
        teamId: selectedTeam
      };

      await request(API_ENDPOINTS.POST_ACCOUNT, {
        userId: currentUserId,
        name: formData.name || '',
        teamId: selectedTeam,
        data: JSON.stringify(accountData)
      });
      onClose();
      window.location.reload(); // Reload page to show new account
    } catch (error) {
      console.error('Error creating account:', error);
      throw error;
    }
  };

  const handleSelectTeam = (teamId: number) => {
    setSelectedTeam(teamId === selectedTeam ? null : teamId);
  };

  return (
    <dialog open={isOpen} className="fixed inset-0 z-50 bg-transparent">
      <div className="fixed inset-0 bg-black/50" />
      <div className={`fixed left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 p-8 pb-2 rounded-lg w-[800px] max-h-[90vh] overflow-y-auto ${THEME_CLASSES.card.background}`}>
        <div className={`flex justify-between items-center mb-2 ${THEME_CLASSES.note.card.background.gray} -m-8 rounded-t-lg`}>
          <h3 className="text-lg font-semibold text-gray-900 dark:text-white pl-4 pt-4">
            New Account
          </h3>
          <button
            type="button"
            onClick={onClose}
            className="text-gray-500 dark:text-gray-400 hover:text-gray-700 dark:hover:text-gray-300 hover:bg-gray-100 dark:hover:bg-gray-600 rounded-full mr-4"
            aria-label="Close"
          >
            ×
          </button>
        </div>

        {/* Team Selection Section */}
        <div className={`mb-6 p-4 rounded-md bg-gray-50 dark:bg-gray-800`}>
          <div className="space-y-3">
            <div className="flex items-center gap-3">
              <h4 className={`text-sm my-auto font-medium ${THEME_CLASSES.text.secondary} whitespace-nowrap`}>
                Select a Team:
              </h4>
              <div className="flex flex-wrap gap-2">
                {teams
                  .filter(team => schemas.some(s => s.teamId === team.teamId && !s.rlp))
                  .map(team => (
                  <button
                    key={team.teamId}
                    type="button"
                    onClick={() => handleSelectTeam(team.teamId)}
                    className={`px-3 py-1 rounded-full text-xs ${selectedTeam === team.teamId
                      ? THEME_CLASSES.button.primary
                      : `${THEME_CLASSES.text.secondary} ${THEME_CLASSES.border} border hover:bg-opacity-10 hover:bg-primary`
                      }`}
                  >
                    {team.teamName}
                  </button>
                ))}
              </div>
            </div>

            {isLoadingSchemas && (
              <div className={`text-xs ${THEME_CLASSES.text.secondary}`}>Loading schema...</div>
            )}

            {selectedTeam !== null && !isLoadingSchemas && !formSchema && (
              <div className={`text-xs text-red-500`}>
                No account schema found for the selected team
              </div>
            )}
          </div>
        </div>
        <div className="min-h-[100px]">
          {selectedTeam && formSchema ? (
            <DynamicForm
              fields={formSchema}
              onSubmit={handleSubmit}
              onCancel={onClose}
              submitLabel="Create Account"
              cancelLabel="Cancel"
            />
          ) : (
            <div className={`flex items-center justify-center h-full ${THEME_CLASSES.text.secondary}`}>
              {isLoadingSchemas ? (
                'Loading schema...'
              ) : (
                'Please select a team first'
              )}
            </div>
          )}
        </div>
      </div>
    </dialog>
  );
};

export default NewAccount;
