import { z } from "zod";
import { DataRoutes } from "./types";

const pipelineRoutes: DataRoutes = {
  // Get list of all pipelines
  'get /api/data/pipelines': {
    query: `SELECT * FROM [Pipelines]`,
  },

  // Get details of a specific pipeline
  'get /api/data/pipelines/:id': {
    query: `SELECT * FROM [Pipelines] WHERE ID = @id`,
  },

  // Get ordered list of stages for a pipeline
  'get /api/data/pipelines/:id/stages': {
    query: `SELECT
      id as id,
      Name as name
    FROM [dbo].[PipelineStages]
    WHERE PipelineID = @id
    ORDER BY [StageOrder] ASC`,
  },

  // Get opportunities within a pipeline
  'get /api/data/pipelines/:id/opportunities': {
    query: `SELECT O.[id] AS id
      ,O.StageId AS stageId
      ,O.[Data] AS data
      ,O.[ContactId] AS contactId
      ,COALESCE(O.accountId, C.accountId) AS accountId
  FROM [Opportunities] O
  JOIN [PipelineStages] PS ON O.[StageID] = PS.[ID]
  LEFT JOIN Contacts C ON O.contactId = C.id
  WHERE PipelineID = @id`
  },

  // Update opportunity data
  'patch /api/data/opportunities/:id/data': {
    schema: z.object({
      id: z.number().int(),
      data: z.string()
    }),
    query: `UPDATE [opportunities] SET data = @data WHERE id = @id`
  },

  // Update contact associated with an opportunity
  'patch /api/data/opportunities/:id/contact': {
    schema: z.object({
      id: z.number().int(),
      contactId: z.number().int()
    }),
    query: `UPDATE [opportunities] SET contactId = @contactId WHERE id = @id`
  },

  // Create a new opportunity in a stage
  'post /api/data/opportunities': {
    schema: z.object({
      stageId: z.number().int(),
      data: z.string()
    }),
    query: `INSERT INTO
    [Opportunities] (StageID, Data)
    VALUES (@stageId, @data);

    SELECT * FROM [Opportunities] WHERE ID = SCOPE_IDENTITY()
    `
  },

  // Move opportunity to a different stage
  'patch /api/data/opportunities/:id/stage': {
    schema: z.object({
      id: z.number().int(),
      stageId: z.number().int()
    }),
    query: `UPDATE [Opportunities]
            SET StageID = @stageId
            WHERE id = @id`
  },

  'get /api/data/opportunities/count': {
    query: `SELECT
    ISNULL(P.Id, 0) AS pipelineId
    ,COUNT(*) AS count
    FROM dbo.opportunities O

    JOIN dbo.PipelineStages PS ON O.StageID = PS.ID
    JOIN dbo.Pipelines P ON PS.PipelineID = P.ID
    GROUP BY P.Id`
  },
};

export default pipelineRoutes;
