import React, { useState, useEffect } from 'react';
import { THEME_CLASSES } from '../constants/themeConstants';
import { request } from '../services/dataService';
import { API_ENDPOINTS } from '../constants/apiEndpoints';
import { Schema } from '../models/Schema';
import Grid from '../components/common/Grid';
import { ColDef } from 'ag-grid-community';

// Local storage key for active schema
const ACTIVE_SCHEMA_KEY = 'rlpCRM-activeSchema';

const Settings: React.FC<{}> = (): JSX.Element => {
  const [schemas, setSchemas] = useState<Schema[]>([]);
  const [activeSchema, setActiveSchema] = useState<Schema | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [gridData, setGridData] = useState<any[]>([]);
  const [isSaving, setIsSaving] = useState(false);
  const [saveStatus, setSaveStatus] = useState<{ message: string, isError: boolean } | null>(null);
  const [expandedSections, setExpandedSections] = useState<{[key: string]: boolean}>({
    contacts: true,
    accounts: true,
    pipeline: true
  });

  const toggleSection = (section: string) => {
    setExpandedSections(prev => ({
      ...prev,
      [section]: !prev[section]
    }));
  };

  // Static column definitions based on FormField structure
  const formFieldColDefs: ColDef[] = [
    {
      field: 'name',
      headerName: 'Field Name',
      width: 300,
      rowDrag: true
    },
    {
      field: 'label',
      headerName: 'Display Label',
      width: 200,
    },
    {
      field: 'type',
      headerName: 'Field Type',
      width: 150,
      cellEditor: 'agSelectCellEditor',
      cellEditorParams: {
        values: ['text', 'date', 'number', 'boolean', 'textarea', 'select', 'email', 'password', 'tel', 'url']
      }
    },
    {
      field: 'required',
      headerName: 'Required',
      width: 100,
      type: 'boolean',
      cellEditor: 'agCheckboxCellEditor',
      cellRenderer: 'agCheckboxCellRenderer'
    },
    {
      field: 'placeholder',
      headerName: 'Placeholder',
      width: 200
    },
    {
      field: 'options',
      headerName: 'Options',
      type: 'textarea',
      width: 250,
      cellRenderer: (params: any) => {
        if (!params.value) return '';
        // Handle both string and array cases
        let options = Array.isArray(params.value) ? params.value : [];
        try {
          // If it's a string, try to parse it
          if (typeof params.value === 'string') {
            options = JSON.parse(params.value);
          }
        } catch (e) {
          return 'Invalid JSON';
        }
        return options.map((opt: any) => opt.label).join(', ');
      },
      cellEditor: 'agLargeTextCellEditor',
      cellEditorPopup: true,
      valueGetter: (params) => {
        if (!params.data?.options) return '[]';
        if (typeof params.data.options === 'string') {
          return params.data.options;
        }
        return JSON.stringify(params.data.options, null, 2);
      },
      valueSetter: (params) => {
        try {
          // Parse the input string as JSON and validate it's an array
          const parsed = JSON.parse(params.newValue || '[]');
          if (!Array.isArray(parsed)) {
            throw new Error('Options must be an array');
          }
          // Validate each option has required properties
          parsed.forEach((opt: any) => {
            if (!opt.label || !opt.value) {
              throw new Error('Each option must have label and value properties');
            }
          });
          params.data[params.colDef.field!] = parsed;
          return true;
        } catch (err: any) {
          alert(`Invalid JSON format. Expected array of objects with label and value. Error: ${err.message}`);
          return false;
        }
      },
      cellEditorParams: {
        maxLength: 5000,
        rows: 10,
        cols: 50
      }
    }
  ];

  // Fetch schemas on component mount
  useEffect(() => {
    const fetchSchemas = async () => {
      setIsLoading(true);
      setError(null);
      try {
        const response = await request(API_ENDPOINTS.GET_SCHEMA);

        // Parse schema strings into field objects
        const parsedSchemas = response.map((schema: any) => ({
          ...schema,
          fields: schema.schema ? JSON.parse(schema.schema) : []
        }));

        setSchemas(parsedSchemas);

        // Get previously selected schema from local storage
        const savedSchemaName = localStorage.getItem(ACTIVE_SCHEMA_KEY);

        if (parsedSchemas.length > 0) {
          // Try to find the saved schema
          const savedSchema = savedSchemaName
            ? parsedSchemas.find((schema: Schema) => schema.name === savedSchemaName)
            : null;

          // Use saved schema if found, otherwise use the first one
          const schemaToActivate = savedSchema || parsedSchemas[0];
          setActiveSchema(schemaToActivate);
          setGridData(schemaToActivate.fields || []);
        }
      } catch (err) {
        console.error('Error fetching schemas:', err);
        setError('Failed to load schemas. Please try again later.');
      } finally {
        setIsLoading(false);
      }
    };

    fetchSchemas();
  }, []);

  // Save selected schema to local storage when it changes
  useEffect(() => {
    if (activeSchema) {
      localStorage.setItem(ACTIVE_SCHEMA_KEY, activeSchema.name);
    }
  }, [activeSchema]);

  const handleValueChanged = async (newData: any[]) => {
    if (!activeSchema) return;

    // Update grid data
    setGridData(newData);

    // Clear previous status
    setSaveStatus(null);

    try {
      setIsSaving(true);

      // Create updated schema object with stringified fields
      const updatedSchema = {
        name: activeSchema.name,
        schema: JSON.stringify(newData)
      };

      // Call the PATCH API to update the schema
      await request(API_ENDPOINTS.PATCH_SCHEMA, updatedSchema);

      // Update local state with the new schema
      const newSchema = {
        ...activeSchema,
        schema: JSON.stringify(newData),
        fields: newData
      };

      setSchemas(prevSchemas =>
        prevSchemas.map(schema =>
          schema.name === activeSchema.name ? newSchema : schema
        )
      );

      // Show success message
      setSaveStatus({
        message: `Schema ${activeSchema.name} updated successfully`,
        isError: false
      });

      // Update active schema
      setActiveSchema(newSchema);

    } catch (err) {
      console.error('Error updating schema:', err);
      setSaveStatus({
        message: 'Failed to update schema. Please try again.',
        isError: true
      });
    } finally {
      setIsSaving(false);

      // Clear status message after a few seconds
      setTimeout(() => {
        setSaveStatus(null);
      }, 3000);
    }
  };

  // Render settings content based on active schema
  const renderSettingsContent = () => {
    return (
      <div className="space-y-6">
        {isLoading ? (
          <div className={`p-4 rounded-lg ${THEME_CLASSES.card.background}`}>
            <p>Loading schemas...</p>
          </div>
        ) : error ? (
          <div className={`p-4 rounded-lg ${THEME_CLASSES.status.error}`}>
            <p className="text-red-600 dark:text-red-400">{error}</p>
          </div>
        ) : activeSchema ? (
          <div>
            <Grid
              message={saveStatus ? { text: saveStatus.message, isError: saveStatus.isError } : undefined}
              isSaving={isSaving}
              data={gridData}
              columnDefs={formFieldColDefs}
              getRowId={(data) => data.name}
              onValueChanged={handleValueChanged}
              enableRowDrag={true}
              canAddRows={true}
              canRemoveRows={true}
              gridOptions={{
                rowSelection: {
                  mode: 'multiRow',
                  checkboxes: true,
                  headerCheckbox: true
                },
                selectionColumnDef: {
                  width: 50,
                  pinned: 'left',
                  // Additional column properties as needed
                },
                rowDragManaged: true,
                animateRows: true
              }}
            />
          </div>
        ) : (
          <div className={`p-4 rounded-lg ${THEME_CLASSES.card.background} ${THEME_CLASSES.card.border}`}>
            <h3 className={THEME_CLASSES.text.secondary}>No schemas available.</h3>
          </div>
        )}
      </div>
    );
  };

  return (
    <div className={`h-screen flex flex-col overflow-hidden ${THEME_CLASSES.background}`}>
      {/* Content area with sidebar and settings */}
      <div className="flex-1 overflow-hidden">
        <div className="flex h-full">
          {/* Schema names sidebar */}
          <div className={`w-64 border-r ${THEME_CLASSES.border} overflow-y-auto`}>
            {isLoading ? (
              <div className="p-4">
                <p className={THEME_CLASSES.text.secondary}>Loading schemas...</p>
              </div>
            ) : schemas.length > 0 ? (
              <div className="p-4">
                <h1 className={`text-2xl font-bold ${THEME_CLASSES.text.primary} mb-4`}>Settings - Schema</h1>
                <div className="space-y-4">
                  {/* Accounts (entityType: 2) */}
                  <div>
                    <button 
                      onClick={() => toggleSection('accounts')}
                      className={`w-full flex items-center justify-between p-3 rounded-md ${THEME_CLASSES.dropdown.background} border ${THEME_CLASSES.border} bg-gray-50 dark:bg-gray-800 hover:bg-gray-100 dark:hover:bg-gray-700`}
                    >
                      <h2 className={`text-sm font-semibold uppercase tracking-wide my-auto ${THEME_CLASSES.dropdown.item.base}`}>Accounts</h2>
                      <svg 
                        className={`w-5 h-5 transition-transform ${expandedSections.accounts ? 'transform rotate-180' : ''}`}
                        fill="none" 
                        stroke="currentColor" 
                        viewBox="0 0 24 24"
                      >
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M19 9l-7 7-7-7" />
                      </svg>
                    </button>
                    <ul className={`space-y-1 mt-2 overflow-hidden transition-all duration-200 ease-in-out ${expandedSections.accounts ? 'max-h-96 opacity-100' : 'max-h-0 opacity-0'}`}>
                      {schemas.filter(schema => schema.entityType === 2).map((schema) => (
                        <li key={schema.name}>
                          <button
                            onClick={() => {
                              setActiveSchema(schema);
                              setGridData(schema.fields || []);
                            }}
                            className={`w-full text-left px-3 py-2 text-sm rounded-md transition-colors ${activeSchema?.name === schema.name
                              ? `bg-blue-600 hover:bg-blue-700 text-white`
                              : `${THEME_CLASSES.text.secondary} ${THEME_CLASSES.hover.background}`
                              }`}
                          >
                            <div className="flex justify-between items-center">
                              <span>{schema.name}</span>
                              <span className={`px-2 py-0.5 text-xs rounded-full ${activeSchema?.name === schema.name
                                ? 'bg-white bg-opacity-20 text-white'
                                : 'bg-gray-200 dark:bg-gray-700 text-gray-700 dark:text-gray-300'
                                }`}>
                                {schema.fields?.length || 0}
                              </span>
                            </div>
                          </button>
                        </li>
                      ))}
                    </ul>
                  </div>

                  {/* Contacts (entityType: 1) */}
                  <div>
                    <button 
                      onClick={() => toggleSection('contacts')}
                      className={`w-full flex items-center justify-between p-3 rounded-md ${THEME_CLASSES.dropdown.background} border ${THEME_CLASSES.border} bg-gray-50 dark:bg-gray-800 hover:bg-gray-100 dark:hover:bg-gray-700`}
                    >
                      <h2 className={`text-sm font-semibold uppercase tracking-wide my-auto ${THEME_CLASSES.dropdown.item.base}`}>Contacts</h2>
                      <svg 
                        className={`w-5 h-5 transition-transform ${expandedSections.contacts ? 'transform rotate-180' : ''}`}
                        fill="none" 
                        stroke="currentColor" 
                        viewBox="0 0 24 24"
                      >
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M19 9l-7 7-7-7" />
                      </svg>
                    </button>
                    <ul className={`space-y-1 mt-2 overflow-hidden transition-all duration-200 ease-in-out ${expandedSections.contacts ? 'max-h-96 opacity-100' : 'max-h-0 opacity-0'}`}>
                      {schemas.filter(schema => schema.entityType === 1).map((schema) => (
                        <li key={schema.name}>
                          <button
                            onClick={() => {
                              setActiveSchema(schema);
                              setGridData(schema.fields || []);
                            }}
                            className={`w-full text-left px-3 py-2 text-sm rounded-md transition-colors ${activeSchema?.name === schema.name
                              ? `bg-blue-600 hover:bg-blue-700 text-white`
                              : `${THEME_CLASSES.text.secondary} ${THEME_CLASSES.hover.background}`
                              }`}
                          >
                            <div className="flex justify-between items-center">
                              <span>{schema.name}</span>
                              <span className={`px-2 py-0.5 text-xs rounded-full ${activeSchema?.name === schema.name
                                ? 'bg-white bg-opacity-20 text-white'
                                : 'bg-gray-200 dark:bg-gray-700 text-gray-700 dark:text-gray-300'
                                }`}>
                                {schema.fields?.length || 0}
                              </span>
                            </div>
                          </button>
                        </li>
                      ))}
                    </ul>
                  </div>

                  {/* Pipeline (entityType: 3) */}
                  <div>
                    <button 
                      onClick={() => toggleSection('pipeline')}
                      className={`w-full flex items-center justify-between p-3 rounded-md ${THEME_CLASSES.dropdown.background} border ${THEME_CLASSES.border} bg-gray-50 dark:bg-gray-800 hover:bg-gray-100 dark:hover:bg-gray-700`}
                    >
                      <h2 className={`text-sm font-semibold uppercase tracking-wide my-auto ${THEME_CLASSES.dropdown.item.base}`}>Pipeline</h2>
                      <svg 
                        className={`w-5 h-5 transition-transform ${expandedSections.pipeline ? 'transform rotate-180' : ''}`}
                        fill="none" 
                        stroke="currentColor" 
                        viewBox="0 0 24 24"
                      >
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M19 9l-7 7-7-7" />
                      </svg>
                    </button>
                    <ul className={`space-y-1 mt-2 overflow-hidden transition-all duration-200 ease-in-out ${expandedSections.pipeline ? 'max-h-96 opacity-100' : 'max-h-0 opacity-0'}`}>
                      {schemas.filter(schema => schema.entityType === 3).map((schema) => (
                        <li key={schema.name}>
                          <button
                            onClick={() => {
                              setActiveSchema(schema);
                              setGridData(schema.fields || []);
                            }}
                            className={`w-full text-left px-3 py-2 text-sm rounded-md transition-colors ${activeSchema?.name === schema.name
                              ? `bg-blue-600 hover:bg-blue-700 text-white`
                              : `${THEME_CLASSES.text.secondary} ${THEME_CLASSES.hover.background}`
                              }`}
                          >
                            <div className="flex justify-between items-center">
                              <span>{schema.name}</span>
                              <span className={`px-2 py-0.5 text-xs rounded-full ${activeSchema?.name === schema.name
                                ? 'bg-white bg-opacity-20 text-white'
                                : 'bg-gray-200 dark:bg-gray-700 text-gray-700 dark:text-gray-300'
                                }`}>
                                {schema.fields?.length || 0}
                              </span>
                            </div>
                          </button>
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>
              </div>
            ) : !error ? (
              <div className="p-4">
                <p className={THEME_CLASSES.text.secondary}>No schemas available.</p>
              </div>
            ) : (
              <div className="p-4">
                <p className="text-red-600 dark:text-red-400">Failed to load schemas.</p>
              </div>
            )}
          </div>

          {/* Settings content */}
          <div className="flex-1 p-6 overflow-y-auto">
            {renderSettingsContent()}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Settings;
